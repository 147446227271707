import React from 'react'
import './WhirlOff2.css'
import Home2Header from '../home2/Home2Header'
import Home2Footer from '../home2/Home2Footer'
import Home2MapHeaderContent from '../home2/Home2MapHeaderContent'
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import { Breakpoint, BreakpointProvider } from 'react-socks';
import WhirlOffMolenList from './WhirlOffMolenList/WhirlOffMolenList';
import WhirlOffHeader from './WhirlOffHeader/WhirlOffHeader'
import WhirlOffHeadlineStats from './WhirlOffHeadlineStats/WhirlOffHeadlineStats'

class WhirlOff2 extends React.Component {

  constructor(props) {
    super();
    this.onMapMillSelect = this.onMapMillSelect.bind(this);
  }

  state = {
    onClickBackButton: false,
    mapZoom: 6,
    mapLat: 52.8,
    mapLong: -1.5,
    selectedMolen: "upminster",
    mapMolenFocus: "",
    name: null,
    displaySequence: 0,
    displaySequenceIntervalId: 0
  };

  reloadRequested = true;


  onMapMillSelect(entry) {
    try {
      var smartmolenSelectionInstruction = JSON.parse(entry.data);
      console.log("map mill select " + smartmolenSelectionInstruction.selectedMolen);

      if (typeof (smartmolenSelectionInstruction.selectedMolen) !== 'undefined') {
        this.setState({ selectedMolen: smartmolenSelectionInstruction.selectedMolen });
      }
    }
    catch (e) {
      // do nothing - miscellaneous messages may be picked up which will fail JSON parse
    }
  }


  componentDidMount() {

    // these lines are part of the logic to reload the page when the back button is pressed
    window.history.pushState(null, null, window.location.pathname);
    window.addEventListener('popstate', this.onClickBackButton);

    window.addEventListener("message", this.onMapMillSelect);

    const hubConnection = new HubConnectionBuilder()
      .withUrl(`https://` + process.env.REACT_APP_SIGNALR_API)
      .configureLogging(LogLevel.Information)
      .withAutomaticReconnect()
      .build();

    console.log(hubConnection);

    this.setState({ hubConnection }, () => {
      this.state.hubConnection
        .start()
        .then(() => {
          console.log('Connection started!');
        })
        .catch(err => {
          console.log('Error while establishing connection :(');
          console.log(err);
        });


      this.state.hubConnection.on('molenUpdated',
        (receivedMessage) => {
          if (this.reloadRequested === false) {
            this.reloadRequested = true;
            setTimeout(this.reloadState(), 3000);
          }
        });

    });

    this.reloadState();
  }


  // this function is part of the logic to reload the page when the back button is pressed
  onBackButtonEvent = (e) => {
    e.preventDefault();
    if (!this.onClickBackButton) {

      this.onClickBackButton = true;
      this.reloadRequested = true;
      this.reloadState();
      window.history.push();
    }
  }

  // this function is part of the logic to reload the page when the back button is pressed
  componentWillUnmount = () => {
    window.removeEventListener('popstate', this.onClickBackButton);
    clearInterval(this.state.displaySequenceIntervalId);
  }

  reloadState() {

    var reloadCount = this.state.reloadCount;

    var { year } = this.props.match.params
    if (year == null ) {year = new Date().getFullYear();}
    fetch(`https://` + process.env.REACT_APP_SMARTMOLEN_API + `/whirloff/${year}`)
      .then(response => response.json())
      .then(response => {
        this.setState({
          whirloff: response,
          year: year,
          reloadCount: reloadCount + 1
        })

      });

    // log in 'plausible' that the window is still open (every 30 mins)
    if (this.lastLogTimeMillis <= (new Date().getTime()) - 1800000) {
      window.plausible('whirloff');
      this.lastLogTimeMillis = new Date().getTime();
    }

  }


  focusMapOnMolen(molen) {
    this.setState({ "selectedMolen": molen.shortName });
    document.getElementById('windy-iframe').contentWindow.postMessage({ 'messageType': 'focus', 'molenShortName': molen.shortName });
  }

  setMapBounds(bounds) {
    document.getElementById('windy-iframe').contentWindow.postMessage({ 'messageType': 'bounds', 'bounds': bounds });
  }

  render() {
    document.title = "smartmolen Whirl-Off";
    if (this.state == null || this.state.whirloff == null) {
      return (
        <div className="whirloff2">
          Loading ...
        </div>
      )
    }
    else {
      return this.renderLoaded();
    }
  }

  renderLoaded() {
    return (
      <>
        <BreakpointProvider>
          <Breakpoint xlarge up>
            <div className="whirloff2-full">
              <div className="whirloff2-header-full"><WhirlOffHeader whirloff={this.state.whirloff} year={this.state.year} /></div>
              <div className="whirloff2-molen-list-full"><WhirlOffMolenList whirloff={this.state.whirloff} year={this.state.year} showWeekdayDistribution={true} /></div>
              <div className="whirloff2-webcam-full"><iframe src="https://view-video.smartmolen.com:5443/5da4cf72947144f8b6d00df1b89698ae/play.html?name=602270698634470403055021" allow="autoplay" class="webcam-view" scrolling="no" title="millwebcam" style={{ border: "none" }} allowfullscreen=""></iframe></div>
              <div className="whirloff2-map-full-xlarge">
                <div className="whirloff2-windy-map-full">
                  <iframe id="windy-iframe" src={"/map/map.html?bounds=nweurope&molenFocus=" + this.state.mapMolenFocus} title="Windy Map" width="100%" height="100%" style={{ border: "none" }}></iframe></div>
              </div>
              <div className="whirloff2-headline-stats-xlarge"><WhirlOffHeadlineStats whirloff={this.state.whirloff} /></div>
              <div className="whirloff2-footer">Footer</div>
            </div>
          </Breakpoint>

          <Breakpoint large only>
            <div className="whirloff2-full">
              <div className="whirloff2-header-full"><WhirlOffHeader whirloff={this.state.whirloff} year={this.state.year} /></div>
              <div className="whirloff2-map-full-large"><iframe id="windy-iframe" src={"/map/map.html?bounds=nweurope&molenFocus=" + this.state.mapMolenFocus} title="Windy Map" width="100%" height="100%" style={{ border: "none" }}></iframe></div>
              <div className="whirloff2-content-right-large">
                <div className="whirloff2-list-header-full"></div>
                <div className="whirloff2-scroll-full-large">
                  <div className="whirloff2-molen-table-full">
                  <WhirlOffMolenList whirloff={this.state.whirloff} year={this.state.year} />
                  </div>
                </div>
                <div className="whirloff2-headline-stats-xlarge"><WhirlOffHeadlineStats whirloff={this.state.whirloff} /></div>
                <div className="whirloff2-footer-full"><Home2Footer /></div>
              </div>
            </div>
          </Breakpoint>

          <Breakpoint medium down>
            <div className="whirloff2-scroll">
              <div className="whirloff2-header-mobile"><Home2Header /></div>
              <div className="whirloff2-list-header"><Home2MapHeaderContent molenList={this.state.molenList} /></div>
              <div className="whirloff2-molen-table">
              <WhirlOffMolenList whirloff={this.state.whirloff} year={this.state.year} />
              </div>
              <div className="whirloff2-headline-stats-xlarge"><WhirlOffHeadlineStats whirloff={this.state.whirloff} /></div>
              <div className="whirloff2-footer"><Home2Footer /></div>
            </div>
          </Breakpoint>
        </BreakpointProvider>
      </>
    )
  }
}



export default WhirlOff2