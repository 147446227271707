import React from 'react';
import './consoleTableItem.css';
import WindDirectionArrow from '../components/weatherForecastTable/windDirectionArrow';

class ConsoleTableItem extends React.Component {

    componentDidMount() {
    }

    signalMolenSelected = (e) => {
        this.props.onMolenSelected(this.props.molen);
    };

    render() {

        var molen = this.props.molen;
        var noWind = (molen.latestWeatherObservation.windDirection == null);

        return (
            <>

                <div className="console-table-item-container" onClick={this.signalMolenSelected}>
                    <div className="console-table-item-col1">
                        <div className="console-table-item-title">
                            {molen.name}
                        </div>
                        <div className="console-table-item-subtitle">
                            {molen.county}<br />
                        </div>
                    </div>
                    <div className="console-table-item-col2">
                        {molen.latestOrientationSensorReading &&
                            <>
                                Sails Facing<br />
                                {!(molen.isCapInFixedPosition) && <>
                                <div className="console-headline-stat">{molen.latestOrientationSensorReading.compassPoint}</div>
                                <div className="console-table-item-subtitle">{molen.latestOrientationSensorReading.bearing}&deg;</div>
                                </>
                                }
                                {(molen.isCapInFixedPosition) && <>
                                <div className="console-headline-stat">{molen.latestOrientationSensorReading.compassPoint}</div>
                                <div className="console-table-item-subtitle-red">Not turning to wind</div>
                                </>
                                }
                            </>
                        }
                    </div>
                    <div className="console-table-item-col3">
                        {molen.latestWeatherObservation && <>
                            <div className="console-table-wind">
                                <div className="console-table-wind-left">
                                    Wind<br />
                                    <div className="console-headline-stat">{(noWind) ? "None" : this.props.molen.latestWeatherObservation.windDirectionCompassPoint}</div>
                                    <div className="console-table-item-subtitle">{Math.round(molen.latestWeatherObservation.windSpeedMs * 2.24)} mph</div>
                                </div>
                                <div className="console-table-wind-right">
                                    {(!noWind) && <WindDirectionArrow windDirection={molen.latestWeatherObservation.windDirection} molenOrientation={molen.latestOrientationSensorReading.bearing} windSpeedMs={molen.latestWeatherObservation.windSpeedMs} size="50" palette="dark" />}
                                </div>
                            </div>
                        </>
                        }
                    </div>
                    <div className="console-table-item-col4">
                        {molen.latestSailRotationReading && <>
                            Sail Speed<br />
                            <div className="console-headline-stat">{Math.round((this.props.molen.latestSailRotationReading.currentSpeedRpm) * 10) / 10} rpm</div>
                            {molen.latestSailRotationReading.brakeLeverSensorFitted ? (molen.latestSailRotationReading.brakeOn ? "Brake On" : "Brake Off") : <>&nbsp;</>}
                        </>}
                    </div>
                    <div className="console-table-item-right-border">
                    </div>

                </div>

            </>
        )
    }
}



export default ConsoleTableItem