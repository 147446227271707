import React from 'react'
import './Tech2.css'
import moment from 'moment';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import Tech2Tabs from './Tech2Tabs'
import HeaderBar from '../components/headerBar/headerBar';

class Tech2 extends React.Component {

  state = {
    name: null,
    reloadCount: 0
  }

  lastLogTimeMillis = new Date().getTime();

  componentDidMount() {

    const hubConnection = new HubConnectionBuilder()
      .withUrl(`https://` + process.env.REACT_APP_SIGNALR_API)
      .configureLogging(LogLevel.Information)
      .withAutomaticReconnect()
      .build();

    console.log(hubConnection);

    this.setState({ hubConnection }, () => {
      this.state.hubConnection
        .start()
        .then(() => {
          console.log('Connection started!');
        })
        .catch(err => {
          console.log('Error while establishing connection :(');
          console.log(err);
        });


      this.state.hubConnection.on('molenUpdated',
        (receivedMessage) => {
          this.reloadState();
        });

    });

    this.reloadState();

  }

  reloadState() {

    const { molenName } = this.props.match.params
    fetch(`https://` + process.env.REACT_APP_SMARTMOLEN_API + `/api/molen/${molenName}`)
      .then(response => response.json())
      .then(response => {
        this.setState({
          molen: response,
        })

      });

    // log in 'plausible' that the window is still open (every 30 mins)
    if (this.lastLogTimeMillis <= (new Date().getTime()) - 1800000)
    {
      window.plausible('techLive');
      this.lastLogTimeMillis = new Date().getTime();
    }

  }

  render() {
    if (this.state == null || this.state.molen == null) {
      return <span>Loading ...</span>
    }
    else {
      document.title = "smartmolen - " + this.state.molen.name;
      return this.renderLoaded();
    }
  }



  renderLoaded() {

    return (
      <div className="tech-container" id="tech-container">

        <HeaderBar molen={this.state.molen} />

        <div className="tech-body">
          <Tech2Tabs molen={this.state.molen}></Tech2Tabs>
        </div>

        <div className="tech-footer">
          <div className="app-smalltext">
            &copy; {moment().format("YYYY")} smartmolen.com<br />
          </div>
        </div>

      </div>
    )
  }
}



export default Tech2