import React from 'react';
import moment from 'moment';
import './weatherForecastTable.css'
import WindDirectionArrow from './windDirectionArrow'
import BeaufortIcon from '../beaufortIcon/beaufortIcon'
import WindLogic from '../windLogic';
import UnitsContext from '../../UnitsContext';

class WeatherForecastTable extends React.Component {

    static contextType = UnitsContext;

    constructor(props) {
        super(props);
        this.state = {
            weatherForecast: {
                entries: [],
                issuedBy: "[Loading]",
                utcTime: "[Loading]"
            }
        }
    }

    componentDidMount() {
        this.updateForecast();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.forecastId !== this.props.forecastId) {
            this.updateForecast();
        }
    }

    updateForecast() {
        if (this.props.forecastId != null) {
            fetch(`https://` + process.env.REACT_APP_SMARTMOLEN_API + `/api/weatherForecast/` + this.props.forecastId + `/6/12`)
                .then(response => response.json())
                .then(response => {
                    this.setState({ weatherForecast: response })
                });
        }
    }

    render() {
        if (this.state.weatherForecast.entries.length === 0) {
            return <span>Loading ...</span>
        }
        else {
            return this.renderLoaded();
        }
    }

    renderLoaded() {

        var includeHyperlinks = true;
        if (this.props.includeHyperlinks != null) {
            includeHyperlinks = this.props.includeHyperlinks;
        }

        /// display wind speed in local units, defaulting to British
        var unitsContext = this.context;
        var windSpeedUnitText = "mph";
        var windSpeedMultiplier = 2.23694;
        if (unitsContext === "metric") {
            windSpeedUnitText = "km/h";
            windSpeedMultiplier = 3.6;
        }


        return (
            <>
                <div className="weather-forecast-panel-contents">
                    <div>
                        <table id="weather-forecast-table">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th colSpan="2">WEATHER</th>
                                    <th colSpan="3">WIND</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.weatherForecast.entries.map(item => (
                                    <tr key={item.key}>
                                        <td>{moment(item.utcTime).format("ddd")}</td>
                                        <td align="right">{moment(item.utcTime).format("HH:mm")}</td>
                                        <td align="center"><img src={"/img/w2/" + item.iconCodeUniversal + ".png"} alt={item.weatherDesc} title={item.weatherDesc} height="32px" /></td>
                                        <td align="right">&nbsp;{Math.round(item.tempDegC)}&deg;</td>
                                        <td align="center"><WindDirectionArrow windDirection={item.windDirection} orientationSensorFitted={this.props.orientationSensorFitted} molenOrientation={this.props.molenOrientation} windSpeedMs={item.windSpeedMs} /></td>
                                        <td align="right">
                                            {new WindLogic().isWindGusting(item.windSpeedMs, item.windGustMs) ?
                                                <><div className="weather-forecast-table-gust">{Math.round(item.windGustMs * windSpeedMultiplier)} {windSpeedUnitText}</div></> :
                                                <>{Math.round(item.windSpeedMs * windSpeedMultiplier)} {windSpeedUnitText}</>}
                                        </td>
                                        <td align="center"><BeaufortIcon force={item.windSpeedBeaufort} /></td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <div className="app-supersmalltext">
                        {includeHyperlinks && <>Provided by <a target="_blank" rel="noreferrer" href={this.state.weatherForecast.issuerUrl}>{this.state.weatherForecast.issuedBy}</a> at {moment(this.state.weatherForecast.utcTime).format("HH:mm:ss")}</>}
                        {!includeHyperlinks && <>Provided by {this.state.weatherForecast.issuedBy} at {moment(this.state.weatherForecast.utcTime).format("HH:mm:ss")}</>}
                    </div>
                </div>
            </>
        )
    }
}

export default WeatherForecastTable