import React from 'react';

class CustomOrientationDiagramWilton extends React.Component {

    componentDidMount() {
    }

    render() {

        return (
            <>
                {/* --- mill base layer - Wilton --- */}
                <g name="wiltonBaseLayer">
                    <circle
                        style={{ fill: '#8B4513', stroke: '#8B4513', strokeWidth: 0.8 }}
                        cx="143.991"
                        cy="144.00031"
                        id="ellipse10"
                        r="43.6744" />
                </g>


                {/* --- cap - Wilton --- */}
                <g name="wiltonCap" transform={"rotate( " + this.props.molenOrientation + ", 144, 144)"} fill="#ffffff" strokeWidth="0" stroke="#ffffff">
                    <g
                        transform="matrix(0.5481104,0,0,0.3831368,8.307808,25.70784)"
                        id="g22">
                        <g
                            transform="translate(0.213736,14.826991)"
                            id="g20">
                            <rect
                                x="4.1589999"
                                y="187.161"
                                width="486.36099"
                                height="6.3299999"
                                style={{fill:"#ffffff", stroke:"#ffffff"}}
                                id="rect12" />
                            <path
                                d="m -8.89,-530.506 v 177.242 h -9.496 z"
                                style={{fill:"#ffffff", stroke:"#ffffff"}}
                                transform="matrix(0,-1,-1,0,-40.041053,185.12859)"
                                id="path14" />
                            <rect
                                x="180.291"
                                y="187.216"
                                width="132.931"
                                height="15.825"
                                style={{fill:"#ffffff", stroke:"#ffffff"}}
                                id="rect16" />
                            <path
                                d="M 27.882,530.506 V 707.748 H 18.386 Z"
                                style={{fill:"#ffffff", stroke:"#ffffff"}}
                                transform="rotate(-90,-152.19569,374.15086)"
                                id="path18" />
                        </g>
                    </g>
                    <g
                        id="g66"
                        transform="matrix(0.8,0,0,0.8,-36.0938,-62.873301)">
                        <rect
                            x="220.787"
                            y="217.097"
                            width="9.2250004"
                            height="5.5349998"
                            style={{stroke:"#000000", fill:"#000000"}}
                            id="rect24" />
                        <rect
                            x="201.429"
                            y="214.608"
                            width="49.814999"
                            height="1.523"
                            style={{stroke:"#000000", fill:"#000000"}}
                            id="rect26" />
                        <rect
                            x="204.10899"
                            y="291.17401"
                            width="4.6339998"
                            height="53.290001"
                            style={{fill:"#423333", stroke:"#423333"}}
                            id="rect28" />
                        <rect
                            x="243.366"
                            y="290.85699"
                            width="4.6339998"
                            height="53.290001"
                            style={{fill:"#423333", stroke:"#423333"}}
                            id="rect30" />
                        <rect
                            x="213.82201"
                            y="232.37"
                            width="4.0549998"
                            height="42.574001"
                            style={{fill:"#423333", stroke:"#423333"}}
                            transform="rotate(90,177.32586,302.17536)"
                            id="rect32" />
                        <rect
                            x="45.834"
                            y="232.37"
                            width="0.86900002"
                            height="42.574001"
                            style={{fill:"#423333", stroke:"#423333"}}
                            transform="rotate(90,95.352631,385.00357)"
                            id="rect34" />
                        <rect
                            x="45.834"
                            y="232.37"
                            width="0.86900002"
                            height="42.574001"
                            style={{fill:"#423333", stroke:"#423333"}}
                            transform="rotate(90,96.514831,383.74429)"
                            id="rect36" />
                        <rect
                            x="45.834"
                            y="232.37"
                            width="0.86900002"
                            height="42.574001"
                            style={{fill:"#423333", stroke:"#423333"}}
                            transform="rotate(90,97.752197,382.62482)"
                            id="rect38" />
                        <rect
                            x="45.834"
                            y="232.37"
                            width="0.86900002"
                            height="42.574001"
                            style={{fill:"#423333", stroke:"#423333"}}
                            transform="rotate(90,98.506271,381.0049)"
                            id="rect40" />
                        <rect
                            x="45.834"
                            y="232.37"
                            width="0.86900002"
                            height="42.574001"
                            style={{fill:"#423333", stroke:"#423333"}}
                            transform="rotate(90,100.06044,380.20577)"
                            id="rect42" />
                        <rect
                            x="45.834"
                            y="232.37"
                            width="0.86900002"
                            height="42.574001"
                            style={{fill:"#423333", stroke:"#423333"}}
                            transform="rotate(90,100.85675,378.569)"
                            id="rect44" />
                        <rect
                            x="45.834"
                            y="232.37"
                            width="0.86900002"
                            height="42.574001"
                            style={{fill:"#423333", stroke:"#423333"}}
                            transform="rotate(90,102.47357,377.83252)"
                            id="rect46" />
                        <rect
                            x="45.834"
                            y="232.37"
                            width="0.86900002"
                            height="42.574001"
                            style={{fill:"#423333", stroke:"#423333"}}
                            transform="rotate(90,103.42943,376.47496)"
                            id="rect48" />
                        <rect
                            x="45.834"
                            y="232.37"
                            width="0.86900002"
                            height="42.574001"
                            style={{fill:"#423333", stroke:"#423333"}}
                            transform="rotate(90,104.76704,375.33961)"
                            id="rect50" />
                        <rect
                            x="45.834"
                            y="232.37"
                            width="0.86900002"
                            height="42.574001"
                            style={{fill:"#423333", stroke:"#423333"}}
                            transform="rotate(90,106.00354,374.18289)"
                            id="rect52" />
                        <rect
                            x="45.834"
                            y="232.37"
                            width="0.86900002"
                            height="42.574001"
                            style={{fill:"#423333", stroke:"#423333"}}
                            transform="rotate(90,106.91951,372.58601)"
                            id="rect54" />
                        <rect
                            x="45.834"
                            y="232.37"
                            width="0.86900002"
                            height="42.574001"
                            style={{fill:"#423333", stroke:"#423333"}}
                            transform="rotate(90,108.25713,371.45067)"
                            id="rect56" />
                        <rect
                            x="45.834"
                            y="232.37"
                            width="0.86900002"
                            height="42.574001"
                            style={{fill:"#423333", stroke:"#423333"}}
                            transform="rotate(90,109.59659,370.2973)"
                            id="rect58" />
                        <rect
                            x="45.834"
                            y="232.37"
                            width="0.86900002"
                            height="42.574001"
                            style={{fill:"#423333", stroke:"#423333"}}
                            transform="rotate(90,111.07242,369.10071)"
                            id="rect60" />
                        <rect
                            x="45.834"
                            y="232.37"
                            width="0.86900002"
                            height="42.574001"
                            style={{fill:"#423333", stroke:"#423333"}}
                            transform="rotate(90,112.32883,367.92406)"
                            id="rect62" />
                        <rect
                            x="45.834"
                            y="232.37"
                            width="0.86900002"
                            height="42.574001"
                            style={{fill:"#423333", stroke:"#423333"}}
                            transform="rotate(90,113.4257,366.30864)"
                            id="rect64" />
                    </g>
                    <circle
                            style={{fill:"#ffffff", stroke:"#ffffff", strokeWidth:0.8}}
                        cx="144.343"
                        cy="144.0379"
                        id="ellipse68"
                        r="32.226402" />
                    <g
                        transform="matrix(0.637688,0.0143968,-0.0126152,0.5587656,4.887459,15.85075)"
                        id="g82">
                        <g
                            transform="matrix(0.84176,0.02265,-0.023561,0.875638,27.87467,-12.891336)"
                            id="g74">
                            <polyline
                            style={{fill:"#ffffff", stroke:"#ffffff"}}
                                points="236.7 396.682 248.451 434.116 253.24 433.755 260.685 396.682"
                                transform="rotate(180,248.6925,415.399)"
                                id="polyline70" />
                            <ellipse
                            style={{fill:"#ffffff", stroke:"#ffffff"}}
                            cx="248.642"
                                cy="433.76099"
                                rx="11.894"
                                ry="4.5900002"
                                id="ellipse72" />
                        </g>
                        <g
                            transform="matrix(-0.84176,-0.02265,0.023561,-0.875638,424.05524,695.31323)"
                            id="g80">
                            <polyline
                            style={{fill:"#ffffff", stroke:"#ffffff"}}
                            points="236.7 396.682 248.451 434.116 253.24 433.755 260.685 396.682"
                                transform="rotate(180,248.6925,415.399)"
                                id="polyline76" />
                            <ellipse
                            style={{fill:"#ffffff", stroke:"#ffffff"}}
                            cx="248.642"
                                cy="433.76099"
                                rx="11.894"
                                ry="4.5900002"
                                id="ellipse78" />
                        </g>
                    </g>

                </g>
            </>
        )
    }
}



export default CustomOrientationDiagramWilton