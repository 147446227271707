import React from 'react';

class CustomOrientationDiagramUpminster extends React.Component {

    componentDidMount() {
    }

    render() {

        return (
            <>

                <defs>
                    <linearGradient gradientUnits="userSpaceOnUse" x1="3.975" y1="72.531" x2="3.975" y2="139.292" id="gradient-1" gradientTransform="matrix(-0.02107, 0.999782, -0.444924, -0.009377, 168.743691, 138.21717)">
                        <stop offset="0" style={{ stopColor: "rgba(224,224,224,1)" }} />
                        <stop offset="1" style={{ stopColor: "rgba(173,173,173,1)" }} />
                    </linearGradient>
                </defs>

                {/* --- mill base layer - UPMINSTER CUSTOM --- */}
                <g name="base-outer" transform={"rotate(19, 145, 145)"}>
                <g name="base" transform="matrix(1, 0, 0, 1, 9.470201, 5.546539)">
                    <path d="M 126.682 38.117 L 186.532 62.908 L 211.323 122.758 L 186.532 182.608 L 126.682 207.399 L 66.832 182.608 L 42.041 122.758 L 66.832 62.908 Z" style={{ stroke: "rgb(0,0,0)", fill: "rgb(255,255,255)" }} transform="matrix(0.92388, 0.382683, -0.382683, 0.92388, 64.468228, -23.439259)" />
                    <path d="M 252.018 223.477 L 310.223 247.587 L 334.333 305.792 L 310.223 363.997 L 252.018 388.107 L 193.813 363.997 L 169.703 305.792 L 193.813 247.587 Z" style={{ stroke: "rgb(255,255,255)", fill: "rgb(212,212,212)", paintOrder: "stroke" }} transform="matrix(0.92388, 0.382683, -0.382683, 0.92388, 18.817126, -240.585196)" />
                    <path d="M 250.224 104.522 L 288.821 120.509 L 304.808 159.106 L 288.821 197.703 L 250.224 213.69 L 211.627 197.703 L 195.64 159.106 L 211.627 120.509 Z" style={{ stroke: "rgb(0,0,0)", fill: "rgb(235,235,235)" }} transform="matrix(0.92388, 0.382683, -0.382683, 0.92388, -34.440314, -101.393637)" />
                </g>
                </g>

                {/* --- cap - UPMINSTER CUSTOM --- */}

                <g name="cap-outer" transform={"rotate( " + this.props.molenOrientation + ", 145, 145)"}>
                    {this.props.windingRAGStatus === "FAULT" && <animateTransform attributeName="transform" type="rotate" dur="4s" from="0 145 145" to="360 145 145" repeatCount="indefinite" />}

                    <g name="cap" transform="matrix(1, -0.000001, 0.000001, 1, 9.395831, 6.000014)">
                        <rect x="186.221" y="88.158" width="4.084" height="29.596" style={{ stroke: "rgb(0,0,0)", fill: "rgb(255,255,255)", strokeWidth: "0.5px" }} transform="matrix(-0.999985, 0.005472, -0.005472, -0.999985, 341.916351, 271.93454)" />
                        <rect x="186.221" y="88.158" width="4.084" height="29.596" style={{ stroke: "rgb(0,0,0)", fill: "rgb(255,255,255)", strokeWidth: "0.5px" }} transform="matrix(-0.999985, 0.005472, -0.005472, -0.999985, 308.488342, 272.128174)" />
                        <line style={{ stroke: "rgb(0,0,0)", fill: "rgb(216,216,216)", paintOrder: "stroke" }} x1="124.761" y1="181.17" x2="145.581" y2="181.491" transform="matrix(-0.999985, 0.005472, -0.005472, -0.999985, 271.332212, 361.918625)" />
                        <rect x="146.456" y="128.2" width="3.212" height="43.039" style={{ stroke: "rgb(0,0,0)", fill: "rgb(255,255,255)", strokeWidth: "0.5px" }} transform="matrix(0.000061, 1, -1, 0.000061, 285.523224, 32.650833)" />
                        <rect x="138.459" y="88.158" width="3.037" height="29.596" style={{ stroke: "rgb(0,0,0)", fill: "rgb(255,255,255)", strokeWidth: "0.5px" }} transform="matrix(0.99756, 0.069815, -0.069815, 0.99756, 17.069689, 58.526661)" />
                        <rect x="138.459" y="88.158" width="3.037" height="29.596" style={{ stroke: "rgb(0,0,0)", fill: "rgb(255,255,255)", strokeWidth: "0.5px" }} transform="matrix(-0.994363, 0.106032, -0.106032, -0.994363, 273.436096, 258.744141)" />
                        <path style={{ stroke: "rgb(0,0,0)", strokeLinecap: "round", strokeMiterlimit: 11, fillRule: "nonzero", fill: "url(#gradient-1)", paintOrder: "stroke", strokeWidth: "0.5px" }} d="M 158.573 173.766 L 147.932 174.32 L 135.878 174.531 L 125.111 174.061 L 113.222 172.957 L 109.86 164.095 L 107.181 151.691 L 106.549 141.409 L 107.581 130.174 L 109.544 118.563 L 113.833 107.769 L 159.574 108.58 L 163.764 119.161 L 165.601 129.934 L 166.172 140.434 L 165.182 150.907 L 163.061 161.506 L 158.573 173.766 Z" transform="matrix(-0.999864, 0.0165, -0.0165, -0.999864, 275.031412, 280.03084)" />
                        <g>
                            <rect x="111.276" y="131.957" width="25.407" height="4.484" style={{ stroke: "rgb(255,255,255)", fill: "rgb(255,255,255)" }} transform="matrix(-0.999985, 0.005471, -0.005472, -0.999985, 258.322388, 238.458191)" />
                            <g transform="matrix(-0.762679, 0.004173, -0.003064, -0.559963, 258.322388, 238.458191)">
                                <path d="M -146.02 -399.447 L -146.02 -256.509 L -152.747 -256.509 L -146.02 -399.447 Z" style={{ stroke: "rgb(255,255,255)", fill: "rgb(255,255,255)" }} transform="matrix(0, 1, -1, 0, -79.138802, 389.075684)" />
                                <path d="M 155.131 224.492 L 161.858 367.43 L 155.131 367.43 L 155.131 224.492 Z" style={{ stroke: "rgb(255,255,255)", fill: "rgb(255,255,255)" }} transform="matrix(0, -1, 1, 0, -222.530533, 397.906616)" />
                                <rect x="1.762" y="242.695" width="319.347" height="4.164" style={{ stroke: "rgb(255,255,255)", fill: "rgb(255,255,255)" }} />
                            </g>
                            <rect x="110.062" y="144.308" width="12.424" height="5.413" style={{ stroke: "rgb(0,0,0)" }} transform="matrix(-0.999985, 0.005472, -0.005472, -0.999985, 252.703842, 250.505066)" />
                            <g transform="matrix(-0.450126, 0.002463, -0.002949, -0.538814, 241.974457, 237.331345)">
                                <path style={{ stroke: "rgb(208,208,208)", fill: "rgb(255,255,255)" }} d="M 233.024 103.789 C 233.024 103.789 243.29796920206164 126.17251178069606 244.234 131.976 C 244.6309138818194 134.43690731447145 245.1968301440453 136.21134771366937 243.914 137.421 C 241.350550297164 139.83822008819553 224.91257017081136 139.92228984369746 222.134 137.421 C 220.66115789901002 136.09513642193517 221.11965299246117 134.02759530854914 221.493 131.335 C 222.31024869064282 125.4409659086348 230.7101310613115 105.92595787320916 232.383 104.109 C 232.6444841669574 103.82499346195917 233.024 103.789 233.024 103.789 C 233.024 103.789 233.02399999999997 103.789 233.024 103.789" />
                                <path style={{ stroke: "rgb(208,208,208)", fill: "rgb(255,255,255)" }} d="M 232.494 71.637 C 232.494 71.637 242.7679692020616 94.02051178069607 243.704 99.824 C 244.10091388181942 102.28490731447145 244.6668301440453 104.05934771366941 243.384 105.269 C 240.82055029716406 107.68622008819553 224.38257017081133 107.77028984369743 221.604 105.269 C 220.13115789901008 103.94313642193518 220.58965299246117 101.87559530854915 220.963 99.183 C 221.78024869064285 93.28896590863481 230.18013106131144 73.77395787320917 231.853 71.957 C 232.11448416695745 71.67299346195917 232.494 71.637 232.494 71.637 C 232.49399999999997 71.637 232.49399999999997 71.63699999999999 232.494 71.637" transform="matrix(-1, 0, 0, -1, 464.766647, 178.750572)" />
                            </g>
                        </g>
                    </g>
                </g>

            </>
        )
    }
}



export default CustomOrientationDiagramUpminster