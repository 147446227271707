import React from 'react'
import Odometer from 'react-odometerjs';
import './WhirlOffMolenList.css'
import WhirlOffWeekdaySparkBars from '../WhirlOffWeekdaySparkBars/WhirlOffWeekdaySparkBars';

class WhirlOffMolenList extends React.Component {

  render() {
    if (this.props.whirloff == null) {
      return <span>Loading ...</span>
    }
    else {
      document.title = "smartmolen Whirl-Off " + this.props.year;
      return this.renderLoaded();
    }
  }



  renderLoaded() {

    return (
      <div className="whirloff-molen-list">
        <table className="whirloff-table">
          <thead>
            <tr>
              <th></th>
              <th></th>
              <th></th>
              {this.props.showWeekdayDistribution && <th align="right"></th> }
              <th align="right">RPM</th>
              <th align="right">Today</th>
              <th align="right">This year</th>
            </tr>
          </thead>
          <tbody>
            {this.props.whirloff.map(item => (
              <tr key={item.molenId}>
                <td width="5%" align="right">{item.rank}</td>
                <td><div className="whirloff-table-mill-name">{item.name}</div></td>
                <td width="32" align="center"><img src={"/img/flags/" + item.country.toLowerCase() + ".png"} width="26" style={{ "verticalAlign": "middle" }} title={"Flag"} alt={"Flag"}></img>
                </td>
                {this.props.showWeekdayDistribution &&
                <td width="80px">
                  <WhirlOffWeekdaySparkBars countsByWeekday={item.annualStats.rotationCountsByDayOfWeek} />
                  </td>
                  }
                <td width="9%" align="right">
                  {item.currentSpeedRpm > 0 &&
                    <Odometer value={item.currentSpeedRpm} format="(dd)" theme="minimal" />
                  }
                </td>
                <td width="13%" align="right">
                {item.revCountToday > 0 &&
                  <Odometer value={item.revCountToday} format="(,ddd)" theme="minimal" />
                }
                </td>
                <td width="13%" align="right">
                  <Odometer value={item.revCountThisYear} format="(,ddd)" theme="minimal" />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <br />
        <br />

      </div>

    )
  }
}



export default WhirlOffMolenList