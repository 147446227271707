import React from 'react';
import BearingDiffCalculator from '../windLogic'

class SailRotationDiagram extends React.Component {

    componentDidMount() {
    }

    render() {
        if (this.props == null || this.props.molen == null) {
            return <span>Loading ...</span>
        }
        else {
            return this.renderLoaded();
        }
    }

    renderLoaded() {

        var windDirectionIndicationColour;
        var windDirectionArrowOpacity;
        var windDirectionArrowRotation;

        if (this.props.molen.latestWeatherObservation == null || this.props.molen.latestWeatherObservation.windDirection == null || this.props.molen.latestOrientationSensorReading == null) {
            windDirectionIndicationColour = "#FFFFFF";
            windDirectionArrowOpacity = 0;
            windDirectionArrowRotation = 0;
        }
        else {
            windDirectionIndicationColour = new BearingDiffCalculator().getBearingDiffIndicationColour(this.props.molen.latestWeatherObservation.windDirection, this.props.molen.latestOrientationSensorReading.bearing, this.props.molen.latestWeatherObservation.windSpeedMs);
            windDirectionArrowOpacity = 100;
            windDirectionArrowRotation = 180 + this.props.molen.latestWeatherObservation.windDirection - this.props.molen.latestOrientationSensorReading.bearing;
        }

        var rpm;
        var rotationTimePeriod;

        if (this.props.molen.latestSailRotationReading.currentSpeedRpm > 0) {
            rpm = this.props.molen.latestSailRotationReading.currentSpeedRpm;
            rotationTimePeriod = 60 / rpm;
        }
        else {
            rpm = 0;
            rotationTimePeriod = 0;
        }

        return (
            <>

                <div>
                    <svg width="188px" height="190px">

                        <g id="diagram" transform="scale(0.64 0.64) translate(0, -10)">

                            <g id="wind" style={{ "transform": "rotateX(73deg)", "transformOrigin": "150px 260px" }}>
                                <circle cx="150" cy="260" r="110" stroke="#FFFFFF" strokeWidth="6" fill="none" />
                                <polygon points="122,142 150,167 178,142 150,208" fill={windDirectionIndicationColour} fillOpacity={windDirectionArrowOpacity} transform={"rotate(" + windDirectionArrowRotation + ", 150, 270)"}>
                                </polygon>
                            </g>

                            <g id="windmill">
                                <polygon points="105,270 125,130 175,130 195,270" fill="white" fillOpacity="75%" strokeWidth="1" stroke="#000000" />
                                <line x1="142" y1="130" x2="135" y2="270" stroke="#909090" />
                                <line x1="158" y1="130" x2="165" y2="270" stroke="#909090" />
                                <polygon points="120,135 120,125 180,125 180,135" fill="white" fillOpacity="90%" strokeWidth="1" stroke="#000000" />
                                <path d="M 120 125 Q 120 110 150 100 Q 180 110 180 125 L 120 125" fill="#ffffff" strokeWidth="1" stroke="#000000" fillOpacity="85%" />
                            </g>

                            <g id="sails" transform="rotate(45,150,120)">
                                <line x1="150" y1="10" x2="150" y2="230" stroke="white" strokeWidth="3" />
                                <line x1="40" y1="120" x2="260" y2="120" stroke="white" strokeWidth="3" />

                                <g id="sail1">
                                    <polygon points="140,10 160,10 160,100 140,100" stroke="white" strokeWidth="2" fillOpacity="0" />
                                    <line x1="140" y1="20" x2="160" y2="20" stroke="white" strokeWidth="2" />
                                    <line x1="140" y1="30" x2="160" y2="30" stroke="white" strokeWidth="2" />
                                    <line x1="140" y1="40" x2="160" y2="40" stroke="white" strokeWidth="2" />
                                    <line x1="140" y1="50" x2="160" y2="50" stroke="white" strokeWidth="2" />
                                    <line x1="140" y1="60" x2="160" y2="60" stroke="white" strokeWidth="2" />
                                    <line x1="140" y1="70" x2="160" y2="70" stroke="white" strokeWidth="2" />
                                    <line x1="140" y1="80" x2="160" y2="80" stroke="white" strokeWidth="2" />
                                    <line x1="140" y1="90" x2="160" y2="90" stroke="white" strokeWidth="2" />
                                </g>

                                <g id="sail2" transform="rotate(90,150,120)">
                                <polygon points="140,10 160,10 160,100 140,100" stroke="white" strokeWidth="2" fillOpacity="0" />
                                    <line x1="140" y1="20" x2="160" y2="20" stroke="white" strokeWidth="2" />
                                    <line x1="140" y1="30" x2="160" y2="30" stroke="white" strokeWidth="2" />
                                    <line x1="140" y1="40" x2="160" y2="40" stroke="white" strokeWidth="2" />
                                    <line x1="140" y1="50" x2="160" y2="50" stroke="white" strokeWidth="2" />
                                    <line x1="140" y1="60" x2="160" y2="60" stroke="white" strokeWidth="2" />
                                    <line x1="140" y1="70" x2="160" y2="70" stroke="white" strokeWidth="2" />
                                    <line x1="140" y1="80" x2="160" y2="80" stroke="white" strokeWidth="2" />
                                    <line x1="140" y1="90" x2="160" y2="90" stroke="white" strokeWidth="2" />
                                </g>

                                <g id="sail3" transform="rotate(180,150,120)">
                                <polygon points="140,10 160,10 160,100 140,100" stroke="white" strokeWidth="2" fillOpacity="0" />
                                    <line x1="140" y1="20" x2="160" y2="20" stroke="white" strokeWidth="2" />
                                    <line x1="140" y1="30" x2="160" y2="30" stroke="white" strokeWidth="2" />
                                    <line x1="140" y1="40" x2="160" y2="40" stroke="white" strokeWidth="2" />
                                    <line x1="140" y1="50" x2="160" y2="50" stroke="white" strokeWidth="2" />
                                    <line x1="140" y1="60" x2="160" y2="60" stroke="white" strokeWidth="2" />
                                    <line x1="140" y1="70" x2="160" y2="70" stroke="white" strokeWidth="2" />
                                    <line x1="140" y1="80" x2="160" y2="80" stroke="white" strokeWidth="2" />
                                    <line x1="140" y1="90" x2="160" y2="90" stroke="white" strokeWidth="2" />
                                </g>

                                <g id="sail4" transform="rotate(270,150,120)">
                                <polygon points="140,10 160,10 160,100 140,100" stroke="white" strokeWidth="2" fillOpacity="0" />
                                    <line x1="140" y1="20" x2="160" y2="20" stroke="white" strokeWidth="2" />
                                    <line x1="140" y1="30" x2="160" y2="30" stroke="white" strokeWidth="2" />
                                    <line x1="140" y1="40" x2="160" y2="40" stroke="white" strokeWidth="2" />
                                    <line x1="140" y1="50" x2="160" y2="50" stroke="white" strokeWidth="2" />
                                    <line x1="140" y1="60" x2="160" y2="60" stroke="white" strokeWidth="2" />
                                    <line x1="140" y1="70" x2="160" y2="70" stroke="white" strokeWidth="2" />
                                    <line x1="140" y1="80" x2="160" y2="80" stroke="white" strokeWidth="2" />
                                    <line x1="140" y1="90" x2="160" y2="90" stroke="white" strokeWidth="2" />
                                </g>

                                {rpm > 0 && <animateTransform attributeName="transform" type="rotate" dur={String(rotationTimePeriod) + "s"} from="360 150 120" to="0 150 120" repeatCount="indefinite" />}
                            </g>

                        </g>

                    </svg>
                </div>

            </>
        )
    }
}



export default SailRotationDiagram