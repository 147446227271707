import React from 'react'
import '../SmartMolen.css'
import './Widget.css'
import moment from 'moment';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import ThreeDayForecastPanel from '../panels/ThreeDayForecastPanel/ThreeDayForecastPanel';
import LiveCapOrientationPanel from '../panels/LiveCapOrientationPanel/LiveCapOrientationPanel';
import HeaderBar from '../components/headerBar/headerBar';
import AlertBar from '../components/alertBar/alertBar';


class Widget extends React.Component {

  state = {
    name: null,
    reloadCount: 0
  }

  lastLogTimeMillis = new Date().getTime();

  componentDidMount() {

    const hubConnection = new HubConnectionBuilder()
      .withUrl(`https://` + process.env.REACT_APP_SIGNALR_API)
      .configureLogging(LogLevel.Information)
      .withAutomaticReconnect()
      .build();

    console.log(hubConnection);

    this.setState({ hubConnection }, () => {
      this.state.hubConnection
        .start()
        .then(() => {
          console.log('Connection started!');
        })
        .catch(err => {
          console.log('Error while establishing connection :(');
          console.log(err);
        });


      this.state.hubConnection.on('molenUpdated',
        (receivedMessage) => {
          this.reloadState();
        });

    });

    this.reloadState();

  }

  reloadState() {

    const { molenName } = this.props.match.params;

    fetch(`https://` + process.env.REACT_APP_SMARTMOLEN_API + `/api/molen/${molenName}`)
      .then(response => response.json())
      .then(response => {
        this.setState({
          molen: response,
        })

      });

    // log in 'plausible' that the window is still open (every 30 mins)
    if (this.lastLogTimeMillis <= (new Date().getTime()) - 1800000) {
      window.plausible('widgetLive');
      this.lastLogTimeMillis = new Date().getTime();
    }
  }

  render() {
    if (this.state == null || this.state.molen == null) {
      return "Loading ..."
    }
    else {
      return this.renderLoaded();
    }
  }


  renderLoaded() {
    
    var showWebcam = false;
    if (window.location.search != null) {
      var queryString = window.location.search; // look for ?webcam=Y in query string so we know which link to offer back
      if (queryString != null) {
        var params = new URLSearchParams(queryString);
        var webcamParam = params.get('showWebcam');
        showWebcam = ((webcamParam != null) && (webcamParam === "true"));
      }
    }

    var molen = this.state.molen;

    return (
      <div className="widget-container" id="widget-container">

        <HeaderBar molen={molen} />

        {molen.openAlerts.length > 0 && <AlertBar molen={molen} />}

        <div className="widget-body">

          {(showWebcam && this.state.molen.webcam != null) &&
            <>
              <div className="panel-wide">
                <div className="panel-header">
                  LIVE CAMERA
                </div>
                <div className="panel-body">
                  <iframe src={this.state.molen.webcam.url} allow="autoplay" className="webcam-view" scrolling="no" title="millwebcam" allowFullScreen style={{ border: "none" }}></iframe>
                </div>
              </div>
            </>

          }

          <LiveCapOrientationPanel molen={molen} />

          <ThreeDayForecastPanel molen={molen} />

        </div>

        <div className="widget-footer">
          <div className="app-smalltext">
            &copy; {moment().format("YYYY")} <a target="_blank" rel="noreferrer" href="https://www.smartmolen.com">smartmolen.com</a><br />
          </div>
        </div>
      </div>
    )
  }
}



export default Widget