import React from 'react';

class SailSpeedSensorIcon extends React.Component {

    componentDidMount() {
    }

    render() {

        var size = this.props.size != null ? this.props.size : 100;
        var color = this.props.color != null ? this.props.color: "black";
        var strokeWidth = this.props.strokeWidth != null ? this.props.strokeWidth: 4;

        return (
            <>
                <div className="sail-speed-sensor-icon" >
                    <svg height={size} width={size}>

                        <g name="sails" transform={"scale(" + size / 100 + " )"}>
                            <g transform="rotate(45,50,50)" stroke={color} strokeWidth={strokeWidth} fill="none">
                                <line x1="0" y1="50" x2="100" y2="50"></line>
                                <line x1="50" y1="0" x2="50" y2="100"></line>

                                <g>
                                    <rect x="42" y="0" width="16" height="36"></rect>
                                    <line x1="42" y1="9" x2="58" y2="9"></line>
                                    <line x1="42" y1="18" x2="58" y2="18"></line>
                                    <line x1="42" y1="27" x2="58" y2="27"></line>
                                </g>

                                <g transform="rotate(90,50,50)">
                                    <rect x="42" y="0" width="16" height="36"></rect>
                                    <line x1="42" y1="9" x2="58" y2="9"></line>
                                    <line x1="42" y1="18" x2="58" y2="18"></line>
                                    <line x1="42" y1="27" x2="58" y2="27"></line>
                                </g>

                                <g transform="rotate(180,50,50)">
                                    <rect x="42" y="0" width="16" height="36"></rect>
                                    <line x1="42" y1="9" x2="58" y2="9"></line>
                                    <line x1="42" y1="18" x2="58" y2="18"></line>
                                    <line x1="42" y1="27" x2="58" y2="27"></line>
                                </g>

                                <g transform="rotate(270,50,50)">
                                    <rect x="42" y="0" width="16" height="36"></rect>
                                    <line x1="42" y1="9" x2="58" y2="9"></line>
                                    <line x1="42" y1="18" x2="58" y2="18"></line>
                                    <line x1="42" y1="27" x2="58" y2="27"></line>
                                </g>
                            </g>
                        </g>
                    </svg>
                </div>

            </>
        )
    }
}

export default SailSpeedSensorIcon