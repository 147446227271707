import React from 'react';

class CustomOrientationDiagramCranbrook extends React.Component {

    componentDidMount() {
    }

    render() {

        return (
            <>
                <defs>
                    <radialGradient gradientUnits="userSpaceOnUse" cx="252.018" cy="305.792" r="82.315" id="gradient-0" gradientTransform="matrix(-0.282825, -0.732079, 1.079817, -0.417167, -6.904803, 617.855938)">
                        <stop offset="0" style={{ stopColor: "rgba(135, 151, 162, 1)" }} />
                        <stop offset="1" style={{ stopColor: "rgba(85, 100, 110, 1)" }} />
                    </radialGradient>
                    <linearGradient gradientUnits="userSpaceOnUse" x1="135.28" y1="116.031" x2="135.28" y2="173.43" id="gradient-1" spreadMethod="reflect" gradientTransform="matrix(1.232885, -0.003911, 0.006067, 2.552888, -1.051592, -185.719955)">
                        <stop offset="0" style={{ stopColor: "rgb(255, 255, 255)" }} />
                        <stop offset="1" style={{ stopColor: "rgba(36, 37, 49, 1)" }} />
                    </linearGradient>
                    <linearGradient gradientUnits="userSpaceOnUse" x1="135.28" y1="116.031" x2="135.28" y2="173.43" id="gradient-2" spreadMethod="pad" gradientTransform="matrix(1.232889, 0, 0, 0.947722, 0, 0)">
                        <stop offset="0" style={{ stopColor: "rgba(213, 213, 213, 1)" }} />
                        <stop offset="1" style={{ stopColor: "rgba(162, 162, 162, 1)" }} />
                    </linearGradient>
                </defs>
                <g name="base">
                    <g transform="matrix(1, 0, 0, 1, 9.076439, 3.197197)">
                        <path d="M 252.018 241.19 L 276.74 246.108 L 297.699 260.111 L 311.702 281.07 L 316.62 305.792 L 311.702 330.514 L 297.699 351.473 L 276.74 365.476 L 252.018 370.394 L 227.296 365.476 L 206.337 351.473 L 192.334 330.514 L 187.416 305.792 L 192.334 281.07 L 206.337 260.111 L 227.296 246.108 Z" style={{ paintOrder: "stroke", stroke: "rgb(0,0,0)", fill: "url(#gradient-0)" }} transform="matrix(0.976296, 0.216439, -0.216439, 0.976296, -44.935287, -212.287231)" />
                        <path d="M 250.224 104.522 L 288.821 120.509 L 304.808 159.106 L 288.821 197.703 L 250.224 213.69 L 211.627 197.703 L 195.64 159.106 L 211.627 120.509 Z" style={{ stroke: "rgb(0,0,0)", fill: "rgb(228,228,228)" }} transform="matrix(0.92388, 0.382683, -0.382683, 0.92388, -34.44025, -101.393545)" />
                        <rect x="129.155" y="182.178" width="11.84" height="8.358" style={{ stroke: "rgb(172,172,172)", fill: "rgb(216,216,216)" }} />
                        <rect x="128.851" y="92.285" width="11.84" height="8.358" style={{ stroke: "rgb(172,172,172)", fill: "rgb(216,216,216)" }} />
                    </g>
                </g>
                <g name="cap" transform={"rotate( " + this.props.molenOrientation + ", 144, 144)"}>
                    {this.props.windingRAGStatus === "FAULT" && <animateTransform attributeName="transform" type="rotate" dur="4s" from="0 144 144" to="360 144 144" repeatCount="indefinite" />}
                    <g transform="matrix(-1, 0, 0, -1, 279.945984, 288.793915)">
                        <rect x="122.71" y="88.158" width="2.691" height="29.596" style={{ stroke: "rgb(218,218,218)", fill: "rgb(255,255,255)" }} />
                        <rect x="144.683" y="88.158" width="2.691" height="29.596" style={{ stroke: "rgb(218,218,218)", fill: "rgb(255,255,255)", paintOrder: "fill" }} />
                        <line style={{ stroke: "rgb(218,218,218)", fill: "rgb(216,216,216)" }} x1="124.055" y1="92.642" x2="145.804" y2="92.642" />
                        <line style={{ stroke: "rgb(218,218,218)", fill: "rgb(216,216,216)" }} x1="124.055" y1="96.902" x2="146.028" y2="96.678" />
                        <line style={{ stroke: "rgb(218,218,218)", fill: "rgb(216,216,216)" }} x1="124.055" y1="101.162" x2="145.804" y2="100.938" />
                        <line style={{ stroke: "rgb(255,255,255)", fill: "rgb(216,216,216)" }} x1="123.703" y1="104.774" x2="145.452" y2="104.774" />
                        <line style={{ stroke: "rgb(218,218,218)", fill: "rgb(216,216,216)" }} x1="123.703" y1="109.034" x2="145.676" y2="108.81" />
                        <rect x="137.111" y="109.964" width="59.348" height="54.398" style={{ stroke: "url(#gradient-2)", fill: "url(#gradient-1)", paintOrder:"stroke markers", fillRule: "evenodd" }} transform="matrix(0, 1, -1, 0, 272.494202, -23.17576)" />
                        <g transform="matrix(0.762688, 0, 0, 0.559969, 12.821077, 43.154831)">
                            <path d="M -146.02 -399.447 L -146.02 -256.509 L -152.747 -256.509 L -146.02 -399.447 Z" style={{ stroke: "rgb(255,255,255)", fill: "rgb(255,255,255)" }} transform="matrix(0, 1, -1, 0, -79.138802, 389.075684)" />
                            <path d="M 155.131 224.492 L 161.858 367.43 L 155.131 367.43 L 155.131 224.492 Z" style={{ stroke: "rgb(255,255,255)", fill: "rgb(255,255,255)" }} transform="matrix(0, -1, 1, 0, -222.530533, 397.906616)" />
                            <rect x="145.9" y="235.649" width="33.312" height="8.008" style={{ stroke: "rgb(255,255,255)", fill: "rgb(255,255,255)" }} />
                            <rect x="1.762" y="242.695" width="319.347" height="4.164" style={{ stroke: "rgb(255,255,255)", fill: "rgb(255,255,255)" }} />
                        </g>
                        <rect x="128.258" y="174.177" width="14.478" height="6.534" style={{ stroke: "rgb(0,0,0)" }} />
                        <g transform="matrix(0.389074, 0, 0, 0.445856, 44.437099, 42.259686)">
                            <path style={{ stroke: "rgb(208,208,208)", fill: "rgb(255,255,255)" }} d="M 233.024 103.789 C 233.024 103.789 243.29796920206164 126.17251178069606 244.234 131.976 C 244.6309138818194 134.43690731447145 245.1968301440453 136.21134771366937 243.914 137.421 C 241.350550297164 139.83822008819553 224.91257017081136 139.92228984369746 222.134 137.421 C 220.66115789901002 136.09513642193517 221.11965299246117 134.02759530854914 221.493 131.335 C 222.31024869064282 125.4409659086348 230.7101310613115 105.92595787320916 232.383 104.109 C 232.6444841669574 103.82499346195917 233.024 103.789 233.024 103.789 C 233.024 103.789 233.02399999999997 103.789 233.024 103.789" />
                            <path style={{ stroke: "rgb(208,208,208)", fill: "rgb(255,255,255)" }} d="M 232.494 71.637 C 232.494 71.637 242.7679692020616 94.02051178069607 243.704 99.824 C 244.10091388181942 102.28490731447145 244.6668301440453 104.05934771366941 243.384 105.269 C 240.82055029716406 107.68622008819553 224.38257017081133 107.77028984369743 221.604 105.269 C 220.13115789901008 103.94313642193518 220.58965299246117 101.87559530854915 220.963 99.183 C 221.78024869064285 93.28896590863481 230.18013106131144 73.77395787320917 231.853 71.957 C 232.11448416695745 71.67299346195917 232.494 71.637 232.494 71.637 C 232.49399999999997 71.637 232.49399999999997 71.63699999999999 232.494 71.637" transform="matrix(-1, 0, 0, -1, 464.766647, 178.750572)" />
                        </g>
                        <line style={{ stroke: "rgb(131,131,131)", fill: "rgb(216,216,216)" }} x1="124.439" y1="89.093" x2="145.259" y2="89.414" />
                    </g>
                </g>
            </>
        )
    }
}

export default CustomOrientationDiagramCranbrook