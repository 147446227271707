import React from 'react';
import './fantailIcon.css';

class FantailIcon extends React.Component {

    componentDidMount() {
    }

    render() {

        var size = this.props.size != null ? this.props.size : 100;
        var color = this.props.color != null ? this.props.color: "black";
        var strokeWidth = this.props.strokeWidth != null ? this.props.strokeWidth: 3;

        return (
            <>
                <div className="fantail-icon" >
                    <svg height={size} width={size}>

                        <g name="fantail" transform={"scale(" + size / 100 + " )"} stroke={color} strokeWidth={strokeWidth} fill="none">
                            <circle cx="50" cy="50" r="12" fill="none" strokeWidth="4"></circle>
                            <line x1="50" y1="30" x2="50" y2="70"></line>
                            <line x1="50" y1="30" x2="50" y2="70" transform="rotate(60,50,50)"></line>
                            <line x1="50" y1="30" x2="50" y2="70" transform="rotate(120,50,50)"></line>
                            <g transform="rotate(-30,50,50)">
                                <path className="fan-blade" d="M50 10
                 A40 40 0 0 1 84.6 30
                 L67.3 40
                 A20 20 0 0 0 50 30
                 L50 10" />
                            </g>

                            <g transform="rotate(30,50,50)">
                                <path className="fan-blade" d="M50 10
                 A40 40 0 0 1 84.6 30
                 L67.3 40
                 A20 20 0 0 0 50 30
                 L50 10" />
                            </g>

                            <g transform="rotate(90,50,50)">
                                <path className="fan-blade" d="M50 10
                 A40 40 0 0 1 84.6 30
                 L67.3 40
                 A20 20 0 0 0 50 30
                 L50 10" />
                            </g>

                            <g transform="rotate(150,50,50)">
                                <path className="fan-blade" d="M50 10
                 A40 40 0 0 1 84.6 30
                 L67.3 40
                 A20 20 0 0 0 50 30
                 L50 10" />
                            </g>

                            <g transform="rotate(210,50,50)">
                                <path className="fan-blade" d="M50 10
                 A40 40 0 0 1 84.6 30
                 L67.3 40
                 A20 20 0 0 0 50 30
                 L50 10" />
                            </g>

                            <g transform="rotate(270,50,50)">
                                <path className="fan-blade" d="M50 10
                 A40 40 0 0 1 84.6 30
                 L67.3 40
                 A20 20 0 0 0 50 30
                 L50 10" />
                            </g>
                        </g>

                    </svg>
                </div>

            </>
        )
    }
}

export default FantailIcon