import React from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import Widget from './widget/Widget'
import Tech2 from './tech2/Tech2'
import Home2 from './home2/Home2'
import Console from './console/Console'
import WidgetDemo from './widgetDemo/WidgetDemo'
import UpmVisitorScreen1 from './custom/upminster/visitorCentreScreen1/UpmVisitorCentreScreen1'
import UpmVisitorScreen2 from './custom/upminster/visitorCentreScreen2/UpmVisitorCentreScreen2'
import LoRaWANFieldTest from './util/LoRaWANFieldTest/LoRaWANFieldTest'
import DigitalWindmillView1MillInfo from './dw/DigitalWindmillView1MillInfo'
import DigitalWindmillView2Live from './dw/DigitalWindmillView2Live'
import DigitalWindmillView3WindRose from './dw/DigitalWindmillView3WindRose'
import DigitalWindmillView4WindingTracker from './dw/DigitalWindmillView4WindingTracker'
import WhirlOff2 from './whirloff/WhirlOff2'

import './SmartMolen.css'
import './Panels.css'

import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.light.compact.css';

class SmartMolen extends React.Component {

  componentDidMount() {
    this.calculateScalingFactors();
  }

  calculateScalingFactors() {

    var zoom = 100;
    
    if (window.location.search != null) {
      var queryString = window.location.search;
      if (queryString != null) {
        var params = new URLSearchParams(queryString);
        var zoomQSValue = params.get('zoom');
        if (zoomQSValue !== null) {
          zoom = parseInt(zoomQSValue);
        }
      }
    }

    var iFrameScale = zoom / 100;
    var iFrameZoom = (100.0 / zoom) * 100.0;

    document.getElementsByClassName("SmartMolen")[0].style.setProperty("--iframe-scale", iFrameScale);
    document.getElementsByClassName("SmartMolen")[0].style.setProperty("--iframe-zoom", iFrameZoom.toString() + "%");
    document.getElementsByClassName("SmartMolen")[0].style.setProperty("--fixed-size-item-scale", iFrameScale);
  }

    render(){

      return(
        <div className="SmartMolen">
          <BrowserRouter>
            <Switch>
              <Route path='/custom/upminster/vcs1' component={UpmVisitorScreen1} />
              <Route path='/custom/upminster/vcs2' component={UpmVisitorScreen2} />
              <Route path='/dw1/:molenName' component={DigitalWindmillView1MillInfo} />
              <Route path='/dw2/:molenName' component={DigitalWindmillView2Live} />
              <Route path='/dw3/:molenName' component={DigitalWindmillView3WindRose} />
              <Route path='/dw4/:molenName' component={DigitalWindmillView4WindingTracker} />
              <Route path='/widget/:molenName' component={Widget} />
              <Route path='/tech/:molenName' component={Tech2} />
              <Route path='/live/:molenName' component={Tech2} />
              <Route path='/console' component={Console} />
              <Route path='/demowidgets' component={WidgetDemo} />
              <Route path='/fieldTest' component={LoRaWANFieldTest} />
              <Route path='/whirloff/:year' component={WhirlOff2} />
              <Route path='/whirloff' component={WhirlOff2} />
              <Route path='/:molenName' component={Tech2} />
              <Route path='' component={Home2} />
            </Switch>
          </BrowserRouter>
        </div>
      )
   }

 }

export default SmartMolen