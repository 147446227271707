import React from 'react'
import ThreeDayForecastPanel from '../../panels/ThreeDayForecastPanel/ThreeDayForecastPanel';
import LiveCapOrientationPanel from '../../panels/LiveCapOrientationPanel/LiveCapOrientationPanel';
import LiveSailRotationSensorPanel from '../../panels/LiveSailRotationSensorPanel/LiveSailRotationSensorPanel';
import LiveFantailSensorPanel from '../../panels/LiveFantailSensorPanel/LiveFantailSensorPanel';
import LiveTemperaturePanel from '../../panels/LiveTemperaturePanel/LiveTemperaturePanel';
import AlertBar from '../../components/alertBar/alertBar';
import NotTurningToWindBar from '../../components/notTurningToWindBar/notTurningToWindBar';
import UpminsterDustFloorCameraPanel from '../../panels/UpminsterDustFloorCameraPanel/UpminsterDustFloorCameraPanel';
import './TechLiveTab.css';
import SecondaryWeatherObservationsPanel from '../../panels/SecondaryWeatherObservationsPanel/SecondaryWeatherObservationsPanel';
import WindWarningsBar from '../../components/windWarningsBar/windWarningsBar';

class TechLiveTab extends React.Component {

    state = {
    }

    render() {
        if (this.props == null || this.props.molen == null) {
            return <span>Loading ...</span>
        }
        else {
            return this.renderLoaded();
        }
    }

    renderLoaded() {

        var videoIFrameAutoPauseSuffix = "";
        if (this.props.videoAutoPause === false) {videoIFrameAutoPauseSuffix = "&autoPause=false";}
        
        return (

            <>
            {this.props.molen.isCapInFixedPosition && <NotTurningToWindBar molen={this.props.molen} />}

            {this.props.molen.openAlerts.length > 0 && <AlertBar molen={this.props.molen} />}

            {this.props.molen.windWarnings.length > 0 && <WindWarningsBar molen={this.props.molen} />}

            <div className="widget-body">

                {(this.props.molen.webcam != null) &&

                    <>
                        <div className="panel-wide">
                            <div className="panel-header">
                                LIVE CAMERA
                        </div>
                            <div className="panel-body">
                                <iframe src={this.props.molen.webcam.url + videoIFrameAutoPauseSuffix} allow="autoplay" className="webcam-view" scrolling="no" title="millwebcam" allowFullScreen style={{ border: "none" }}></iframe>
                            </div>
                        </div>
                    </>

                }

                {(this.props.molen.latestOrientationSensorReading != null &&  this.props.molen.latestWeatherObservation != null) && <LiveCapOrientationPanel molen={this.props.molen} />}

                { /* show sail rotation sensor before the weather forecast if there is no orientation sensor (otherwise show it after the weather forecast) */ }
                {(this.props.molen.latestSailRotationReading != null && this.props.molen.latestOrientationSensorReading == null) && <LiveSailRotationSensorPanel molen={this.props.molen} />}

                {this.props.molen.latestWeatherForecast != null && <ThreeDayForecastPanel molen={this.props.molen} />}

                {(this.props.molen.secondaryWeatherObservations != null && this.props.molen.secondaryWeatherObservations.length > 0) && <SecondaryWeatherObservationsPanel molen={this.props.molen} />}

                {this.props.molen.shortName === "upminster" && <UpminsterDustFloorCameraPanel videoAutoPause={this.props.videoAutoPause} />} 

                {(this.props.molen.latestSailRotationReading != null && this.props.molen.latestOrientationSensorReading != null) && <LiveSailRotationSensorPanel molen={this.props.molen} />}

                {this.props.molen.latestFantailSensorReading != null && <LiveFantailSensorPanel molen={this.props.molen} />}

                {this.props.molen.latestInternalTemperatureReading != null && <LiveTemperaturePanel molen={this.props.molen} />}

            </div>
            </>
        )
    }
}



export default TechLiveTab
