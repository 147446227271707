import React from 'react';

class CustomOrientationDiagramRijnEnLek extends React.Component {

    componentDidMount() {
    }

    render() {

        return (
            <>

                <defs><pattern id="pattern-1" viewBox="0 0 100 100" patternUnits="userSpaceOnUse" preserveAspectRatio="none" width="100" height="100" />
                    <pattern id="pattern-2" viewBox="0 0 100 100" patternUnits="userSpaceOnUse" preserveAspectRatio="none" width="100" height="100" />
                    <radialGradient gradientUnits="userSpaceOnUse" cx="242.364" cy="184.107" r="12.726" id="gradient-6" gradientTransform="matrix(0.227924, -0.808859, 5.021267, 2.190476, -736.195621, -22.045973)">
                        <stop offset="0" style={{ stopColor: "rgba(255, 0, 0, 1)" }} /><stop offset="1" style={{ stopColor: "rgba(153, 0, 0, 1)" }} />
                    </radialGradient>
                    <linearGradient gradientUnits="userSpaceOnUse" x1="291.855" y1="198.024" x2="291.855" y2="420.754" id="gradient-9" gradientTransform="matrix(1.56159, 0, 0, 1.53917, -163.903488, -166.812646)">
                        <stop offset="0" style={{ stopColor: "rgba(87, 107, 78, 1)" }} /><stop offset="1" style={{ stopColor: "rgba(39, 48, 35, 1)" }} /></linearGradient>
                    <linearGradient gradientUnits="userSpaceOnUse" x1="250.001" y1="193.484" x2="250.001" y2="306.515" id="gradient-4" gradientTransform="matrix(0.93522, 0, 0, 1, 16.194433, -11.001391)">
                        <stop offset="0" style={{ stopColor: "rgb(29.412% 23.137% 18.039%" }} /><stop offset="1" style={{ stopColor: "rgb(19.878% 14.205% 9.4598%)" }} /></linearGradient>
                </defs>

                <g transform="matrix(0.551818, 0, 0, 0.551818, 4.941863, 6.045529)">
                    <path d="M 288.854 138.609 Q 291.855 137.349 294.856 138.609 L 408.875 186.479 Q 411.875 187.738 413.118 190.78 L 460.346 306.348 Q 461.589 309.389 460.346 312.43 L 413.118 427.998 Q 411.875 431.04 408.875 432.299 L 294.856 480.169 Q 291.855 481.429 288.854 480.169 L 174.835 432.299 Q 171.835 431.04 170.592 427.998 L 123.364 312.43 Q 122.121 309.389 123.364 306.348 L 170.592 190.78 Q 171.835 187.738 174.835 186.479 Z" style={{ fill: "url(#gradient-9)", paintOrder: "fill", stroke: "rgb(255, 255, 255)", strokeWidth: "5px", strokeMiterLimit: "30", strokeLinecap: "square", transform: "matrix(0.920633, -0.39043, 0.39043, 0.920633, -139.486112, 79.115188)" }}>
                    </path>
                    <ellipse style={{ fill: "rgb(127, 97, 41)", stroke: "rgb(0, 0, 0)" }} cx="250" cy="250" rx="71.317" ry="71.317" />
                </g>

                <g transform={"rotate( " + this.props.molenOrientation + ", 144, 144)"}>
                    <g transform="matrix(0.551818, 0, 0, 0.551818, 4.941848, 12.115521)" name="cap" >
                        <g transform="matrix(1, 0, 0, 1.213578, 7.938299, -55.818108)">
                            <g transform="matrix(1, 0, 0, 0.871403, -4.296306, 15.114956)">
                                <g transform="matrix(1, 0, 0, 0.625002, 0, 75.766182)">
                                    <path d="M 21.831 186.466 L 248.359 186.466 L 474.885 186.466 L 474.885 194.255 L 248.359 202.044 L 21.831 193.656 L 21.831 194.255 L 21.831 186.466 Z" style={{ stroke: "rgb(255, 255, 255)", strokeWidth: "0.320615px" }} /></g></g>
                            <rect x="234.728" y="181.562" width="17.533" height="10.18" style={{ fill: "url(#gradient-6)", stroke: "rgb(128, 0, 0)" }} />
                            <rect x="74.722" y="279.625" width="195.268" height="5.456" style={{ stroke: "rgb(0, 0, 0)", fill: "rgb(226, 217, 152)", strokeWidth: "0px", transform: "matrix(-0.69217, -0.721734, -0.721734, 0.69217, 493.382967, 224.76192)" }} />
                            <rect x="74.42" y="279.625" width="194.48" height="5.456" style={{ stroke: "rgb(0, 0, 0)", fill: "rgb(226, 217, 152)", strokeWidth: "0px", transform: "matrix(0.69217, -0.721734, 0.721734, 0.69217, -9.519975, 224.446966)" }} />
                            <rect x="96.229" y="222.608" width="289.288" height="7.218" style={{ stroke: "rgb(0, 0, 0)", fill: "rgb(226, 217, 152)", strokeWidth: "0px" }} />
                            <rect x="-26.508" y="-189.229" width="69.272" height="3.692" style={{ stroke: "rgb(0, 0, 0)", fill: "rgb(226, 217, 152)", strokeWidth: "0px", transform: "matrix(0.631756, -0.775167, -0.775167, -0.631756, 113.861324, 215.038778)" }} />
                            <rect x="-26.508" y="-187.327" width="69.272" height="3.655" style={{ stroke: "rgb(0, 0, 0)", fill: "rgb(226, 217, 152)", strokeWidth: "0px", transform: "matrix(-0.631756, -0.775167, 0.775167, -0.631756, 369.467639, 215.933327)" }} />
                            <rect x="237.363" y="291.294" width="9.397" height="75.918" style={{ stroke: "rgb(0, 0, 0)", fill: "rgb(226, 217, 152)", strokeWidth: "0px" }} />
                            <rect x="195.633" y="297.817" width="92.858" height="4.965" style={{ stroke: "rgb(0, 0, 0)", fill: "rgb(226, 217, 152)", strokeWidth: "0px" }} />
                        </g>
                        <path d="M 300.708 296.75 L 189.111 296.75 L 189.111 233.795 L 300.708 233.795 L 300.708 296.75 Z M 190.111 234.795 L 190.111 295.75 L 299.708 295.75 L 299.708 234.795 L 190.111 234.795 Z" style={{ fill: "none", transform: "matrix(0, 1, -1, 0, 510.182007, 20.362972)" }} />
                        <path d="M 317.033 204.399 L 317.033 273.37 C 284.911 292.098 191.455 312.055 182.966 273.37 L 182.966 204.399 C 189.802 165.44 298.34 186.811 317.033 204.399 Z" style={{ paintOrder: "stroke", fillRule: "nonzero", stroke: "rgb(0, 0, 0)", fill: "url(#gradient-4)", transform: "matrix(0, 1, -1, 0, 488.999016, -10.999996)" }} />
                        <rect x="229.94" y="388.99" width="40.12" height="4.004" style={{ stroke: "rgb(63, 8, 8)", fill: "rgb(25, 73, 24)", strokeWidth: "0px" }} />
                        <rect x="224.657" y="389.663" width="11.262" height="2.675" style={{ fill: "rgb(216, 216, 216)" }} />
                        <rect x="223.628" y="390.196" width="11.262" height="2.675" style={{ fill: "rgb(216, 216, 216)", transform: "matrix(1, 0, 0, 1, 40.733498, -0.479471)" }} />
                    </g>
                </g>

            </>


        )
    }
}

export default CustomOrientationDiagramRijnEnLek