import React from 'react';

class CustomOrientationDiagramPolkeys extends React.Component {

    componentDidMount() {
    }

    render() {

        return (
            <>
                {/* --- mill base layer - POLKEYS --- */}
                <g name="polkeysBaseLayer">
                    <circle
                        style={{ fill: '#101010', stroke: '#101010', strokeWidth: 0.46996 }}
                        cx="144.77512"
                        cy="144.00003"
                        id="ellipse4"
                        r="45.155167" />
                    <rect id="scoop-wheel"
                        x="-148.01572"
                        y="145.63974"
                        width="13.840791"
                        height="76.469528"
                        style={{ fill: '#a0a0a0', stroke: '#a0a0a0', strokeWidth: 0.46996 }}
                        transform="rotate(-70.750886)"
                    />
                </g>


                {/* --- cap - Polkeys --- */}
                <g name="polkeysCap" transform={"rotate( " + this.props.molenOrientation + ", 144, 144)"} fill="#ffffff" strokeWidth="0" stroke="#ffffff">
                    <g
                        transform="matrix(0.34274841,0,0,0.33784907,80.72722,58.57997)"
                        id="g16">
                        <rect
                            x="120.559"
                            y="174.209"
                            width="134.117"
                            height="167.68201"
                            id="rect8" />
                        <path
                            d="m 121.833,342.072 c 0,0 -2.81903,-5.08609 -4.463,-9.244 -3.12271,-7.89794 -8.49753,-23.74112 -11.06,-35.347 -2.39532,-10.84885 -3.58432,-20.8696 -4.239,-32.084 -0.71511,-12.24956 -0.75234,-27.47804 0.569,-38.609 1.02886,-8.66718 3.12953,-15.92147 5.145,-22.839 1.73447,-5.95306 3.80107,-11.21832 5.871,-16.314 1.88198,-4.63298 5.926,-13.05 5.926,-13.05"
                            transform="rotate(0.73495292,111.70041,258.33083)"
                            id="path10" />
                        <path
                            d="m 273.007,174.695 c 0,0 -2.81903,5.08609 -4.463,9.244 -3.12271,7.89794 -8.49753,23.74112 -11.06,35.347 -2.39532,10.84885 -3.58432,20.8696 -4.239,32.084 -0.71511,12.24956 -0.75234,27.47804 0.569,38.609 1.02886,8.66718 3.12953,15.92147 5.145,22.839 1.73447,5.95306 3.80107,11.21832 5.871,16.314 1.88198,4.63298 5.926,13.05 5.926,13.05"
                            transform="rotate(179.26505,262.87977,258.43847)"
                            id="path12" />
                        <path
                            d="m 117.587,174.12 h 139.135 c -46.991,-13.868 -93.369,-13.868 -139.135,0 z"
                            id="path14" />
                    </g>

                    <g
                        id="g46"
                        transform="matrix(0.46996,0,0,0.46996,30.43477,7.91325)">
                        <rect
                            x="214.27699"
                            y="400.84"
                            width="60.244999"
                            height="1.143"
                            style={{fill:"#7e7e7e", stroke:"#7e7e7e"}}
                            id="rect18" />
                        <rect
                            x="202.01199"
                            y="347.289"
                            width="14.803"
                            height="37.330002"
                            style={{fill:"#ffffff", stroke:"#ffffff"}}
                            id="rect20" />
                        <rect
                            x="270.271"
                            y="347.57599"
                            width="14.803"
                            height="37.330002"
                            style={{fill:"#ffffff", stroke:"#ffffff"}}
                            id="rect22" />
                        <rect
                            x="198.82899"
                            y="370.746"
                            width="90.107002"
                            height="11.585"
                            style={{fill:"#ffffff", stroke:"#ffffff"}}
                            id="rect24" />
                        <g
                            transform="rotate(1.5413281,122.16379,305.21978)"
                            id="g30">
                            <polyline
                            style={{fill:"#ffffff", stroke:"#ffffff"}}
                            points="236.7 396.682 248.451 434.116 253.24 433.755 260.685 396.682"
                                transform="rotate(180,248.6925,415.399)"
                                id="polyline26" />
                            <ellipse
                                style={{fill:"#ffffff", stroke:"#ffffff"}}
                                cx="248.642"
                                cy="433.76099"
                                rx="11.894"
                                ry="4.5900002"
                                id="ellipse28" />
                        </g>
                        <g
                            transform="rotate(-177.8974,245.53084,398.78655)"
                            id="g36">
                            <polyline
                            style={{fill:"#ffffff", stroke:"#ffffff"}}
                            points="236.7 396.682 248.451 434.116 253.24 433.755 260.685 396.682"
                                transform="rotate(180,248.6925,415.399)"
                                id="polyline32" />
                            <ellipse
                            style={{fill:"#ffffff", stroke:"#ffffff"}}
                            cx="248.642"
                                cy="433.76099"
                                rx="11.894"
                                ry="4.5900002"
                                id="ellipse34" />
                        </g>
                        <rect
                            x="288.89899"
                            y="120.735"
                            width="65.421997"
                            height="1.29"
                            style={{fill:"#ffffff", stroke:"#ffffff"}}
                            transform="matrix(0.97173,0.236095,-0.36453,0.940525,-24.541237,201.84506)"
                            id="rect38" />
                        <rect
                            x="-299.70599"
                            y="-144.46201"
                            width="67.869003"
                            height="1.544"
                            style={{fill:"#ffffff", stroke:"#ffffff"}}
                            transform="matrix(-0.97173,0.236095,0.36453,0.940525,38.659104,589.66882)"
                            id="rect40" />
                        <polygon
                            style={{fill:"#ffffff", stroke:"#ffffff"}}
                            points="199.914,381.977 215.862,401.714 220.351,398.914 209.63,379.757 "
                            transform="rotate(15.025624,210.13249,390.73554)"
                            id="polygon42" />
                        <polygon
                            style={{fill:"#ffffff", stroke:"#ffffff"}}
                            points="267.894,399.312 283.842,379.575 288.331,382.375 277.61,401.532 "
                            transform="rotate(164.97438,278.1125,390.55351)"
                            id="polygon44" />
                    </g>
                    <g
                        transform="matrix(0.46996,0,0,0.40952455,28.94731,29.63647)"
                        id="g60">
                        <g
                            id="g58">
                            <rect
                                x="4.1589999"
                                y="187.161"
                                width="486.36099"
                                height="6.3299999"
                                style={{fill:"#ffffff", stroke:"#ffffff"}}
                                id="rect48" />
                            <path
                                d="m -8.89,-530.506 v 177.242 h -9.496 z"
                                style={{fill:"#ffffff", stroke:"#ffffff"}}
                                transform="matrix(0,-1,-1,0,-40.041053,185.12859)"
                                id="path50" />
                            <rect
                                x="180.291"
                                y="187.216"
                                width="132.931"
                                height="15.825"
                                style={{fill:"#ffffff", stroke:"#ffffff"}}
                                id="rect52" />
                            <path
                                d="M 27.882,530.506 V 707.748 H 18.386 Z"
                                style={{fill:"#ffffff", stroke:"#ffffff"}}
                                transform="rotate(-90,-152.19569,374.15086)"
                                id="path54" />
                            <rect
                                x="236.26199"
                                y="195.636"
                                width="21.1"
                                height="12.107"
                                style={{fill:"#ffffff", stroke:"#ffffff"}}
                                id="rect56" />
                        </g>
                    </g>
                </g>
            </>
        )
    }
}



export default CustomOrientationDiagramPolkeys