import React from 'react';
import './notTurningToWindBar.css';

class NotTurningToWindBar extends React.Component {

    componentDidMount() {
    }

    render() {

        return (
            <>

                <div className="not-turning-container">
                    <div className="not-turning-content">
                        This mill is not currently turning to wind
                    </div>
                </div>

            </>
        )
    }
}



export default NotTurningToWindBar