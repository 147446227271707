import React from 'react';

class CustomOrientationDiagramLaceyGreen extends React.Component {

    componentDidMount() {
    }

    render() {

        return (
            <>
                <defs>
                    <linearGradient gradientUnits="userSpaceOnUse" x1="135.28" y1="116.031" x2="135.28" y2="173.43" id="gradient-1" spreadMethod="reflect" gradientTransform="matrix(1.477352, 0, 0, 1.196949, 0, 0)">
                        <stop offset="0" style={{ stopColor: "rgba(80,82,107,1)" }} />
                        <stop offset="1"style={{ stopColor: "rgba(36,37,49,1)" }} />
                    </linearGradient>
                    <linearGradient gradientUnits="userSpaceOnUse" x1="135.28" y1="116.031" x2="135.28" y2="173.43" id="gradient-2" spreadMethod="pad" gradientTransform="matrix(1.477352, 0, 0, 1.196949, 0, 0)">
                        <stop offset="0" style={{ stopColor: "rgba(213,213,213,1)" }} />
                        <stop offset="1" style={{ stopColor: "rgba(162,162,162,1)" }} />
                    </linearGradient>
                </defs>
                <g transform="matrix(0.822322, 0, 0, 0.822322, 33.626835, 18.886602)">
                    <g name="base-outer" transform="matrix(1, 0, 0, -1, 0, 304.292969)">
                    <g name="base" transform="rotate(22,134,150)">
                        <path d="M 250.224 93.699 L 296.474 112.856 L 315.631 159.106 L 296.474 205.356 L 250.224 224.513 L 203.974 205.356 L 184.817 159.106 L 203.974 112.856 Z" style={{ stroke: "rgb(183,97,22)", fill: "rgb(21,23,41)", strokeWidth:"3px" }} transform="matrix(0.92388, 0.382683, -0.382683, 0.92388, -36.183746, -90.604864)" />
                    </g>
                    </g>
                    <g name="cap-outer" transform={"rotate( " + this.props.molenOrientation + ", 134, 150)"} >
                    <g name="cap" transform="matrix(1, 0.000001, 0.000001, -1, -0.000055, 305.835358)">
                        <rect x="118.361" y="88.398" width="3.225" height="35.464" style={{ stroke: "rgb(89,89,89)", fill: "rgb(89,89,89)" }} />
                        <rect x="144.691" y="88.398" width="3.225" height="35.464" style={{ stroke: "rgb(89,89,89)", fill: "rgb(89,89,89)" }} />
                        <line style={{ stroke: "rgb(89,89,89)", fill: "rgb(216,216,216)", strokeWidth: "2px" }} x1="119.973" y1="93.771" x2="146.034" y2="93.771" />
                        <line style={{ stroke: "rgb(89,89,89)", fill: "rgb(216,216,216)", strokeWidth: "2px" }} x1="119.973" y1="98.876" x2="146.303" y2="98.607" />
                        <line style={{ stroke: "rgb(89,89,89)", fill: "rgb(216,216,216)", strokeWidth: "2px" }} x1="119.973" y1="103.98" x2="146.034" y2="103.712" />
                        <line style={{ stroke: "rgb(89,89,89)", fill: "rgb(216,216,216)", strokeWidth: "2px" }} x1="119.551" y1="108.309" x2="145.612" y2="108.309" />
                        <line style={{ stroke: "rgb(89,89,89)", fill: "rgb(216,216,216)", strokeWidth: "2px" }} x1="120.672" y1="113.413" x2="145.881" y2="113.145" />
                        <rect x="164.298" y="138.882" width="71.116" height="68.704" style={{ fill: "url(#gradient-1)", paintOrder: "stroke markers", fillRule: "evenodd", stroke: "url(#gradient-2)"}} transform="matrix(0, 1, -1, 0, 306.926788, -45.011848)" />
                        <g transform="matrix(0.913917, 0, 0, 0.671002, -13.317291, 34.471104)">
                            <path d="M -146.02 -399.447 L -146.02 -256.509 L -152.747 -256.509 L -146.02 -399.447 Z" style={{ stroke: "rgb(255,255,255)", fill: "rgb(255,255,255)" }} transform="matrix(0, 1, -1, 0, -79.138802, 389.075684)" />
                            <path d="M 155.131 224.492 L 161.858 367.43 L 155.131 367.43 L 155.131 224.492 Z" style={{ stroke: "rgb(255,255,255)", fill: "rgb(255,255,255)" }} transform="matrix(0, -1, 1, 0, -222.530533, 397.906616)" />
                            <rect x="145.9" y="235.649" width="33.312" height="8.008" style={{ stroke: "rgb(255,255,255)", fill: "rgb(255,255,255)" }} />
                            <rect x="1.762" y="242.695" width="319.347" height="4.164" style={{ stroke: "rgb(255,255,255)", fill: "rgb(255,255,255)" }} />
                        </g>
                        <rect x="125.009" y="191.473" width="17.349" height="7.83" style={{ stroke: "rgb(0,0,0)" }} />
                        <line style={{ stroke: "rgb(0,0,0)", fill: "rgb(216,216,216)" }} x1="132.792" y1="120.196" x2="132.792" y2="192.737" />
                        <g transform="matrix(0.466222, 0, 0, 0.534262, 24.567719, 33.39846)">
                            <path style={{ stroke: "rgb(208,208,208)", fill: "rgb(255,255,255)" }} d="M 233.024 103.789 C 233.024 103.789 243.29796920206164 126.17251178069606 244.234 131.976 C 244.6309138818194 134.43690731447145 245.1968301440453 136.21134771366937 243.914 137.421 C 241.350550297164 139.83822008819553 224.91257017081136 139.92228984369746 222.134 137.421 C 220.66115789901002 136.09513642193517 221.11965299246117 134.02759530854914 221.493 131.335 C 222.31024869064282 125.4409659086348 230.7101310613115 105.92595787320916 232.383 104.109 C 232.6444841669574 103.82499346195917 233.024 103.789 233.024 103.789 C 233.024 103.789 233.02399999999997 103.789 233.024 103.789" />
                            <path style={{ stroke: "rgb(208,208,208)", fill: "rgb(255,255,255)" }} d="M 232.494 71.637 C 232.494 71.637 242.7679692020616 94.02051178069607 243.704 99.824 C 244.10091388181942 102.28490731447145 244.6668301440453 104.05934771366941 243.384 105.269 C 240.82055029716406 107.68622008819553 224.38257017081133 107.77028984369743 221.604 105.269 C 220.13115789901008 103.94313642193518 220.58965299246117 101.87559530854915 220.963 99.183 C 221.78024869064285 93.28896590863481 230.18013106131144 73.77395787320917 231.853 71.957 C 232.11448416695745 71.67299346195917 232.494 71.637 232.494 71.637 C 232.49399999999997 71.637 232.49399999999997 71.63699999999999 232.494 71.637" transform="matrix(-1, 0, 0, -1, 464.766647, 178.750572)" />
                        </g>
                        <line style={{ stroke: "rgb(0,0,0)", fill: "rgb(216,216,216)" }} x1="120.433" y1="89.518" x2="145.381" y2="89.903" />
                    </g>
                </g></g>
                {/*
                <defs>
                    <radialGradient gradientUnits="userSpaceOnUse" cx="252.018" cy="305.792" r="82.315" id="gradient-0" gradientTransform="matrix(1, 0, 0, 1, -0.000278, -0.000347)">
                        <stop offset="0" style={{ stopColor: "rgba(135,151,162,1)" }} />
                        <stop offset="1" style={{ stopColor: "rgba(85,100,110,1)" }} />
                    </radialGradient>
                    <linearGradient gradientUnits="userSpaceOnUse" x1="135.28" y1="116.031" x2="135.28" y2="173.43" id="gradient-1" spreadMethod="reflect" gradientTransform="matrix(1.232889, 0, 0, 0.820312, 0, 0)">
                        <stop offset="0" style={{ stopColor: "rgba(80,82,107,1)" }} />
                        <stop offset="1" style={{ stopColor: "rgba(36,37,49,1)" }} />
                    </linearGradient>
                    <linearGradient gradientUnits="userSpaceOnUse" x1="135.28" y1="116.031" x2="135.28" y2="173.43" id="gradient-2" spreadMethod="pad" gradientTransform="matrix(1.232889, 0, 0, 0.820312, 0, 0)">
                        <stop offset="0" style={{ stopColor: "rgba(213,213,213,1)" }} />
                        <stop offset="1" style={{ stopColor: "rgba(162,162,162,1)" }} />
                    </linearGradient>
                </defs>
                <g transform="matrix(1, 0, 0, 1, 2.218964, 5.546484)">
                    <g name="baseOuter" transform={"rotate(6, 142, 142)"}>
                        <g transform="matrix(1, 0, 0, 1, 5.510999, -0.000007)" name="base">
                            <path d="M 126.682 38.117 L 186.532 62.908 L 211.323 122.758 L 186.532 182.608 L 126.682 207.399 L 66.832 182.608 L 42.041 122.758 L 66.832 62.908 Z" style={{ stroke: "#000000", fill: "rgb(69,69,69)" }} transform="matrix(0.92388, 0.382683, -0.382683, 0.92388, 64.468258, -23.439196)" />
                            <path d="M 252.018 223.477 L 310.223 247.587 L 334.333 305.792 L 310.223 363.997 L 252.018 388.107 L 193.813 363.997 L 169.703 305.792 L 193.813 247.587 Z" style={{ stroke: "rgb(255, 255, 255)", paintOrder: "stroke", fill: "url(#gradient-0)" }} transform="matrix(0.92388, 0.382683, -0.382683, 0.92388, 18.81717, -240.585044)" />
                            <path d="M 250.224 104.522 L 288.821 120.509 L 304.808 159.106 L 288.821 197.703 L 250.224 213.69 L 211.627 197.703 L 195.64 159.106 L 211.627 120.509 Z" style={{ stroke: "rgb(0,0,0)", fill: "rgb(21,23,41)" }} transform="matrix(0.92388, 0.382683, -0.382683, 0.92388, -34.44025, -101.393545)" />
                            <rect x="44.897" y="695.076" width="15.883" height="9.653" style={{ stroke: "rgb(220,220,220)", fill: "rgb(255,255,255)" }} transform="matrix(0.70714, 0.707074, -0.638619, 0.775588, 511.649841, -407.480438)" />
                            <rect x="44.897" y="695.076" width="15.883" height="9.653" style={{ stroke: "rgb(220,220,220)", fill: "rgb(255,255,255)" }} transform="matrix(0.70714, 0.707074, -0.638619, 0.775588, 576.647217, -471.673492)" />
                        </g>
                    </g>
                    <g name="cap-outer" transform={"rotate( " + this.props.molenOrientation + ", 142, 142)"}>
                        {this.props.windingRAGStatus === "FAULT" && <animateTransform attributeName="transform" type="rotate" dur="4s" from="0 142 142" to="360 142 142" repeatCount="indefinite" />}
                        <g transform={"matrix(1, 0, 0, -1, 5.835068, 282.841736)"} name="cap">

                            <rect x="122.71" y="88.158" width="2.691" height="29.596" style={{ stroke: "rgb(0,0,0)", fill: "rgb(255,255,255)" }} />
                            <rect x="144.683" y="88.158" width="2.691" height="29.596" style={{ stroke: "rgb(0,0,0)", fill: "rgb(255,255,255)" }} />
                            <line style={{ stroke: "rgb(255,255,255)", fill: "rgb(216,216,216)" }} x1="124.055" y1="92.642" x2="145.804" y2="92.642" />
                            <line style={{ stroke: "rgb(255,255,255)", fill: "rgb(216,216,216)" }} x1="124.055" y1="96.902" x2="146.028" y2="96.678" />
                            <line style={{ stroke: "rgb(255,255,255)", fill: "rgb(216,216,216)" }} x1="124.055" y1="101.162" x2="145.804" y2="100.938" />
                            <line style={{ stroke: "rgb(255,255,255)", fill: "rgb(216,216,216)" }} x1="123.703" y1="104.774" x2="145.452" y2="104.774" />
                            <line style={{ stroke: "rgb(255,255,255)", fill: "rgb(216,216,216)" }} x1="123.703" y1="109.034" x2="145.676" y2="108.81" />
                            <rect x="137.111" y="95.181" width="59.348" height="47.085" style={{ fill: "url(#gradient-1)", paintOrder: "stroke markers", fillRule: "evenodd", stroke: "url(#gradient-2)" }} transform="matrix(0, 1, -1, 0, 254.228348, -23.17576)" />
                            <g transform="matrix(0.762688, 0, 0, 0.559969, 12.821077, 43.154831)">
                                <path d="M -146.02 -399.447 L -146.02 -256.509 L -152.747 -256.509 L -146.02 -399.447 Z" style={{ stroke: "rgb(255,255,255)", fill: "rgb(255,255,255)" }} transform="matrix(0, 1, -1, 0, -79.138802, 389.075684)" />
                                <path d="M 155.131 224.492 L 161.858 367.43 L 155.131 367.43 L 155.131 224.492 Z" style={{ stroke: "rgb(255,255,255)", fill: "rgb(255,255,255)" }} transform="matrix(0, -1, 1, 0, -222.530533, 397.906616)" />
                                <rect x="145.9" y="235.649" width="33.312" height="8.008" style={{ stroke: "rgb(255,255,255)", fill: "rgb(255,255,255)" }} />
                                <rect x="1.762" y="242.695" width="319.347" height="4.164" style={{ stroke: "rgb(255,255,255)", fill: "rgb(255,255,255)" }} />
                            </g>
                            <rect x="128.258" y="174.177" width="14.478" height="6.534" style={{ stroke: "rgb(0,0,0)" }} />
                            <line style={{ stroke: "rgb(0,0,0)", fill: "rgb(216,216,216)" }} x1="136.043" y1="114.694" x2="135.848" y2="168.21" />
                            <g transform="matrix(0.389074, 0, 0, 0.445856, 44.437099, 42.259686)">
                                <path style={{ stroke: "rgb(208,208,208)", fill: "rgb(255,255,255)" }} d="M 233.024 103.789 C 233.024 103.789 243.29796920206164 126.17251178069606 244.234 131.976 C 244.6309138818194 134.43690731447145 245.1968301440453 136.21134771366937 243.914 137.421 C 241.350550297164 139.83822008819553 224.91257017081136 139.92228984369746 222.134 137.421 C 220.66115789901002 136.09513642193517 221.11965299246117 134.02759530854914 221.493 131.335 C 222.31024869064282 125.4409659086348 230.7101310613115 105.92595787320916 232.383 104.109 C 232.6444841669574 103.82499346195917 233.024 103.789 233.024 103.789 C 233.024 103.789 233.02399999999997 103.789 233.024 103.789" />
                                <path style={{ stroke: "rgb(208,208,208)", fill: "rgb(255,255,255)" }} d="M 232.494 71.637 C 232.494 71.637 242.7679692020616 94.02051178069607 243.704 99.824 C 244.10091388181942 102.28490731447145 244.6668301440453 104.05934771366941 243.384 105.269 C 240.82055029716406 107.68622008819553 224.38257017081133 107.77028984369743 221.604 105.269 C 220.13115789901008 103.94313642193518 220.58965299246117 101.87559530854915 220.963 99.183 C 221.78024869064285 93.28896590863481 230.18013106131144 73.77395787320917 231.853 71.957 C 232.11448416695745 71.67299346195917 232.494 71.637 232.494 71.637 C 232.49399999999997 71.637 232.49399999999997 71.63699999999999 232.494 71.637" transform="matrix(-1, 0, 0, -1, 464.766647, 178.750572)" />
                            </g>
                            <line style={{ stroke: "rgb(0,0,0)", fill: "rgb(216,216,216)" }} x1="124.439" y1="89.093" x2="145.259" y2="89.414" />
                        </g>
                    </g>
        </g> */ }



            </>


        )
    }
}

export default CustomOrientationDiagramLaceyGreen