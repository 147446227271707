import React from 'react';

class CustomOrientationDiagramLuebberstedt extends React.Component {

    componentDidMount() {
    }

    render() {

        return (
            <>

                <defs>

                    <linearGradient gradientUnits="userSpaceOnUse" x1="291.855" y1="198.024" x2="291.855" y2="420.754" id="gradient-9" gradientTransform="matrix(0.563954, 0, 0, 0.563954, 127.26226, 134.907913)">
                        <stop offset="0" style={{ stopColor: "rgba(87,107,78,1)" }} />
                        <stop offset="1" style={{ stopColor: "rgba(39,48,35,1)" }} />
                    </linearGradient>
                    <linearGradient gradientUnits="userSpaceOnUse" x1="250.001" y1="193.484" x2="250.001" y2="306.515" id="gradient-0" gradientTransform="matrix(1, 0, 0, 1, 0.000005, -0.000008)">
                        <stop offset="0" style={{ stopColor: "rgba(67,104,88,1)" }} />
                        <stop offset="1" style={{ stopColor: "rgba(27,42,35,1)" }} />
                    </linearGradient>
                    <linearGradient gradientUnits="userSpaceOnUse" x1="102.621" y1="102.403" x2="102.621" y2="121.267" id="gradient-2" gradientTransform="matrix(-0.331059, -0.985612, 7.233273, -0.049922, -644.767031, 219.409167)">
                        <stop offset="0" style={{ stopColor: "rgba(255,255,255,1)" }} />
                        <stop offset="1" style={{ stopColor: "rgba(204,204,204,1)" }} />
                    </linearGradient>
                    <linearGradient gradientUnits="userSpaceOnUse" x1="102.621" y1="102.403" x2="102.621" y2="121.267" id="gradient-3" gradientTransform="matrix(-0.341945, -0.983624, 2.705796, -0.04962, -119.782267, 217.473071)">
                        <stop offset="0" style={{ stopColor: "rgba(255,255,255,1)" }} />
                        <stop offset="1" style={{ stopColor: "rgba(204,204,204,1)" }} />
                    </linearGradient>
                    <radialGradient gradientUnits="userSpaceOnUse" cx="298.131" cy="57.948" r="129.807" id="gradient-1" spreadMethod="pad" gradientTransform="matrix(1.745099, 0, 0, 0.307688, -272.897958, 175.825722)">
                        <stop offset="0" style={{ stopColor: "rgba(255,0,0,1)" }} />
                        <stop offset="1" style={{ stopColor: "rgba(153,0,0,1)" }} />
                    </radialGradient>
                    <radialGradient gradientUnits="userSpaceOnUse" cx="242.364" cy="184.107" r="12.726" id="gradient-6" gradientTransform="matrix(0.227924, -0.808859, 5.021267, 2.190476, -736.195621, -22.045973)">
                        <stop offset="0" style={{ stopColor: "rgba(255,0,0,1)" }} />
                        <stop offset="1" style={{ stopColor: "rgba(153,0,0,1)" }} />
                    </radialGradient>
                    <linearGradient gradientUnits="userSpaceOnUse" x1="246.299" y1="408.819" x2="246.299" y2="455.435" id="gradient-7" gradientTransform="matrix(-0.034267, 0.798662, -0.258115, -0.007072, 358.689606, 174.882614)">
                        <stop offset="0" style={{ stopColor: "rgba(255,255,255,1)" }} />
                        <stop offset="1" style={{ stopColor: "rgba(204,204,204,1)" }} />
                    </linearGradient>
                    <linearGradient gradientUnits="userSpaceOnUse" x1="246.299" y1="408.819" x2="246.299" y2="455.435" id="gradient-8" gradientTransform="matrix(-0.034267, 0.798662, -0.258115, -0.007072, 360.26117, 237.625107)">
                        <stop offset="0" style={{ stopColor: "rgba(255,255,255,1)" }} />
                        <stop offset="1" style={{ stopColor: "rgba(204,204,204,1)" }} />
                    </linearGradient>
                </defs>

                <g>

                    <g name="base">
                        <path d="M 290.771 246.816 Q 291.855 246.354 292.939 246.816 L 334.116 264.355 Q 335.199 264.817 335.648 265.931 L 352.704 308.275 Q 353.153 309.389 352.704 310.503 L 335.648 352.847 Q 335.199 353.961 334.116 354.423 L 292.939 371.962 Q 291.855 372.424 290.771 371.962 L 249.594 354.423 Q 248.511 353.961 248.062 352.847 L 231.006 310.503 Q 230.557 309.389 231.006 308.275 L 248.062 265.931 Q 248.511 264.817 249.594 264.355 Z" style={{ stroke: "rgb(0, 0, 0)", fill: "url(#gradient-9)" }} transform="matrix(0.986912, -0.161262, 0.161262, 0.986912, -193.927928, -114.274593)">
                        </path>
                    </g>

                    <g transform="matrix(0.563954, 0, 0, 0.563954, 2.133087, -2.072748)" name="cap-outer">

                        <g name="cap-outer" transform={"rotate( " + this.props.molenOrientation + ", 252, 252)"}>
                            {this.props.windingRAGStatus === "FAULT" && <animateTransform attributeName="transform" type="rotate" dur="4s" from="0 252 252" to="360 252 252" repeatCount="indefinite" />}

                            <g transform="matrix(0.799132, 0, 0, 1, 55.105695, -28.191562)">
                                <title>Fanstage</title>
                                <rect x="202.012" y="347.289" width="5.188" height="37.33" style={{ fill: "rgb(99, 99, 99)" }}>
                                    <title>LH Sheer</title>
                                </rect>
                                <rect x="279.321" y="347.576" width="5.753" height="37.33" style={{ fill: "rgb(99, 99, 99)" }}>
                                    <title>RH Sheer</title>
                                </rect>
                                <rect x="198.829" y="376.402" width="90.107" height="5.929" style={{ fill: "rgb(99, 99, 99)" }}>
                                    <title>Cross Beam</title>
                                </rect>
                            </g>

                            <path d="M 321.677 215.399 L 321.677 284.37 C 287.33 303.098 187.401 323.055 178.324 284.37 L 178.324 215.399 C 185.633 176.44 301.689 197.811 321.677 215.399 Z" style={{paintOrder: "stroke", fillRule: "nonzero", stroke: "rgb(0, 0, 0)", strokeWidth: "2px", fill: "url(#gradient-0)"}} transform="matrix(0, 1, -1, 0, 500.00003, -0.000978)" />
                            <g transform="matrix(1, 0, 0, 1, 9.127077, -12.040446)">
                                <rect x="4.421" y="102.403" width="196.4" height="18.864" style={{stroke: "rgb(0, 0, 0)", fill: "url(#gradient-2)"}} transform="matrix(1, 0.000067, -0.164469, 0.999989, 288.252686, 71.678658)" />
                                <rect x="4.421" y="102.403" width="196.4" height="18.864" style={{stroke: "rgb(0, 0, 0)", fill: "url(#gradient-3)"}} transform="matrix(-1, -0.000067, 0.164469, -0.999989, 196.608673, 300.229797)" />
                                <g transform="matrix(1, 0, 0, 0.871403, -4.296306, 15.114956)">
                                    <title>Stocks</title>
                                    <g transform="matrix(1, 0, 0, 0.625002, 0, 75.766182)">
                                        <path d="M 21.831 186.466 L 248.359 186.466 L 474.885 186.466 L 474.885 194.255 L 248.359 202.044 L 21.831 193.656 L 21.831 194.255 L 21.831 186.466 Z" style={{fill: "url(#gradient-1)", stroke: "rgb(255, 255, 255)", strokeWidth: "0.320615px"}} />
                                    </g>
                                </g>
                                <rect x="234.728" y="181.562" width="17.533" height="10.18" style={{fill: "url(#gradient-6)", stroke: "rgb(128, 0, 0)"}} />
                            </g>
                            <path d="M 300.708 296.75 L 189.111 296.75 L 189.111 233.795 L 300.708 233.795 L 300.708 296.75 Z M 190.111 234.795 L 190.111 295.75 L 299.708 295.75 L 299.708 234.795 L 190.111 234.795 Z" style={{fill: "none"}} transform="matrix(0, 1, -1, 0, 510.181995, 20.363001)" />
                            <g transform="matrix(0.799132, 0, 0, 1, 53.80311, -47.111429)">
                                <path d="M 232.34 352.146 C 237.421 343.814 252.008 344.298 257.113 352.146 L 254.232 392.299 L 237.525 392.693 L 232.34 352.146 Z" style={{stroke: "rgb(0, 0, 0)", strokeWidth: "0.333494px", fill: "url(#gradient-7)"}} />
                                <path d="M 233.912 414.888 C 238.993 406.556 253.58 407.04 258.685 414.888 L 255.804 455.041 L 239.097 455.435 L 233.912 414.888 Z" style={{stroke: "rgb(0, 0, 0)", strokeWidth: "0.333494px", fill: "url(#gradient-8)"}} transform="matrix(-1, 0, 0, -1, 492.597, 864.25415)" />
                                <rect x="244.033" y="347.285" width="2.786" height="108.031" style={{fill: "rgb(255, 255, 255)"}}>
                                    <title>Fly Stocks</title>
                                </rect>
                            </g>
                        </g>
                    </g>
                </g>
            </>
        )
    }
}

export default CustomOrientationDiagramLuebberstedt