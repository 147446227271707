import React from 'react';

class CustomOrientationDiagramLabbus extends React.Component {

    componentDidMount() {
    }

    render() {

        return (
            <>
                <defs>
                    <radialGradient gradientUnits="userSpaceOnUse" cx="291.855" cy="309.389" r="101.65" id="gradient-4" gradientTransform="matrix(0.500565, -0.259767, 0.509173, 0.981166, -11.769656, 81.641655)">
                        <stop offset="0" style={{ stopColor: "rgba(147, 65, 79, 1)" }} />
                        <stop offset="1" style={{ stopColor: "rgba(76, 34, 41, 1)" }} />
                    </radialGradient>
                    <linearGradient gradientUnits="userSpaceOnUse" x1="204.606" y1="347.289" x2="204.606" y2="384.619" id="gradient-10">
                        <stop offset="0" style={{ stopColor: "rgba(255, 0, 0, 1)" }} />
                        <stop offset="1" style={{ stopColor: "rgba(153, 0, 0, 1)" }} />
                    </linearGradient>
                    <linearGradient gradientUnits="userSpaceOnUse" x1="282.198" y1="347.576" x2="282.198" y2="384.906" id="gradient-11">
                        <stop offset="0" style={{ stopColor: "rgba(255, 0, 0, 1)" }} />
                        <stop offset="1" style={{ stopColor: "rgba(153, 0, 0, 1)" }} />
                    </linearGradient>
                    <linearGradient gradientUnits="userSpaceOnUse" x1="243.882" y1="376.402" x2="243.882" y2="382.331" id="gradient-12">
                        <stop offset="0" style={{ stopColor: "rgba(255, 0, 0, 1)" }} />
                        <stop offset="1" style={{ stopColor: "rgba(153, 0, 0, 1)" }} />
                    </linearGradient>
                    <linearGradient gradientUnits="userSpaceOnUse" x1="284.502" y1="28.281" x2="284.502" y2="88.236" id="gradient-0" gradientTransform="matrix(1.352111, -0.043287, 0.028302, 0.884024, -144.882523, 249.721039)" spreadMethod="pad">
                        <stop offset="0" style={{ stopColor: "rgba(48, 159, 111, 1)" }} />
                        <stop offset="1" style={{ stopColor: "rgba(24, 81, 56, 1)" }} />
                    </linearGradient>
                    <linearGradient gradientUnits="userSpaceOnUse" x1="102.621" y1="102.403" x2="102.621" y2="121.267" id="gradient-2" gradientTransform="matrix(-0.331059, -0.985612, 7.233273, -0.049922, -644.767031, 219.409167)">
                        <stop offset="0" style={{ stopColor: "rgba(255, 255, 255, 1)" }} />
                        <stop offset="1" style={{ stopColor: "rgba(204, 204, 204, 1)" }} />
                    </linearGradient>
                    <linearGradient gradientUnits="userSpaceOnUse" x1="102.621" y1="102.403" x2="102.621" y2="121.267" id="gradient-3" gradientTransform="matrix(-0.341945, -0.983624, 2.705796, -0.04962, -119.782267, 217.473071)">
                        <stop offset="0" style={{ stopColor: "rgba(255, 255, 255, 1)" }} />
                        <stop offset="1" style={{ stopColor: "rgba(204, 204, 204, 1)" }} />
                    </linearGradient>
                    <radialGradient gradientUnits="userSpaceOnUse" cx="298.131" cy="57.948" r="129.807" id="gradient-1" spreadMethod="pad" gradientTransform="matrix(1.745099, 0, 0, 0.307688, -272.897958, 175.825722)">
                        <stop offset="0" style={{ stopColor: "rgba(255, 0, 0, 1)" }} />
                        <stop offset="1" style={{ stopColor: "rgba(153, 0, 0, 1)" }} />
                    </radialGradient>
                    <radialGradient gradientUnits="userSpaceOnUse" cx="242.364" cy="184.107" r="12.726" id="gradient-6" gradientTransform="matrix(0.227924, -0.808859, 5.021267, 2.190476, -736.195621, -22.045973)">
                        <stop offset="0" style={{ stopColor: "rgba(255, 0, 0, 1)" }} />
                        <stop offset="1" style={{ stopColor: "rgba(153, 0, 0, 1)" }} />
                    </radialGradient>
                    <linearGradient gradientUnits="userSpaceOnUse" x1="246.299" y1="408.819" x2="246.299" y2="455.435" id="gradient-7" gradientTransform="matrix(-0.034267, 0.798662, -0.258115, -0.007072, 358.689606, 174.882614)">
                        <stop offset="0" style={{ stopColor: "rgba(255, 255, 255, 1)" }} />
                        <stop offset="1" style={{ stopColor: "rgba(204, 204, 204, 1)" }} />
                    </linearGradient>
                    <linearGradient gradientUnits="userSpaceOnUse" x1="246.299" y1="408.819" x2="246.299" y2="455.435" id="gradient-8" gradientTransform="matrix(-0.034267, 0.798662, -0.258115, -0.007072, 360.26117, 237.625107)">
                        <stop offset="0" style={{ stopColor: "rgba(255, 255, 255, 1)" }} />
                        <stop offset="1" style={{ stopColor: "rgba(204, 204, 204, 1)" }} />
                    </linearGradient>
                    <linearGradient gradientUnits="userSpaceOnUse" x1="245.426" y1="347.285" x2="245.426" y2="455.316" id="gradient-5">
                        <stop offset="0" style={{ stopColor: "rgba(255, 0, 0, 1)" }} />
                        <stop offset="1" style={{ stopColor: "rgba(153, 0, 0, 1)" }} />
                    </linearGradient>
                </defs>

                <g name="base" transform="matrix(1, 0, 0, 1, 9.794544, 0)">
                    <path d="M 290.838 250.654 Q 291.855 250.221 292.872 250.654 L 331.523 267.118 Q 332.54 267.551 332.961 268.597 L 348.971 308.343 Q 349.392 309.389 348.971 310.435 L 332.961 350.181 Q 332.54 351.227 331.523 351.66 L 292.872 368.124 Q 291.855 368.557 290.838 368.124 L 252.187 351.66 Q 251.17 351.227 250.749 350.181 L 234.739 310.435 Q 234.318 309.389 234.739 308.343 L 250.749 268.597 Q 251.17 267.551 252.187 267.118 Z" style={{ stroke: "rgb(0, 0, 0)", fill: "url(#gradient-4" }} transform="matrix(1, -0.000057, 0.000057, 1, -157.66719, -165.903213)">
                    </path>
                </g>




                <g name="cap-outer" transform={"rotate( " + this.props.molenOrientation + ", 144, 144)"}>
                    {this.props.windingRAGStatus === "FAULT" && <animateTransform attributeName="transform" type="rotate" dur="4s" from="0 142 142" to="360 142 142" repeatCount="indefinite" />}
                    <g transform="matrix(0.563954, 0, 0, 0.563954, 8.556181, -6.764877)" name="cap">
                        <g transform="matrix(0.799132, 0, 0, 1, 48.988007, -9.968327)">
                            <title>Fanstage</title>
                            <rect x="202.012" y="347.289" width="5.188" height="37.33" style={{ fill: "url(#gradient-10)" }}>
                                <title>LH Sheer</title>
                            </rect>
                            <rect x="279.321" y="347.576" width="5.753" height="37.33" style={{ fill: "url(#gradient-11)" }}>
                                <title>RH Sheer</title>
                            </rect>
                            <rect x="198.829" y="376.402" width="90.107" height="5.929" style={{ fill: "url(#gradient-12)" }}>
                                <title>Cross Beam</title>
                            </rect>
                        </g>
                        <path d="M 317.669 230.084 L 317.669 305.31 C 281.821 325.736 177.526 347.503 168.052 305.31 L 168.052 230.084 C 175.681 187.592 296.808 210.901 317.669 230.084 Z" style={{ fill: "url(#gradient-0)", paintOrder: "stroke", fillRule: "nonZero", stroke: "rgb(0, 0, 0)", strokeWidth: "2px" }} transform="matrix(0, 1, -1, 0, 510.682854, 24.961845)" />
                        <g transform="matrix(1, 0, 0, 1, -2.262444, -0.081448)">
                            <rect x="4.421" y="102.403" width="196.4" height="18.864" style={{fill: "url(#gradient-2)", stroke: "rgb(0,0,0)"}} transform="matrix(1, 0.000067, -0.164469, 0.999989, 288.252686, 71.678658)" />
                            <rect x="4.421" y="102.403" width="196.4" height="18.864" style={{fill: "url(#gradient-3)", stroke: "rgb(0,0,0)"}} transform="matrix(-1, -0.000067, 0.164469, -0.999989, 196.608673, 300.229797)" />
                            <g transform="matrix(1, 0, 0, 0.871403, -4.296306, 15.114956)">
                                <g transform="matrix(1, 0, 0, 0.625002, 0, 75.766182)">
                                    <path d="M 21.831 186.466 L 248.359 186.466 L 474.885 186.466 L 474.885 194.255 L 248.359 202.044 L 21.831 193.656 L 21.831 194.255 L 21.831 186.466 Z" style={{fill: "url(#gradient-1)", stroke: "rgb(255,255,255)", strokeWidth: "0.32px"}} />
                                </g>
                            </g>
                            <rect x="234.728" y="181.562" width="17.533" height="10.18" style={{fill: "url(#gradient-6)", stroke: "rgb(128,0,0)"}} />
                        </g>
                        <path d="M 300.708 296.75 L 189.111 296.75 L 189.111 233.795 L 300.708 233.795 L 300.708 296.75 Z M 190.111 234.795 L 190.111 295.75 L 299.708 295.75 L 299.708 234.795 L 190.111 234.795 Z" style={{fill: "none"}} transform="matrix(0, 1, -1, 0, 510.181999, 20.362999)" />
                        <g transform="matrix(0.799132, 0, 0, 1, 48.084011, -32.027149)">
                            <path d="M 232.34 352.146 C 237.421 343.814 252.008 344.298 257.113 352.146 L 254.232 392.299 L 237.525 392.693 L 232.34 352.146 Z" style={{fill: "url(#gradient-7)", stroke: "rgb(0,0,0)", strokeWidth: "0.34px"}} />
                            <path d="M 233.912 414.888 C 238.993 406.556 253.58 407.04 258.685 414.888 L 255.804 455.041 L 239.097 455.435 L 233.912 414.888 Z" style={{fill: "url(#gradient-8)", stroke: "rgb(0,0,0)", strokeWidth: "0.34px"}} transform="matrix(-1, 0, 0, -1, 492.597, 864.25415)" />
                            <rect x="244.033" y="347.285" width="2.786" height="108.031" style={{fill: "url(#gradient-5)"}}>
                            </rect>
                        </g>
                    </g>
                </g>
            </>
        )
    }
}

export default CustomOrientationDiagramLabbus