import React from 'react';
import { DataGrid, Column } from 'devextreme-react/data-grid';
import SailRotationData from './SailRotationData';

class SailRotationDetailsTemplate extends React.Component {

    constructor(props) {
        super(props);
        this.dataSource = getGateways(props.data.key);
        this.loRaWANMessageInfo = getLoRaWANMessageInfo(props.data.key);
    }


    render() {

        return (
            <React.Fragment>

                <div className="master-detail-caption">
                    { /* TODO: pass through and display battery voltage */ }
                    <b>Transmission data rate: </b>SF{this.loRaWANMessageInfo.spreadingFactor}BW{this.loRaWANMessageInfo.bandwidth / 1000}&nbsp;&nbsp;&nbsp;&nbsp;
                    <b>Airtime: </b>{this.loRaWANMessageInfo.airtimeMs}ms<br />
                    <br />
                </div>


                <div className="master-detail-caption">
                    <b>Receiving LoRaWAN Gateways</b> (details provided by gateway owner)
                </div>
                <DataGrid
                    dataSource={this.dataSource}
                    showBorders={true}
                    columnAutoWidth={true}
                    wordWrapEnabled={true}
                >
                    <Column dataField="name" caption="Name" />
                    <Column dataField="location.latitude" width={50} caption="Lat" />
                    <Column dataField="location.longitude" width={50} caption="Long" />
                    <Column dataField="distanceKm" width={60} caption="Dist (km)" />
                    <Column dataField="rssi" width={50} caption="RSSI" />
                    <Column dataField="snr" width={50} caption="SNR" />
                </DataGrid>
            </React.Fragment>
        )
    }
}

function getLoRaWANMessageInfo(key) {
    var readings = SailRotationData.getSailRotationData();
    return readings.find(r => r.id === key).loRaWANMessageInfo;
}


function getGateways(key) {
    var readings = SailRotationData.getSailRotationData();
    return readings.find(r => r.id === key).loRaWANMessageInfo.gateways;
}


export default SailRotationDetailsTemplate