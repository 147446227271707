import React from 'react'
import '../../../SmartMolen.css';
import '../../../Panels.css';
import './UpmVisitorCentreScreen2.css';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import MetaTags from 'react-meta-tags';

class UpmVisitorCentreScreen2 extends React.Component {

  state = {
    name: null
  }

  componentDidMount() {


    const hubConnection = new HubConnectionBuilder()
      .withUrl(`https://` + process.env.REACT_APP_SIGNALR_API + `/`)
      .configureLogging(LogLevel.Information)
      .withAutomaticReconnect()
      .build();

    console.log(hubConnection);

    this.setState({ hubConnection }, () => {
      this.state.hubConnection
        .start()
        .then(() => {
          console.log('Connection started!');
        })
        .catch(err => {
          console.log('Error while establishing connection :(');
          console.log(err);
        });


      this.state.hubConnection.on('molenUpdated',
        (receivedMessage) => {
          this.reloadState();
        });

    });

    this.reloadState();

  }

  reloadState() {

    fetch(`https://` + process.env.REACT_APP_SMARTMOLEN_API + `/api/molen/upminster`)
      .then(response => response.json())
      .then(response => {
        this.setState({
          molen: response,
        })

      });

  }

  render() {
    if (this.state == null || this.state.molen == null) {
      return "Loading ..."
    }
    else {
      return this.renderLoaded();
    }
  }


  renderLoaded() {
    return (
      <>
        <MetaTags>
          <title>SmartMolen: Upminster Visitor Centre Screen 2</title>
        </MetaTags>


        <div className="vcs-background">
        </div>

        <div className="vcs-screen-test">Test screen - you should be able to see the full red border of this box with an even space in grey around the outside</div>

      </>
    )
  }
}

export default UpmVisitorCentreScreen2