import React from 'react'
import moment from 'moment';
import {
    Animation,
    Chart,
    Series,
    Point,
    Legend,
    ValueAxis,
    ArgumentAxis,
    Grid,
    MinorGrid,
    CommonPaneSettings,
    Border
} from 'devextreme-react/chart';
import './orientationSensorCalibrationDetails.css';

var calibration = null;
var calibrationGraphDataSource = null;
var rawGraphDataSource = null;

class OrientationSensorCalibrationDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = { graphView: "TRANSFORMED" }
    }

    setGraphView = (graphView) => {
        this.setState({ graphView });
    };

    componentDidMount() {
        this.loadCalibration();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.calibrationSummary != null && prevProps.calibrationSummary.calibrationUtcTime !== this.props.calibrationSummary.calibrationUtcTime) {
            this.loadCalibration();
        }

    }

    loadCalibration() {
        if (this.props != null && this.props.calibrationSummary != null) {
            fetch(`https://` + process.env.REACT_APP_SMARTMOLEN_API + `/api/sensorCalibration/` + this.props.calibrationSummary.calibrationId)
                .then(response => response.json())
                .then(response => {
                    calibration = response;
                    this.generateCalibrationGraphData();
                    this.generateRawGraphData();
                    this.setState({ readingLoadTime: new Date() })
                });
        }
    }

    async generateCalibrationGraphData() {
        calibrationGraphDataSource = [];
        var dataPoints = calibration.transformationData.dataPoints.split(";");

        // dot for each reading ("x"/"y" series)
        dataPoints.forEach((element, index, array) => {
            var xySplit = element.split(",");
            calibrationGraphDataSource.push({ x: parseFloat(xySplit[0]), y: parseFloat(xySplit[1]) });
        });

        var cX = calibration.calculatedCalibration.centreX;
        var cY = calibration.calculatedCalibration.centreY;
        var r = calibration.calculatedCalibration.r;

        // green dot for centre point ("cX"/"cY" series)
        calibrationGraphDataSource.push({ cX: cX, cY: cY });

        var i, iRads;

        var rEPos = r + (calibration.calibrationQA.rStdDev * 2);
        var rENeg = r - (calibration.calibrationQA.rStdDev * 2);

        // lines for calibration best fit ("cXLine"/"cYLine" series) and error either side of that (eXPos / Neg, etc.)

        for (i = -180; i <= 0; i++) {
            iRads = i * Math.PI / 180;
            calibrationGraphDataSource.push({
                cXLineUpper: cX + (r * Math.cos(iRads)), cYLineUpper: cY + (r * Math.sin(iRads)),
                eXPosLineUpper: cX + (rEPos * Math.cos(iRads)), eYPosLineUpper: cY + (rEPos * Math.sin(iRads)),
                eXNegLineUpper: cX + (rENeg * Math.cos(iRads)), eYNegLineUpper: cY + (rENeg * Math.sin(iRads))
            });
        }

        for (i = 180; i >= 0; i--) {
            iRads = i * Math.PI / 180;
            calibrationGraphDataSource.push({
                cXLineLower: cX + (r * Math.cos(iRads)), cYLineLower: cY + (r * Math.sin(iRads)),
                eXPosLineLower: cX + (rEPos * Math.cos(iRads)), eYPosLineLower: cY + (rEPos * Math.sin(iRads)),
                eXNegLineLower: cX + (rENeg * Math.cos(iRads)), eYNegLineLower: cY + (rENeg * Math.sin(iRads))
            });
        }

        this.setState({ readingLoadTime: new Date() })
    }

    async generateRawGraphData() {
        rawGraphDataSource = [];
        var dataPoints = calibration.sourceData.dataPoints.split(";");

        // dot for each reading ("x"/"y" series)
        dataPoints.forEach((element, index, array) => {
            var xySplit = element.split(",");
            rawGraphDataSource.push({ x: parseFloat(xySplit[0]), y: parseFloat(xySplit[1]) });
        });

        this.setState({ readingLoadTime: new Date() })
    }


    render() {
        if (this.props.calibrationSummary == null || calibration == null) {
            return <span>Loading ...</span>
        }
        if (this.props.calibrationSummary.calibrationType === "MANUAL") {
            return this.renderManuallyCalibrated();
        }
        else {
            return this.renderAutoCalibrated();
        }
    }

    renderManuallyCalibrated() {
        return (
            <>
                <div className="panel-body">
                    <div className="panel-columns">
                        <div className="panel-column">
                            OVERVIEW
                            <div className="panel-item">
                                <table className="table-small">
                                    <tr><td>Calibration Type</td><td>Manual</td></tr>
                                </table>
                            </div>
                            <br />

                            CALIBRATION
                            <div className="panel-item">
                                <table className="table-small">
                                    <tr><td>CalibX</td><td>{this.props.calibrationSummary.magnetometerXCalibration}</td></tr>
                                    <tr><td>CalibY</td><td>{this.props.calibrationSummary.magnetometerYCalibration}</td></tr>
                                </table>
                            </div>
                            <br />
                        </div>
                    </div>
                </div>
            </>
        )
    }

    renderAutoCalibrated() {
        return (
            <>
                <div className="panel-body">
                    <div className="panel-columns">
                        <div className="panel-column">
                            CALIBRATION OVERVIEW
                            <div className="panel-item">
                                <table className="table-small">
                                    <tr><td>Calibration Type</td><td>Automatic</td></tr>
                                    <tr><td>Start Time</td><td>{moment(calibration.calibrationStartUtcTime).format("DD/MM/yyyy HH:mm:ss")}</td></tr>
                                    <tr><td>Finish Time</td><td>{moment(calibration.calibrationFinishUtcTime).format("DD/MM/yyy HH:mm:ss")}</td></tr>
                                </table>
                            </div>
                            <br />

                            SOURCE DATA
                            <div className="panel-item">
                                <table className="table-small">
                                    <tr><td>Data Points Used</td><td>{calibration.sourceData.dataPointsCount}</td></tr>
                                    <tr><td>Earliest</td><td>{moment(calibration.sourceData.fromUtcTime).format("DD/MM/yyy HH:mm:ss")}</td></tr>
                                    <tr><td>Latest</td><td>{moment(calibration.sourceData.toUtcTime).format("DD/MM/yyy HH:mm:ss")}</td></tr>
                                </table>
                            </div>
                            <br />

                            CALCULATED CALIBRATION
                            <div className="panel-item">
                                <table className="table-small">
                                    <tr><td>CalibX</td><td>{calibration.calculatedCalibration.centreX}</td></tr>
                                    <tr><td>CalibY</td><td>{calibration.calculatedCalibration.centreY}</td></tr>
                                    <tr><td>Radius</td><td>{calibration.calculatedCalibration.r}</td></tr>
                                    <tr><td>StdDev (&sigma;) Radius</td><td>{Math.round(calibration.calibrationQA.rStdDev * 1000) / 1000}</td></tr>
                                </table>
                            </div>
                            <br />

                            COMPASS POINT COVERAGE
                            <div className="panel-item">
                                <table className="table-small">
                                    <thead>
                                        <th>&sigma; Radius</th>
                                        <th>Error<br />(degrees)</th>
                                        <th>% Points</th>
                                        <th>Compass<br />Coverage</th>
                                    </thead>
                                    <tbody>
                                        {calibration.calibrationQA.readingCountBySigma.map(item => (
                                            <tr>
                                                <td>{item.sigmaLowerBound + 1}&sigma;</td>
                                                <td align="right">{Math.round(calibration.calibrationQA.rStdDevAsCircumferenceDegrees * (item.sigmaLowerBound + 1) * 100) / 100}</td>
                                                <td align="right">{Math.round(1000 * (item.readingCount) / calibration.sourceData.dataPointsCount) / 10}%</td>
                                                <td align="right">{item.sixteenPointCompassCoverage}/16</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <br />

                            CALIBRATION QA
                            <div className="panel-item">
                                <table className="table-small">
                                    <thead>
                                        <th>Check</th>
                                        <th>Result</th>
                                    </thead>
                                    <tbody>
                                        {calibration.calibrationQA.qaChecks.map(item => (
                                            <tr>
                                                <td width={200}>{item.description}</td>
                                                <td >{item.pass ? <>PASS</> : <>FAIL</>}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                                <br />
                                Outcome: {calibration.calibrationQA.passed ? "PASS" : "FAIL"}
                            </div>
                        </div>

                        <div className="panel-column">

                            <div className="panel-item-light" width="100%">
                                {this.state.graphView === "RAW" &&
                                    <Chart id="raw-chart" dataSource={rawGraphDataSource}>

                                        <Animation
                                            enabled={false}
                                        />

                                        <Series
                                            color="#0f4c81"
                                            name="Readings"
                                            type="scatter"
                                            valueField="y"
                                            argumentField="x" >
                                            <Point symbol="circle" size="2" />
                                        </Series>

                                        <ArgumentAxis
                                            tickInterval={100}
                                            minorTickInterval={10}
                                        >
                                            <Grid visible={true} />
                                            <MinorGrid visible={true} />
                                        </ArgumentAxis>
                                        <ValueAxis tickInterval={100}
                                            minorTickInterval={10}
                                        >
                                            <Grid visible={true} />
                                            <MinorGrid visible={true} />
                                        </ValueAxis>
                                        <Legend visible={true} position="outside"
                                            horizontalAlignment="center"
                                            verticalAlignment="bottom" />
                                        <CommonPaneSettings backgroundColor="#FFFFFF">
                                            <Border visible={true} />
                                        </CommonPaneSettings>
                                    </Chart>
                                }

                                {this.state.graphView === "TRANSFORMED" &&

                                    <Chart id="calib-chart" dataSource={calibrationGraphDataSource} >

                                        <Animation
                                            enabled={false}
                                        />

                                        <Series
                                            color="#0f4c81"
                                            name="Readings"
                                            type="scatter"
                                            valueField="y"
                                            argumentField="x" >
                                            <Point symbol="circle" size="2" />
                                        </Series>

                                        <Series
                                            color="#00B000"
                                            type="scatter"
                                            showInLegend={false}
                                            valueField="cY"
                                            argumentField="cX" >
                                            <Point symbol="circle" size="12" />
                                        </Series>

                                        <Series
                                            color="#00B000"
                                            name="Best Fit Circle &amp; Centre Point"
                                            type="line"
                                            width="3"
                                            valueField="cYLineUpper"
                                            argumentField="cXLineUpper" >
                                            <Point symbol="none" />
                                        </Series>

                                        <Series
                                            color="#00B000"
                                            type="line"
                                            width="3"
                                            showInLegend={false}
                                            valueField="cYLineLower"
                                            argumentField="cXLineLower" >
                                            <Point symbol="none" />
                                        </Series>

                                        <Series
                                            color="#FF0000"
                                            type="line"
                                            name="2&sigma;.r Error Range"
                                            showInLegend={true}
                                            valueField="eYPosLineUpper"
                                            argumentField="eXPosLineUpper" >
                                            <Point symbol="none" />
                                        </Series>
                                        <Series
                                            color="#FF0000"
                                            type="line"
                                            showInLegend={false}
                                            valueField="eYPosLineLower"
                                            argumentField="eXPosLineLower" >
                                            <Point symbol="none" />
                                        </Series>

                                        <Series
                                            color="#FF0000"
                                            type="line"
                                            showInLegend={false}
                                            valueField="eYNegLineUpper"
                                            argumentField="eXNegLineUpper" >
                                            <Point symbol="none" />
                                        </Series>
                                        <Series
                                            color="#FF0000"
                                            type="line"
                                            showInLegend={false}
                                            valueField="eYNegLineLower"
                                            argumentField="eXNegLineLower" >
                                            <Point symbol="none" />
                                        </Series>

                                        <ArgumentAxis
                                            tickInterval={100}
                                            minorTickInterval={10}
                                        >
                                            <Grid visible={true} />
                                            <MinorGrid visible={true} />
                                        </ArgumentAxis>
                                        <ValueAxis tickInterval={100}
                                            minorTickInterval={10}
                                        >
                                            <Grid visible={true} />
                                            <MinorGrid visible={true} />
                                        </ValueAxis>
                                        <Legend visible={true} position="outside"
                                            horizontalAlignment="center"
                                            verticalAlignment="bottom" />
                                        <CommonPaneSettings backgroundColor="#FFFFFF">
                                            <Border visible={true} />
                                        </CommonPaneSettings>
                                    </Chart>}

                                <div className='table-small' align="center">Data:&nbsp;
                                    <>
                                        <input type="radio" name="graphViewSelector" checked={this.state.graphView === "RAW"} onClick={(e) => this.setGraphView("RAW")} />Raw&nbsp;&nbsp;&nbsp;
                                        <input type="radio" name="graphViewSelector" checked={this.state.graphView === "TRANSFORMED"} onClick={(e) => this.setGraphView("TRANSFORMED")} />Transformed
                                    </>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </>


        )
    }
}

export default OrientationSensorCalibrationDetails
