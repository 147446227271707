import React from 'react';

class CustomOrientationDiagramFulwell extends React.Component {

    componentDidMount() {
    }

    render() {

        return (
            <>
                <defs>
                    <radialGradient gradientUnits="userSpaceOnUse" cx="250.224" cy="159.105" r="47.488" id="gradient-1" spreadMethod="pad" gradientTransform="matrix(1, 0, 0, 1, -0.000095, -0.00006)">
                        <stop offset="0" style={{ stopColor: "rgba(255,255,255,1)" }} />
                        <stop offset="1" style={{ stopColor: "rgba(204,204,204,1)" }} />
                    </radialGradient>
                    <radialGradient gradientUnits="userSpaceOnUse" cx="320.667" cy="275.667" r="29.719" id="gradient-0" gradientTransform="matrix(1, 0, 0, 1, -0.000092, -0.000091)">
                        <stop offset="0" style={{ stopColor: "rgba(60,60,60,1)" }} />
                        <stop offset="1" style={{ stopColor: "rgba(9,9,9,1)" }} />
                    </radialGradient>
                </defs>

                <g name="base" transform="matrix(1, 0, 0, 1, 3.199883, -2.000301)">
                    <path d="M 244.272 111.994 Q 250.224 111.242 256.176 111.994 L 256.176 111.994 Q 262.127 112.746 267.705 114.954 L 267.705 114.954 Q 273.283 117.162 278.136 120.689 L 278.136 120.689 Q 282.989 124.215 286.813 128.837 L 286.813 128.837 Q 290.637 133.459 293.191 138.887 L 293.191 138.887 Q 295.745 144.315 296.869 150.208 L 296.869 150.208 Q 297.994 156.101 297.617 162.088 L 297.617 162.088 Q 297.24 168.075 295.386 173.78 L 295.386 173.78 Q 293.533 179.486 290.318 184.551 L 290.318 184.551 Q 287.104 189.616 282.731 193.722 L 282.731 193.722 Q 278.358 197.829 273.101 200.719 L 273.101 200.719 Q 267.844 203.609 262.033 205.101 L 262.033 205.101 Q 256.223 206.593 250.224 206.593 L 250.224 206.593 Q 244.225 206.593 238.415 205.101 L 238.415 205.101 Q 232.604 203.609 227.347 200.719 L 227.347 200.719 Q 222.09 197.829 217.717 193.722 L 217.717 193.722 Q 213.344 189.616 210.13 184.551 L 210.13 184.551 Q 206.915 179.486 205.062 173.78 L 205.062 173.78 Q 203.208 168.075 202.831 162.088 L 202.831 162.088 Q 202.454 156.101 203.579 150.208 L 203.579 150.208 Q 204.703 144.315 207.257 138.887 L 207.257 138.887 Q 209.811 133.459 213.635 128.837 L 213.635 128.837 Q 217.459 124.215 222.312 120.689 L 222.312 120.689 Q 227.165 117.162 232.743 114.954 L 232.743 114.954 Q 238.321 112.746 244.272 111.994 Z" style={{ stroke: "rgb(0, 0, 0)", paintOrder: "fill", fill: "url(#gradient-1)", fillRule: "nonzero" }} transform="matrix(0.92388, 0.382683, -0.382683, 0.92388, -29.48986, -96.75057)" />
                </g>

                <g name="cap-outer" transform={"rotate( " + this.props.molenOrientation + ", 144, 144)"}>
                    {this.props.windingRAGStatus === "FAULT" && <animateTransform attributeName="transform" type="rotate" dur="4s" from="0 144 144" to="360 144 144" repeatCount="indefinite" />}
                    <g transform="matrix(-1.038471, -0.00009, 0.00009, -1.038471, 285.164459, 294.389618)" name="cap">
                        <rect x="122.71" y="88.158" width="2.691" height="29.596" style={{ stroke: "rgb(0,0,0)", fill: "rgb(255,255,255)" }} />
                        <rect x="144.683" y="88.158" width="2.691" height="29.596" style={{ stroke: "rgb(0,0,0)", fill: "rgb(255,255,255)" }} />
                        <line style={{ stroke: "rgb(255,255,255)", fill: "rgb(216,216,216)" }} x1="124.055" y1="92.642" x2="145.804" y2="92.642" />
                        <line style={{ stroke: "rgb(255,255,255)", fill: "rgb(216,216,216)" }} x1="124.055" y1="96.902" x2="146.028" y2="96.678" />
                        <line style={{ stroke: "rgb(255,255,255)", fill: "rgb(216,216,216)" }} x1="124.055" y1="101.162" x2="145.804" y2="100.938" />
                        <line style={{ stroke: "rgb(255,255,255)", fill: "rgb(255,255,255)" }} x1="123.703" y1="104.774" x2="145.452" y2="104.774" />
                        <line style={{ stroke: "rgb(255,255,255)", fill: "rgb(216,216,216)" }} x1="123.705" y1="108.679" x2="145.678" y2="108.455" />
                        <g transform="matrix(0.762688, 0, 0, 0.559969, 12.821077, 43.154831)">
                            <path d="M -146.02 -399.447 L -146.02 -256.509 L -152.747 -256.509 L -146.02 -399.447 Z" style={{ stroke: "rgb(255,255,255)", fill: "rgb(255,255,255)" }} transform="matrix(0, 1, -1, 0, -79.138802, 389.075684)" />
                            <path d="M 155.131 224.492 L 161.858 367.43 L 155.131 367.43 L 155.131 224.492 Z" style={{ stroke: "rgb(255,255,255)", fill: "rgb(255,255,255)" }} transform="matrix(0, -1, 1, 0, -222.530533, 397.906616)" />
                            <rect x="143.965" y="236.945" width="33.312" height="8.008" style={{ stroke: "rgb(255,255,255)", fill: "rgb(255,255,255)" }} />
                            <rect x="1.762" y="242.695" width="319.347" height="4.164" style={{ stroke: "rgb(255,255,255)", fill: "rgb(255,255,255)" }} />
                        </g>
                        <g transform="matrix(0.267841, 0, 0, 0.445856, 72.848381, 42.251469)">
                            <path style={{ stroke: "rgb(208,208,208)", fill: "rgb(255,255,255)" }} d="M 233.024 103.789 C 233.024 103.789 243.29796920206164 126.17251178069606 244.234 131.976 C 244.6309138818194 134.43690731447145 245.1968301440453 136.21134771366937 243.914 137.421 C 241.350550297164 139.83822008819553 224.91257017081136 139.92228984369746 222.134 137.421 C 220.66115789901002 136.09513642193517 221.11965299246117 134.02759530854914 221.493 131.335 C 222.31024869064282 125.4409659086348 230.7101310613115 105.92595787320916 232.383 104.109 C 232.6444841669574 103.82499346195917 233.024 103.789 233.024 103.789 C 233.024 103.789 233.02399999999997 103.789 233.024 103.789" />
                            <path style={{ stroke: "rgb(208,208,208)", fill: "rgb(255,255,255)" }} d="M 232.494 71.637 C 232.494 71.637 242.7679692020616 94.02051178069607 243.704 99.824 C 244.10091388181942 102.28490731447145 244.6668301440453 104.05934771366941 243.384 105.269 C 240.82055029716406 107.68622008819553 224.38257017081133 107.77028984369743 221.604 105.269 C 220.13115789901008 103.94313642193518 220.58965299246117 101.87559530854915 220.963 99.183 C 221.78024869064285 93.28896590863481 230.18013106131144 73.77395787320917 231.853 71.957 C 232.11448416695745 71.67299346195917 232.494 71.637 232.494 71.637 C 232.49399999999997 71.637 232.49399999999997 71.63699999999999 232.494 71.637" transform="matrix(-1, 0, 0, -1, 464.766647, 178.750572)" />
                        </g>
                        <line style={{ stroke: "rgb(255,255,255)", fill: "rgb(0,0,0)" }} x1="124.439" y1="89.093" x2="145.259" y2="89.414" />
                        <ellipse style={{ stroke: "rgb(0,0,0)", fill: "url(#gradient-0)" }} transform="matrix(-0.999979, -0.006544, 0.006544, -0.999979, 454.358085, 421.894719)" cx="320.667" cy="275.667" rx="29.719" ry="29.719" />
                        <rect x="154.851" y="117.898" width="26.591" height="1.173" style={{ stroke: "rgb(0,0,0)" }} transform="matrix(-0.999979, -0.006544, 0.006544, -0.999979, 302.468231, 294.721802)" />
                        <line style={{ stroke: "rgb(255,255,255)", fill: "rgb(216,216,216)" }} x1="124.097" y1="112.424" x2="146.07" y2="112.2" />
                    </g>
                </g>
            </>


        )
    }
}

export default CustomOrientationDiagramFulwell