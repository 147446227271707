import React from 'react';

class CustomOrientationDiagramJill extends React.Component {

    componentDidMount() {
    }

    render() {

        return (
            <>
                {/* --- mill base layer - JILL --- */}
                <g name="postMillBaseLayer">
                    <circle cx="144" cy="144" r="44" fill={this.props.graphicsSettings.displayGraphicColour1} />
                    <circle cx="144" cy="144" r="78" stroke="#0f4c81" strokeWidth="2" fill="none" />
                </g>


                {/* --- buck - JILL --- */}
                <g name="buckLayerPost" transform={"rotate( " + this.props.molenOrientation + ", 144, 144)"} fill={this.props.graphicsSettings.displayGraphicColour2} strokeWidth="0" stroke="#000000">
                    {this.props.windingRAGStatus === "FAULT" && <animateTransform attributeName="transform" type="rotate" dur="4s" from="0 144 144" to="360 144 144" repeatCount="indefinite" />}
                    <path id="windshaft" d="M 140 108 L 148 108 L 147 240 L 141 240 L 140 108" />
                    <path id="sails-outline" d="M 54 102 L 234 102 L 234 104 L 156 109.5 L 136 109.5 L 54 104 Z" />
                    <g name="steps">
                        <path id="steps-leftbeam" d="M 127 179 L 130 179 L 130 226 L 127 226 Z" />
                        <path id="steps-leftbeam" d="M 158 179 L 161 179 L 161 226 L 158 226 Z" />
                        <path id="steps-cross1" d="M 128 180 L 160 180 L 160 183 L 128 183 Z" />
                        <path id="steps-cross2" d="M 128 184 L 160 184 L 160 187 L 128 187 Z" />
                        <path id="steps-cross3" d="M 128 188 L 160 188 L 160 191 L 128 191 Z" />
                        <path id="steps-cross4" d="M 128 192 L 160 192 L 160 195 L 128 195 Z" />
                        <path id="steps-cross5" d="M 128 196 L 160 196 L 160 199 L 128 199 Z" />
                        <path id="steps-cross6" d="M 128 200 L 160 200 L 160 203 L 128 203 Z" />
                        <path id="steps-cross7" d="M 128 204 L 160 204 L 160 207 L 128 207 Z" />
                        <path id="steps-cross8" d="M 128 208 L 160 208 L 160 211 L 128 211 Z" />
                        <path id="steps-cross9" d="M 128 212 L 160 212 L 160 215 L 128 215 Z" />
                        <path id="steps-cross10" d="M 128 216 L 160 216 L 160 219 L 128 219 Z" />
                    </g>
                    <g name="fan">
                        <path id="fan-cross" d="M 130 243.5 L 158 243.5 L 158 246.5 L 130 246.5 Z" />
                        <path id="fan-brace-left" d="M 142 227 L 144.5 227 L 136 245 L 133.5 245 Z" />
                        <path id="fan-brace-right" d="M 143.3 227 L 146 227 L 155.5 245 L 153 245 Z" />
                        <path id="fan-blade-front" d="M 142 244 L 138 229 C 142 226, 146 226, 150 229 L 146 244 Z" />
                        <path id="fan-blade-right" d="M 142 244 L 138 259 C 142 263, 146 263, 150 259 L 146 244 Z" />
                    </g>
                    <polygon points="121,111 167,111 167,179 121,179" />
                </g>
            </>
        )
    }
}



export default CustomOrientationDiagramJill