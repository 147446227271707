import React from 'react';

import Sparkline, {
} from 'devextreme-react/sparkline';
import { Tooltip } from 'devextreme-react/chart';

export default function SensorGatewayStatsTableRowTemplate(props) {
  return (
    <tr>
      <td><b>{props.gateway.name}</b><br />{props.gateway.distanceKm != null ? props.gateway.distanceKm + "km" : ""}</td>
      <td>{props.gateway.uplinkMessageCount} ({Math.round(10000* props.gateway.uplinkMessageCount / props.totalUplinkMessages) /100}%)</td>
      <td>
      <Sparkline
          dataSource={props.gateway.rssiDistribution}
          className="sparkline"
          argumentField="rssi"
          valueField="readingCount"
          type="area"
        >
            <Tooltip enabled={false}></Tooltip>
        </Sparkline>
        
      </td>
      <td align="right">{Math.round(props.gateway.avgRssi * 10) / 10}</td>
      <td>
        <Sparkline
          dataSource={props.gateway.snrDistribution}
          className="sparkline"
          argumentField="snr"
          valueField="readingCount"
          type="area"
        >
            <Tooltip enabled={false}></Tooltip>
        </Sparkline>
        </td>
        <td align="right">
        {Math.round(props.gateway.avgSnr * 10) / 10}
      </td>

    </tr>
  );
}

