import React from 'react';
import './WindRosePanel.css';
import {
    PolarChart,
    CommonSeriesSettings,
    Series,
    ArgumentAxis,
    ValueAxis,
    Label,
    Export,
    Animation
} from 'devextreme-react/polar-chart';
import moment from 'moment';

// OLD COLOURS - DO NOT EDIT ---------- XXXXXXXXXXXXXXXXXXXXXXXXXXXX
/*
const windSpeedsOldColours = [
    { value: 'noWindPercent', name: 'No Wind', color: "#FFFFFF"},
    { value: 'beauf0Percent', name: '0 - Calm', color: "#E0E0E0" },
    { value: 'beauf1Percent', name: '1 - Light Air', color: "#AEF1F9" },
    { value: 'beauf2Percent', name: '2 - Light Breeze', color: "#96F7DC" },
    { value: 'beauf3Percent', name: '3 - Gentle Breeze', color: "#96F7B4" },
    { value: 'beauf4Percent', name: '4 - Moderate Breeze', color: "#6FF46F" },
    { value: 'beauf5Percent', name: '5 - Fresh Breeze', color: "#73ED12" },
    { value: 'beauf6Percent', name: '6 - Strong Breeze', color: "#A4ED12" },
    { value: 'beauf7Percent', name: '7 - High Wind', color: "#DAED12" },
    { value: 'beauf8Percent', name: '8 - Gale', color: "#EDC212" },
    { value: 'beauf9Percent', name: '9 - Severe Gale', color: "#ED8F12" },
    { value: 'beauf10Percent', name: '10 - Storm', color: "#ED6312" },
    { value: 'beauf11Percent', name: '11 - Violent Storm', color: "#ED2912" },
    { value: 'beauf12PlusPercent', name: '12 - Hurricane', color: "#D5102D" }
];
*/

// NEW COLOURS
const windSpeeds = [
    { value: 'noWindPercent', name: 'No Wind', color: "#E0E0E0"},
    { value: 'beauf0Percent', name: '0 - Calm', color: "#3b6bb5" },
    { value: 'beauf1Percent', name: '1 - Light Air', color: "#0c9bd7" },
    { value: 'beauf2Percent', name: '2 - Light Breeze', color: "#35c0c5" },
    { value: 'beauf3Percent', name: '3 - Gentle Breeze', color: "#0aaa4a" },
    { value: 'beauf4Percent', name: '4 - Moderate Breeze', color: "#52b848" },
    { value: 'beauf5Percent', name: '5 - Fresh Breeze', color: "#92c83e" },
    { value: 'beauf6Percent', name: '6 - Strong Breeze', color: "#c6da2b" },
    { value: 'beauf7Percent', name: '7 - Near Gale', color: "#fcee13" },
    { value: 'beauf8Percent', name: '8 - Gale', color: "#fbb515" },
    { value: 'beauf9Percent', name: '9 - Severe Gale', color: "#f46d3d" },
    { value: 'beauf10Percent', name: '10 - Storm', color: "#e92624" },
    { value: 'beauf11Percent', name: '11 - Violent Storm', color: "#ac46cb" },
    { value: 'beauf12PlusPercent', name: '12 - Hurricane', color: "#000000" }
];

class WindRosePanel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            windDistribution: {
                distributionByCompassPoint: []
            }
        }
    }



    componentDidMount() {
        this.updateAnalysis();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.analysisId !== this.props.analysisId) {
            this.updateAnalysis();
        }
    }

    updateAnalysis() {
        if (this.props.analysisId != null) {
            fetch(`https://` + process.env.REACT_APP_SMARTMOLEN_API + `/api/analysis/windDistribution/` + this.props.analysisId)
                .then(response => response.json())
                .then(response => {
                    this.setState({ windDistribution: response })
                });
        }
    }

    render() {
        if (this.props == null || this.props.analysisId == null || this.state.windDistribution == null) {
            return <span>Loading ...</span>
        }
        else {
            return this.renderLoaded();
        }
    }

    renderLoaded() {
        return (
            <div className="panel-wide">
                {!(this.props.minimal) && <>
                <div className="panel-header">
                    WIND ROSE
                </div>
                Last updated {moment(this.state.windDistribution.analysisUtcTime).format("DD/MM/yyyy HH:mm:ss")}
                </>
                }   
                <div className="panel-body-light">
                    <PolarChart
                        id="wind-rose-chart"
                        palette="Soft"
                        dataSource={this.state.windDistribution.distributionByCompassPoint}
                        title={"Observed Wind (Beaufort Scale)<br/>" + moment(this.state.windDistribution.sourceDataStartUtcTime).format("D MMM yyyy") + " - " + moment(this.state.windDistribution.sourceDataEndUtcTime).format("D MMM yyyy")}
                    >

                        <Animation
                            enabled={true}
                        />

                        <CommonSeriesSettings type="stackedbar" argumentField="compassPoint" />
                        {
                            windSpeeds.map(function (item) {
                                return <Series key={item.value} valueField={item.value} name={item.name} color={item.color} barPadding={0} />;
                            })
                        }
                        <ArgumentAxis
                            discreteAxisDivisionMode="crossLabels"
                            firstPointOnStartAngle={true}
                        />
                        <ValueAxis valueMarginsEnabled={false}>
                            <Label visible={true} format={"#'%'"} ></Label>
                        </ValueAxis>
                        <Export enabled={!(this.props.minimal)} />

                    </PolarChart>
                </div>
            </div>

        )
    }
}

export default WindRosePanel
