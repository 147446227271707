import React from 'react'
import moment from 'moment';
import {
    DataGrid,
    Column,
    MasterDetail
} from 'devextreme-react/data-grid';
import SailRotationData from './SailRotationData';
import SailRotationDetailsTemplate from './SailRotationDetailsTemplate';


class SailRotationDetails extends React.Component {

    readings = [];
    orientationSensor = null;

    componentDidMount() {
        this.loadLatestReadings();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.molen != null &&
            prevProps.molen.latestSailRotationReading != null &&
            this.props.molen.latestSailRotationReading != null &&
            prevProps.molen.latestSailRotationReading.utcTime !== this.props.molen.latestSailRotationReading.utcTime) {
            this.loadLatestReadings();
        }
    }

    loadLatestReadings() {
        if (this.props.molen != null) {
            fetch(`https://` + process.env.REACT_APP_SMARTMOLEN_API + `/api/latestSailBrakeFanReadings/` + this.props.molen.molenId + `/100`)
                .then(response => response.json())
                .then(response => {
                    this.readings = response;
                    SailRotationData.setSailRotationData(this.readings);
                    this.setState({ readings: this.readings })
                });
        }
    }

    calculateGatewayCount(rowData) {
        if (rowData == null || rowData.loRaWANMessageInfo == null || rowData.loRaWANMessageInfo.gateways == null) { return 0; }
        return rowData.loRaWANMessageInfo.gateways.length;
    }

    calculateReadingTimeDisplay(rowData) {
        return moment(rowData.utcTime).format("DD/MM/yyyy HH:mm:ss");
    }

    calculateRevCountToday(rowData) {
        return Math.round(rowData.revCountToday * 100) / 100;
    }

    calculateRevCountThisMonth(rowData) {
        return Math.round(rowData.revCountThisMonth * 100) / 100;
    }

    calculateRevCountThisYear(rowData) {
        return Math.round(rowData.revCountThisYear * 100) / 100;
    }

    calculateTotalRevCount(rowData) {
        return Math.round(rowData.totalRevCount);
    }

    render() {
        if (this.readings.length === 0) {
            return <span>Loading ...</span>
        }
        else {
            return this.renderLoaded();
        }
    }

    renderLoaded() {
        return (
            <>
                <div className="panel-container">
                    <div className="panel-wide">
                        <div className="panel-header">
                            <div className="panel-header-title">
                                LATEST READINGS
                            </div>
                        </div>
                        <div className="panel-body">
                            <DataGrid id="grid-container"
                                dataSource={this.state.readings}
                                keyExpr="id"
                                showBorders={true}
                                rowAlternationEnabled={true}
                            >
                                <Column calculateCellValue={this.calculateReadingTimeDisplay} width={125} caption="Time" dataType="date" />
                                <Column dataField="rawSensorCount" width={60} caption="RawSensorCount" />
                                <Column dataField="sailSensorPasses" width={60} caption="SailPasses" />
                                <Column dataField="sailSpeedPpm" width={60} caption="SailPpm" />
                                <Column dataField="sailSpeedRpm" width={60} caption="SailRpm" />
                                <Column calculateCellValue={this.calculateRevCountToday} width={70} caption="RevCountToday" />
                                <Column calculateCellValue={this.calculateRevCountThisMonth} width={70} caption="RevCountThisMonth" />
                                <Column calculateCellValue={this.calculateRevCountThisYear} width={70} caption="RevCountThisMonth" />
                                <Column calculateCellValue={this.calculateTotalRevCount} width={70} caption="TotalRevCount" />

                                <Column calculateCellValue={this.calculateGatewayCount} width={70} caption="Gateways" />

                                <MasterDetail enabled={true} component={SailRotationDetailsTemplate} />

                            </DataGrid>
                        </div>
                    </div>

                </div>
            </>


        )
    }
}

export default SailRotationDetails
