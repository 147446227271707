import React from 'react';
import './WhirlOffHeader.css';
import SailRotationDiagramDutch from '../../components/sailRotationDiagram/sailRotationDiagramDutch';

class WhirloffHeader extends React.Component {

    componentDidMount() {
    }

    render() {

        var totalSpeed = this.props.whirloff.reduce((n, { currentSpeedRpm }) => n + currentSpeedRpm, 0);

        return (
            <>
                <div className="whirloff-header-container">
                    <div className="whirloff-header-logo">
                        <SailRotationDiagramDutch currentSpeedRpm={totalSpeed} />
                    </div>
                    <div className="whirloff-header-title">
                        Windmill Whirl-Off<br />
                        <div className="whirl-off-header-year-line">
                            <div className="whirloff-header-year">{this.props.year}</div>
                            <div className="whirloff-header-beta">BETA</div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default WhirloffHeader