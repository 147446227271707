import React from 'react';

class CustomOrientationDiagramHolgate extends React.Component {

    componentDidMount() {
    }

    render() {

        return (
            <>
                <defs>
                    <radialGradient gradientUnits="userSpaceOnUse" cx="320.667" cy="275.667" r="33.38" id="gradient-0" gradientTransform="matrix(-0.088426, -1.846572, 2.19587, -0.105155, -256.306491, 896.789406)">
                        <stop offset="0" style={{ stopColor: "rgba(255, 255, 255, 1)" }} />
                        <stop offset="1" style={{ stopColor: "rgba(204, 204, 204, 1)" }} />
                    </radialGradient>
                    <radialGradient gradientUnits="userSpaceOnUse" cx="274.5" cy="355.5" r="3.001" id="gradient-1" gradientTransform="matrix(1, 0, 0, 1, 0, 0)">
                        <stop offset="0" style={{ stopColor: "rgba(0, 0, 0, 1)" }} />
                        <stop offset="1" style={{ stopColor: "rgba(0, 0, 0, 1)" }} />
                    </radialGradient>
                </defs>

                <g name="base" transform="matrix(1, 0, 0, 1, 3.199883, -2.000301)">
                    <path d="M 244.272 111.994 Q 250.224 111.242 256.176 111.994 L 256.176 111.994 Q 262.127 112.746 267.705 114.954 L 267.705 114.954 Q 273.283 117.162 278.136 120.689 L 278.136 120.689 Q 282.989 124.215 286.813 128.837 L 286.813 128.837 Q 290.637 133.459 293.191 138.887 L 293.191 138.887 Q 295.745 144.315 296.869 150.208 L 296.869 150.208 Q 297.994 156.101 297.617 162.088 L 297.617 162.088 Q 297.24 168.075 295.386 173.78 L 295.386 173.78 Q 293.533 179.486 290.318 184.551 L 290.318 184.551 Q 287.104 189.616 282.731 193.722 L 282.731 193.722 Q 278.358 197.829 273.101 200.719 L 273.101 200.719 Q 267.844 203.609 262.033 205.101 L 262.033 205.101 Q 256.223 206.593 250.224 206.593 L 250.224 206.593 Q 244.225 206.593 238.415 205.101 L 238.415 205.101 Q 232.604 203.609 227.347 200.719 L 227.347 200.719 Q 222.09 197.829 217.717 193.722 L 217.717 193.722 Q 213.344 189.616 210.13 184.551 L 210.13 184.551 Q 206.915 179.486 205.062 173.78 L 205.062 173.78 Q 203.208 168.075 202.831 162.088 L 202.831 162.088 Q 202.454 156.101 203.579 150.208 L 203.579 150.208 Q 204.703 144.315 207.257 138.887 L 207.257 138.887 Q 209.811 133.459 213.635 128.837 L 213.635 128.837 Q 217.459 124.215 222.312 120.689 L 222.312 120.689 Q 227.165 117.162 232.743 114.954 L 232.743 114.954 Q 238.321 112.746 244.272 111.994 Z"
                        style={{ stroke: "rgb(0, 0, 0)", paintOrder: "fill", fillRule: "nonzero", fill: "rgb(30, 30, 30)" }}
                        transform="matrix(0.92388, 0.382683, -0.382683, 0.92388, -29.48986, -96.75057)" />
                </g>


                <g name="cap-outer" transform={"rotate( " + this.props.molenOrientation + ", 144, 144)"}>
                    {this.props.windingRAGStatus === "FAULT" && <animateTransform attributeName="transform" type="rotate" dur="4s" from="0 144 144" to="360 144 144" repeatCount="indefinite" />}

                    <g transform="matrix(-1.038471, -0.00009, 0.00009, -1.038471, 285.164459, 294.389618)" name="cap">
                        <g transform="matrix(0.464314, 0, 0, 0.529921, 27.949709, 33.38665)">
                            <rect x="269.231" y="147.999" width="8.06" height="50.136" style={{stroke: "rgb(0, 0, 0)", fill: "rgb(255, 255, 255)"}} transform="matrix(0, 1, -1, 0, 406.380432, -132.742599)" />
                            <line style={{stroke: "rgb(0, 0, 0)", fill: "rgb(216, 216, 216)"}} x1="211.937" y1="105.122" x2="256.777" y2="105.727" />
                            <rect x="204.813" y="103.357" width="9.196" height="55.85" style={{stroke: "rgb(0, 0, 0)", fill: "rgb(255, 255, 255)"}} />
                            <rect x="251.165" y="103.68" width="9.196" height="55.85" style={{stroke: "rgb(0, 0, 0)", fill: "rgb(255, 255, 255)"}} />
                            <path style={{stroke: "rgb(208, 208, 208)", fill: "rgb(255, 255, 255)"}} d="M 233.024 103.789 C 233.024 103.789 243.29796920206164 126.17251178069606 244.234 131.976 C 244.6309138818194 134.43690731447145 245.1968301440453 136.21134771366937 243.914 137.421 C 241.350550297164 139.83822008819553 224.91257017081136 139.92228984369746 222.134 137.421 C 220.66115789901002 136.09513642193517 221.11965299246117 134.02759530854914 221.493 131.335 C 222.31024869064282 125.4409659086348 230.7101310613115 105.92595787320916 232.383 104.109 C 232.6444841669574 103.82499346195917 233.024 103.789 233.024 103.789 C 233.024 103.789 233.02399999999997 103.789 233.024 103.789" />
                            <path style={{stroke: "rgb(208, 208, 208)", fill: "rgb(255, 255, 255)"}} d="M 232.494 71.637 C 232.494 71.637 242.7679692020616 94.02051178069607 243.704 99.824 C 244.10091388181942 102.28490731447145 244.6668301440453 104.05934771366941 243.384 105.269 C 240.82055029716406 107.68622008819553 224.38257017081133 107.77028984369743 221.604 105.269 C 220.13115789901008 103.94313642193518 220.58965299246117 101.87559530854915 220.963 99.183 C 221.78024869064285 93.28896590863481 230.18013106131144 73.77395787320917 231.853 71.957 C 232.11448416695745 71.67299346195917 232.494 71.637 232.494 71.637 C 232.49399999999997 71.637 232.49399999999997 71.63699999999999 232.494 71.637" transform="matrix(-1, 0, 0, -1, 464.766647, 178.750572)" />
                        </g>
                        <g transform="matrix(0.762688, 0, 0, 0.559969, 12.825768, 46.629364)">
                            <path d="M -146.02 -399.447 L -146.02 -256.509 L -152.747 -256.509 L -146.02 -399.447 Z"
                            style={{stroke: "rgb(255, 255, 255)", fill: "rgb(255, 255, 255)"}} transform="matrix(0, 1, -1, 0, -79.138802, 389.075684)" />
                            <path d="M 155.131 224.492 L 161.858 367.43 L 155.131 367.43 L 155.131 224.492 Z"
                            style={{stroke: "rgb(255, 255, 255)", fill: "rgb(255, 255, 255)"}} transform="matrix(0, -1, 1, 0, -222.530533, 397.906616)" />
                            <rect x="143.965" y="236.945" width="33.312" height="8.008" style={{stroke: "rgb(255, 255, 255)", fill: "rgb(255, 255, 255)"}} />
                            <rect x="1.762" y="242.695" width="319.347" height="4.164" style={{stroke: "rgb(255, 255, 255)", fill: "rgb(255, 255, 255)"}} />
                        </g>
                        <ellipse style={{stroke: "rgb(255, 255, 255)", fill: "url(#gradient-0)"}} transform="matrix(-0.999979, -0.006544, 0.006544, -0.999979, 454.803642, 422.566075)" cx="320.667" cy="275.667" rx="33.38" ry="33.38" />
                        <ellipse style={{stroke: "rgb(255, 255, 255)", fill: "url(#gradient-1)"}} transform="matrix(-1, 0.000087, -0.000087, -1, 410.478393, 500.282652)" cx="274.5" cy="355.5" rx="3.001" ry="3.001" />
                        <rect x="154.851" y="117.898" width="26.591" height="1.173" style={{stroke: "rgb(0, 0, 0)"}} transform="matrix(-0.999979, -0.006544, 0.006544, -0.999979, 303.317993, 298.196259)" />
                    </g>

                </g>
            </>
        )
    }
}

export default CustomOrientationDiagramHolgate