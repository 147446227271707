var orientationReadings = [];

function getOrientationReadings() {
    return orientationReadings;
}

function setOrientationReadings(readings) {
    orientationReadings = readings;
} 

const sensorData = {
    getOrientationReadings,
    setOrientationReadings,
};

export default sensorData;