import React from 'react'
import ReadingDetailsTemplate from './ReadingDetailsTemplate'
import moment from 'moment';
import {
    DataGrid,
    Column,
    MasterDetail
} from 'devextreme-react/data-grid';
import SensorData from './SensorData';
import OrientationSensorCalibrationDetails from '../orientationSensorCalibrationDetails/orientationSensorCalibrationDetails';
import LoRaWANAirtimePanel from '../../panels/LoRaWANAirtimePanel/LoRaWANAirtimePanel';
import SensorGatewayStatsPanel from '../../panels/SensorGatewayStatsPanel/SensorGatewayStatsPanel';


class OrientationSensorDetails extends React.Component {

    calibrationDisplayed = "LATEST";
    readings = [];
    orientationSensor = null;

    constructor() {
        super();
        this.onChangeValue = this.onChangeValue.bind(this);
    }

    componentDidMount() {
        this.selectOrientationSensor();
        if (this.orientationSensor != null) {
            this.loadLatestReadings();
            this.setState({ calibrationSummaryDisplayed: this.orientationSensor.latestCalibrationCalculation });
        }

    }

    selectOrientationSensor() {
        console.log("Selecting sensor " + this.props.sensorFriendlyId);
        if (this.props.molen != null && this.props.molen.orientationSensors != null) {
            this.orientationSensor = this.props.molen.orientationSensors.find(e => e.deviceFriendlyId === this.props.sensorFriendlyId);
            this.setState({
                orientationSensor: this.orientationSensor
            });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.sensorFriendlyId !== this.props.sensorFriendlyId) {
            this.selectOrientationSensor();
            this.loadLatestReadings();
            return;
        }
        if (prevProps.molen != null && prevProps.molen.latestOrientationSensorReading.utcTime !== this.props.molen.latestOrientationSensorReading.utcTime) {
            this.loadLatestReadings();
        }


        if (prevProps.molen != null) {
            if (this.calibrationDisplayed === "LATEST") {
                if (this.orientationSensor.latestCalibrationCalculation != null) {
                    if (prevState!= null && prevState.calibrationSummaryDisplayed != null && (prevState.calibrationSummaryDisplayed.calibrationId !== this.orientationSensor.latestCalibrationCalculation.calibrationId)) {
                    this.setState({ calibrationSummaryDisplayed: this.orientationSensor.latestCalibrationCalculation });
                    }
                }
            }
            else {
                if (this.orientationSensor.activeCalibration != null) {
                    if (prevState != null && prevState.calibrationSummaryDisplayed != null && (prevState.calibrationSummaryDisplayed.calibrationId !== this.orientationSensor.activeCalibration.calibrationId)) {
                    this.setState({ calibrationSummaryDisplayed: this.orientationSensor.activeCalibration });
                    }
                }
            }
        }


    }

    loadLatestReadings() {
        console.log("Load Latest Readings: Orientation Sensor State: " + this.orientationSensor.deviceId);

        if (this.props.molen != null && this.orientationSensor != null) {
            fetch(`https://` + process.env.REACT_APP_SMARTMOLEN_API + `/api/latestOrientationReadings/` + this.orientationSensor.deviceId + `/100`)
                .then(response => response.json())
                .then(response => {
                    this.readings = response;
                    SensorData.setOrientationReadings(this.readings);
                    this.setState({ readings: this.readings })
                });
        }
    }

    calculateGatewayCount(rowData) {
        if (rowData == null || rowData.loRaWANMessageInfo == null || rowData.loRaWANMessageInfo.gateways == null) { return 0; }
        return rowData.loRaWANMessageInfo.gateways.length;
    }

    calculateReadingTimeDisplay(rowData) {
        return moment(rowData.orientationReading.utcTime).format("DD/MM/yyyy HH:mm:ss");
    }

    render() {
        if (this.readings.length === 0) {
            return <span>Loading ...</span>
        }
        else {
            return this.renderLoaded();
        }
    }

    onChangeValue(event) {
        this.calibrationDisplayed = event.target.value;
        if (event.target.value === "ACTIVE") {
            this.setState({ calibrationSummaryDisplayed: this.state.orientationSensor.activeCalibration });
        }
        else {
            this.setState({ calibrationSummaryDisplayed: this.state.orientationSensor.latestCalibrationCalculation });
        }

    }

    renderLoaded() {
        return (
            <>
                <div className="panel-container">
                    <div className="panel-wide">
                        <div className="panel-header">
                            <div className="panel-header-title">
                                LATEST READINGS
                            </div>
                        </div>
                        <div className="panel-body">
                            <DataGrid id="grid-container"
                                dataSource={this.state.readings}
                                keyExpr="id"
                                showBorders={true}
                                rowAlternationEnabled={true}
                            >
                                <Column calculateCellValue={this.calculateReadingTimeDisplay} width={125} caption="Time" dataType="date" />
                                <Column dataField="magnetometerReading.magnetometerX" width={60} caption="RawMagX" />
                                <Column dataField="magnetometerReading.magnetometerY" width={60} caption="RawMagY" />
                                <Column dataField="magnetometerReading.scaledMagnetometerX" width={60} caption="TransMagX" />
                                <Column dataField="magnetometerReading.scaledMagnetometerY" width={60} caption="TransMagY" />
                                <Column dataField="orientationSensorSettings.bearingOffset" width={55} caption="Offset" />
                                <Column dataField="magnetometerReading.calculatedBearing" width={65} caption="Bearing" />
                                <Column dataField="orientationReading.readingDerivationMethod" width={65} caption="Method" />
                                <Column dataField="orientationReading.readingConfidenceLevel" width={65} caption="Confidence Score" />
                                <Column dataField="orientationReading.bearing" width={65} caption="Bearing Reported" />
                                <Column calculateCellValue={this.calculateGatewayCount} width={65} caption="Gateways" />

                                <MasterDetail enabled={true} component={ReadingDetailsTemplate} />

                            </DataGrid>
                        </div>
                    </div>

                    <div className="panel-wide">
                        <div className="panel-header">
                            <div className="panel-header-title">
                                CALIBRATION
                            </div>

                            <div className="panel-top-right">
                                <div onChange={this.onChangeValue}>
                                    <div className="app-smalltext">
                                        View:&nbsp;
                                        <input type="radio" value="LATEST" name="calibrationSelection" defaultChecked /> Latest calculated &nbsp;
                                        <input type="radio" value="ACTIVE" name="calibrationSelection" /> Currently active
                                    </div>
                                </div>
                            </div>

                        </div>
                        <OrientationSensorCalibrationDetails calibrationSummary={this.state.calibrationSummaryDisplayed} />
                    </div>

                    <SensorGatewayStatsPanel analysisId={this.orientationSensor.latestLoRaWANPerformanceStats} />

                    <LoRaWANAirtimePanel analysisId={this.orientationSensor.latestLoRaWANPerformanceStats} />

                </div>
            </>


        )
    }
}

export default OrientationSensorDetails
