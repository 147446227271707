import React from 'react';
import { DataGrid, Column } from 'devextreme-react/data-grid';
import FieldTestData from './FieldTestData';



class LorawanGatewayTableTemplate extends React.Component {



    constructor(props) {
        super(props);
        this.dataSource = getGateways(props.data.key);
    }


    render() {

        return (
            <React.Fragment>
                <div className="master-detail-caption">
                    <b>Receiving TTN Gateways</b>
                </div>
                <DataGrid
                    dataSource={this.dataSource}
                    showBorders={true}
                    columnAutoWidth={true}
                    wordWrapEnabled={true}
                >
                    <Column dataField="description" caption="Name" />
                    <Column dataField="distanceKm"  width={60} caption="Dist (km)" />
                    <Column dataField="rssi" width={50} caption="RSSI" />
                    <Column dataField="snr" width={50} caption="SNR" />
                </DataGrid>
            </React.Fragment>
        )
    }
}

function getGateways(key) {
    var readings = FieldTestData.getFieldTestReadings();
    return readings.find(r => r.id === key).loRaWANMessageInfo.gateways;
  }

export default LorawanGatewayTableTemplate