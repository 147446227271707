import React from 'react'
import moment from 'moment';
import {
    DataGrid,
    Column,
    MasterDetail
} from 'devextreme-react/data-grid';
import SailBrakeFanData from './SailBrakeFanData';
import SailBrakeFanReadingsDetailsTemplate from './SailBrakeFanReadingDetailsTemplate';


class SailBrakeFanSensorDetails extends React.Component {

    readings = [];
    orientationSensor = null;

    componentDidMount() {
        this.loadLatestReadings();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.molen != null &&
            prevProps.molen.latestSailRotationReading != null &&
            this.props.molen.latestSailRotationReading != null &&
            prevProps.molen.latestSailRotationReading.utcTime !== this.props.molen.latestSailRotationReading.utcTime) {
            this.loadLatestReadings();
        }
    }

    loadLatestReadings() {
        if (this.props.molen != null) {
            fetch(`https://` + process.env.REACT_APP_SMARTMOLEN_API + `/api/latestSailBrakeFanReadings/` + this.props.molen.molenId + `/100`)
                .then(response => response.json())
                .then(response => {
                    this.readings = response;
                    SailBrakeFanData.setSailBrakeFanReadings(this.readings);
                    this.setState({ readings: this.readings })
                });
        }
    }

    calculateGatewayCount(rowData) {
        if (rowData == null || rowData.loRaWANMessageInfo == null || rowData.loRaWANMessageInfo.gateways == null) { return 0; }
        return rowData.loRaWANMessageInfo.gateways.length;
    }

    calculateReadingTimeDisplay(rowData) {
        return moment(rowData.utcTime).format("DD/MM/yyyy HH:mm:ss");
    }

    render() {
        if (this.readings.length === 0) {
            return <span>Loading ...</span>
        }
        else {
            return this.renderLoaded();
        }
    }

    renderLoaded() {
        return (
            <>
                <div className="panel-container">
                    <div className="panel-wide">
                        <div className="panel-header">
                            <div className="panel-header-title">
                                LATEST READINGS
                            </div>
                        </div>
                        <div className="panel-body">
                            <DataGrid id="grid-container"
                                dataSource={this.state.readings}
                                keyExpr="id"
                                showBorders={true}
                                rowAlternationEnabled={true}
                            >
                                <Column calculateCellValue={this.calculateReadingTimeDisplay} width={125} caption="Time" dataType="date" />
                                <Column dataField="brakeOn" width={55} caption="BrakeOn" />
                                <Column dataField="sailSensorPasses" width={55} caption="SailPasses" />
                                <Column dataField="sailSpeedPpm" width={55} caption="SailPpm" />
                                <Column dataField="fantailPassesClockwise" width={55} caption="FanClock" />
                                <Column dataField="fantailPassesAnticlockwise" width={55} caption="FanAnti" />
                                <Column dataField="fantailSpeedPpm" width={55} caption="FanSpeedPpm" />
                                <Column dataField="fantailOscillationsPerMin" width={55} caption="FanOsc" />
                                <Column dataField="fantailSensorAPasses" width={55} caption="FanAPasses" />
                                <Column dataField="fantailSensorBPasses" width={55} caption="FanBPasses" />
                                <Column dataField="fantailSensorCPasses" width={55} caption="FanCPasses" />
                                <Column calculateCellValue={this.calculateGatewayCount} width={65} caption="Gateways" />

                                <MasterDetail enabled={true} component={SailBrakeFanReadingsDetailsTemplate} />

                            </DataGrid>
                        </div>
                    </div>

                </div>
            </>


        )
    }
}

export default SailBrakeFanSensorDetails
