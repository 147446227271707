import React from 'react';
import { Breakpoint } from 'react-socks';
import './Home2Header.css';
import Home2Navigation from './Home2Navigation';
import Clock from 'react-live-clock';
import {FormattedMessage} from 'react-intl';

class Home2Header extends React.Component {

    componentDidMount() {
    }

    render() {

        return (
            <>
                <div className="home2-header-container">
                    <div className="home2-header-logo-and-name">
                        <img src="/img/smartmolen_master2_256px.png" className="home2-header-icon" alt="smartmolen.com"></img>
                        <div className="home2-header-site-name">
                            <div className="home2-header-title">
                                <FormattedMessage
                                    id="app.header"
                                    defaultMessage="smartmolen.com"
                                />
                            </div>
                            <div className="home2-header-subtitle">
                                <FormattedMessage
                                    id="app.subtitle"
                                    defaultMessage="Digital telemetry for traditional windmills"
                                /></div>
                        </div>
                    </div>

                    <Breakpoint large up>
                        <div className="home2-header-navigation">
                            <Home2Navigation></Home2Navigation>
                        </div>
                    </Breakpoint>

                    <Breakpoint xlarge up>
                        <div className="home2-header-clock">
                            <Clock format={'HH:mm:ss'}
                                ticking={true} />
                            <Clock className="home2-header-date" format={'DD MMMM yyyy'}
                                ticking={true} />
                        </div>
                    </Breakpoint>
                </div>
            </>
        )
    }
}

export default Home2Header