import React from 'react';
import './Home2Footer.css';
import moment from 'moment';

class Home2Footer extends React.Component {

  componentDidMount() {
  }

  render() {

    return (
      <>
        <div className="home2-footer-container">
          <div>
            Operated by the<br /><b>Friends of Upminster Windmill</b><br />
            <div className="home2-footer-copyright">
              &copy; {moment().format('YYYY')} smartmolen.com<br />
            </div>
            <div className="home2-footer-terms">
              <a href="/docs/210921FUWPrivacyNotice.pdf">Privacy Policy</a>&nbsp;&nbsp;|&nbsp;&nbsp;<a href="/docs/210921SmartMolenWebsiteTerms.pdf">Terms of Use</a>
            </div>
          </div>
          <img src="img/LogoUpminsterLightSmall.jpg" className="home2-footer-fuw-logo" alt=""></img>
        </div>
      </>
    )
  }
}

export default Home2Footer