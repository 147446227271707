import React from 'react';
import './LoRaWANAirtimePanel.css';
import moment from 'moment';
import {
    Chart,
    Series,
    Legend,
    ValueAxis,
    VisualRange,
    Label,
    ConstantLine,
    Export,
    ArgumentAxis,
} from 'devextreme-react/chart';

class LoRaWANAirtimePanel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            lorawanStats: {
            }
        }
    }


    componentDidMount() {
        this.updateAnalysis();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.analysisId !== this.props.analysisId) {
            this.updateAnalysis();
        }
    }

    updateAnalysis() {
        if (this.props.analysisId != null) {
            fetch(`https://` + process.env.REACT_APP_SMARTMOLEN_API + `/api/lorawanStats/` + this.props.analysisId)
                .then(response => response.json())
                .then(response => {
                    this.setState({ lorawanStats: response })
                });
        }
    }

    render() {
        if (this.props == null || this.props.analysisId == null || this.state.lorawanStats == null || this.state.lorawanStats.gatewayStats == null) {
            return <span>Loading ...</span>
        }
        else {
            return this.renderLoaded();
        }
    }

    renderLoaded() {
        return (
            <div className="panel-wide">
                <div className="panel-header">
                    LORAWAN AIRTIME (LAST 30 DAYS)
                </div>
                Last updated {moment(this.state.lorawanStats.gatewayStats.timeRangeEnd).format("DD/MM/yyyy HH:mm:ss")}

                <div className="panel-body-light" width="100%">
                    <Chart
                        id="loRaWANAirtimeChart"
                        palette="Soft"
                        title="LoRaWAN Airtime (seconds per day)"
                        dataSource={this.state.lorawanStats.airtimeStats}
                        >
                        <Series
                            argumentField="airtimeDate"
                            valueField="airtimeSecs"
                            type="bar"
                            color="#0f4c81"
                        />
                        <ArgumentAxis argumentType="dateTime">
                            <Label format="dd MMM yyyy" />
                        </ArgumentAxis>
                        <ValueAxis >
                            <VisualRange startValue={0} endValue={100} />
                            <ConstantLine
                                width={2}
                                value={86.4}
                                color="#f00000"
                                dashStyle="dash"
                            >
                                <Label text="0.1% duty cycle" />
                            </ConstantLine>
                            <ConstantLine
                                width={2}
                                value={30}
                                color="#00e000"
                                dashStyle="dash"
                            >
                                <Label text="TTN Fair Use Policy" />
                            </ConstantLine>
                        </ValueAxis>
                        <Legend visible={false} />
                        <Export enabled={true} />
                    </Chart>
                </div>
            </div>



        )
    }

    customizeArgumentAxisLabel(arg) {
        return arg;//moment(arg).format("D MMM yyyy");
    }
}

export default LoRaWANAirtimePanel
