import React from 'react';

class CustomOrientationDiagramPaula extends React.Component {

    componentDidMount() {
    }

    render() {

        return (
            <>

                <defs>
                    <radialGradient gradientUnits="userSpaceOnUse" cx="204.606" cy="365.954" r="2.594" id="gradient-0">
                        <stop offset="0" style={{ stopColor: "rgba(91, 91, 91, 1)" }} />
                        <stop offset="1" style={{ stopColor: "rgba(40, 40, 40, 1)" }} />
                    </radialGradient>
                    <radialGradient gradientUnits="userSpaceOnUse" cx="282.198" cy="366.241" r="2.876" id="gradient-1">
                        <stop offset="0" style={{ stopColor: "rgba(91, 91, 91, 1)" }} />
                        <stop offset="1" style={{ stopColor: "rgba(40, 40, 40, 1)" }} />
                    </radialGradient>
                    <radialGradient gradientUnits="userSpaceOnUse" cx="243.882" cy="379.367" r="45.054" id="gradient-2">
                        <stop offset="0" style={{ stopColor: "rgba(91, 91, 91, 1)" }} />
                        <stop offset="1" style={{ stopColor: "rgba(40, 40, 40, 1)" }} />
                    </radialGradient>
                </defs>

                <g name="base">
                    <path d="M 290.727 244.274 Q 291.855 243.794 292.983 244.274 L 335.832 262.526 Q 336.959 263.006 337.426 264.166 L 355.175 308.229 Q 355.642 309.389 355.175 310.549 L 337.426 354.612 Q 336.959 355.772 335.832 356.252 L 292.983 374.504 Q 291.855 374.984 290.727 374.504 L 247.878 356.252 Q 246.751 355.772 246.284 354.612 L 228.535 310.549 Q 228.068 309.389 228.535 308.229 L 246.284 264.166 Q 246.751 263.006 247.878 262.526 Z" style={{ stroke: "rgb(55, 51, 51)", fill: "rgb(47, 47, 47)" }} transform="matrix(0.919996, 0.391927, -0.391927, 0.919996, -3.247543, -255.022517)">
                    </path>
                </g>

                <g transform="matrix(0.563954, 0.000001, -0.000001, 0.563954, 4.654837, 2.163429)" name="cap-outer">

                    <g name="cap-outer" transform={"rotate( " + this.props.molenOrientation + ", 252, 252)"}>
                        {this.props.windingRAGStatus === "FAULT" && <animateTransform attributeName="transform" type="rotate" dur="4s" from="0 252 252" to="360 252 252" repeatCount="indefinite" />}

                        <g transform="matrix(0.799132, 0, 0, 1, 55.105694, -25.913656)" >
                            <title>Fanstage</title>
                            <rect x="202.012" y="347.289" width="5.188" height="37.33" style={{ fill: "url(#gradient-0)" }}>
                                <title>LH Sheer</title>
                            </rect>
                            <rect x="279.321" y="347.576" width="5.753" height="37.33" style={{ fill: "url(#gradient-1)" }}>
                                <title>RH Sheer</title>
                            </rect>
                            <rect x="198.829" y="376.402" width="90.107" height="5.929" style={{ fill: "url(#gradient-2)" }}>
                                <title>Cross Beam</title>
                            </rect>
                        </g>

                        <path d="M 300.708 296.75 L 189.111 296.75 L 189.111 233.795 L 300.708 233.795 L 300.708 296.75 Z M 190.111 234.795 L 190.111 295.75 L 299.708 295.75 L 299.708 234.795 L 190.111 234.795 Z" style={{fill: "none"}} transform="matrix(0, 1, -1, 0, 510.181984, 20.36299)"/>
    <rect x="207.859" y="177.392" width="84.282" height="145.216" style={{stroke: "rgb(30, 30, 30)", fill: "rgb(30, 30, 30)"}}/>
    <ellipse style={{stroke: "rgb(30, 30, 30)", fill: "rgb(30, 30, 30)"}} cx="250" cy="250" rx="66.344" ry="66.344"/>
    <ellipse style={{fill: "rgba(30, 30, 30, 0.6)"}} cx="250.854" cy="258.827" rx="43.565" ry="43.565"/>
    <ellipse style={{stroke: "rgb(0, 0, 0);", fill: "rgba(0, 0, 0, 0.67)"}} cx="250" cy="250" rx="3.132" ry="3.417"/>
    <g transform="matrix(1, 0, 0, 1, 6.430367, -14.887825)">
      <rect x="4.421" y="102.403" width="196.4" height="18.864" style={{fill: "rgb(40, 44, 177)", stroke: "rgb(255, 255, 255)"}} transform="matrix(1, 0.000067, -0.164469, 0.999989, 288.252686, 71.678658)"/>
      <rect x="4.421" y="102.403" width="196.4" height="18.864" style={{fill: "rgb(40, 44, 177)", stroke: "rgb(255, 255, 255)"}} transform="matrix(-1, -0.000067, 0.164469, -0.999989, 196.608673, 300.229797)"/>
      <g transform="matrix(1, 0, 0, 0.871403, -4.296306, 15.114956)">
        <title>Stocks</title>
        <g transform="matrix(1, 0, 0, 0.625002, 0, 75.766182)">
          <path d="M 21.831 186.466 L 248.359 186.466 L 474.885 186.466 L 474.885 194.255 L 248.359 202.044 L 21.831 193.656 L 21.831 194.255 L 21.831 186.466 Z" style={{strokeWidth: "0.320615px", fill: "rgb(40, 44, 177)", stroke: "rgb(255, 255, 255)"}}/>
        </g>
      </g>
      <rect x="234.728" y="181.562" width="17.533" height="10.18" style={{fill: "rgb(40, 44, 177)", stroke: "rgb(255, 255, 255)"}}/>
    </g>
    <g transform="matrix(0.799132, 0, 0, 1, 53.803108, -44.555622)">
      <title>Fan</title>
      <path d="M 232.34 352.146 C 237.421 343.814 252.008 344.298 257.113 352.146 L 254.232 392.299 L 237.525 392.693 L 232.34 352.146 Z" style={{stroke: "rgb(0, 0, 0)", strokeWidth: "0.333494px", fill: "rgb(175, 42, 42)"}}/>
      <path d="M 233.912 414.888 C 238.993 406.556 253.58 407.04 258.685 414.888 L 255.804 455.041 L 239.097 455.435 L 233.912 414.888 Z" style={{stroke: "rgb(0, 0, 0)", strokeWidth: "0.333494px", fill: "rgb(175, 42, 42)"}} transform="matrix(-1, 0, 0, -1, 492.597, 864.25415)"/>
      <rect x="244.033" y="347.285" width="2.786" height="108.031" style={{fill: "rgb(175, 42, 42)"}}>
        <title>Fly Stocks</title>
      </rect>
    </g>

                    </g>
                </g>
            </>
        )
    }
}

export default CustomOrientationDiagramPaula