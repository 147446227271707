import React from 'react'
import {
  DataGrid,
  Column,
  MasterDetail
} from 'devextreme-react/data-grid';
import fieldTestData from './FieldTestData';
import './LoRaWANFieldTest.css'
import LorawanGatewayTableTemplate from './LorawanGatewayTableTemplate'
import moment from 'moment';

var readings = [];

class LoRaWANFieldTest extends React.Component {

  componentDidMount() {
    this.loadLatestReadings();
  }

  loadLatestReadings() {
    fetch(`https://` + process.env.REACT_APP_SMARTMOLEN_API + `/api/fieldTestReadings`)
      .then(response => response.json())
      .then(response => {
        readings = response;
        fieldTestData.setFieldTestReadings(readings);
        this.setState({ readingLoadTime: new Date() })
      });
  }

  render() {
    if (readings.length === 0) {
      return <span>Loading ...</span>
    }
    else {
      return this.renderLoaded();
    }
  }

  calculateReadingTimeDisplay(rowData) {
    return moment(rowData.utcTime).format("DD/MM/yyyy HH:mm:ss");
  }

  calculateGatewayCount(rowData) {
    if (rowData == null || rowData.loRaWANMessageInfo == null || rowData.loRaWANMessageInfo.gateways == null) { return 0; }
    return rowData.loRaWANMessageInfo.gateways.length;
  }

  calculateTtnVersionDisplay(rowData) {
    if (rowData == null || rowData.loRaWANMessageInfo == null || rowData.loRaWANMessageInfo.gateways == null) { return ""; }
    return "V" + String(rowData.ttnVersion);
  }

  calculateLongDisplay(rowData) {
    if (rowData.deviceLocation != null) {
      return Math.round(rowData.deviceLocation.longitude * 1000) / 1000;
    }
    else { return ""; }
  }

  calculateLatDisplay(rowData) {
    if (rowData.deviceLocation != null) {
      return Math.round(rowData.deviceLocation.latitude * 1000) / 1000;
    }
    else {
      return "";
    }
  }

  renderLoaded() {
    return (
      <div className="tech-container">

        <div className="panel-container">
          <div className="panel-wide">
            <div className="panel-header">
              <div className="panel-header-title">
                LATEST READINGS
      </div>
            </div>
            <div className="panel-body">
              <div className="fieldtest-refresh-warning">
                DOES NOT AUTO UPDATE<br />
                Refresh browser for latest data
               </div>

              <DataGrid id="grid-container"
                dataSource={readings}
                keyExpr="id"
                showBorders={true}
                rowAlternationEnabled={true}
                width="100%"
              >
                <Column calculateCellValue={this.calculateReadingTimeDisplay} width={130} caption="Time" dataType="date" />
                <Column calculateCellValue={this.calculateTtnVersionDisplay} width={50} caption="TTN" />
                <Column dataField="gpsSatellites" width={50} caption="Sats" />
                <Column calculateCellValue={this.calculateLatDisplay} width={60} caption="Lat" />
                <Column calculateCellValue={this.calculateLongDisplay} width={60} caption="Long" />
                <Column calculateCellValue={this.calculateGatewayCount} width={60} caption="G'ways" />

                <MasterDetail enabled={true} component={LorawanGatewayTableTemplate} />

              </DataGrid>
            </div>
          </div>
        </div>
      </div>
    )
  }
}



export default LoRaWANFieldTest