import React from 'react';
import './OrientationHistoryPanel.css';
import {
    Chart,
    Series,
    ArgumentAxis,
    CommonAxisSettings,
    Grid,
    Export,
    Legend,
    Tooltip,
    Label,
    Format,
    ValueAxis,
    Point,
    Animation
} from 'devextreme-react/chart';
import moment from 'moment';

var historicalData;
var graphData = [];

class OrientationHistoryPanel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            historicalData: []
        }
    }

    componentDidMount() {
        this.updateAnalysis();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.molen.latestSummarisedHistoryRecords.latest60MinSummarisedHistoryUtcTime !== this.props.molen.latestSummarisedHistoryRecords.latest60MinSummarisedHistoryUtcTime) {
            this.updateAnalysis();
        }
    }

    updateAnalysis() {
        if (this.props.molen != null) {
            fetch(`https://` + process.env.REACT_APP_SMARTMOLEN_API + `/export/summarised/` + this.props.molen.shortName + "/60/720")
                .then(response => response.json())
                .then(response => {
                    historicalData = response;
                    this.generateGraphData();
                    this.setState({ historicalDataLoadTime: new Date() })
                });
        }
    }

    async generateGraphData() {
        graphData = [];

        historicalData.forEach((element, index, array) => {
            var elementUtcTime = Date.parse(element.dataSummary.endUtcTime);

            if ((element.dataSummary.startMolenOrientation + element.dataSummary.molenOrientationChange) > 359) {
                graphData.push({ utcTime: elementUtcTime - 45, molenOrientation: 359 });
                graphData.push({ utcTime: elementUtcTime - 30, molenOrientation: null });
                graphData.push({ utcTime: elementUtcTime - 15, molenOrientation: 0 });
            }
            if ((element.dataSummary.startMolenOrientation + element.dataSummary.molenOrientationChange) < 0) {
                graphData.push({ utcTime: elementUtcTime - 45, molenOrientation: 0 });
                graphData.push({ utcTime: elementUtcTime - 30, molenOrientation: null });
                graphData.push({ utcTime: elementUtcTime - 15, molenOrientation: 359 });
            }

            graphData.push({ utcTime: elementUtcTime, molenOrientation: element.dataSummary.endMolenOrientation });

            if (element.dataSummary.meanWindSpeedMs <= 1.5) {
                graphData.push({ utcTime: elementUtcTime, windDirBeauf01: element.dataSummary.windDirection });
            }
            else if (element.dataSummary.meanWindSpeedMs > 1.5 && element.dataSummary.meanWindSpeedMs <= 5.5) {
                graphData.push({ utcTime: elementUtcTime, windDirBeauf23: element.dataSummary.windDirection });
            }
            else if (element.dataSummary.meanWindSpeedMs > 5.5 && element.dataSummary.meanWindSpeedMs <= 10.7) {
                graphData.push({ utcTime: elementUtcTime, windDirBeauf45: element.dataSummary.windDirection });
            }
            else if (element.dataSummary.meanWindSpeedMs > 10.7 && element.dataSummary.meanWindSpeedMs <= 17.1) {
                graphData.push({ utcTime: elementUtcTime, windDirBeauf67: element.dataSummary.windDirection });
            }
            else if (element.dataSummary.meanWindSpeedMs > 17.1) {
                graphData.push({ utcTime: elementUtcTime, windDirBeauf8Plus: element.dataSummary.windDirection });
            }

        });

        this.setState({ graphDataRefreshTime: new Date() })
    }

    render() {
        if (this.props == null || this.props.molen == null || this.state.historicalData == null) {
            return <span>Loading ...</span>
        }
        else {
            return this.renderLoaded();
        }
    }

    renderLoaded() {
        return (
            <div className="panel-wide">
                {!(this.props.minimal) && <>
                    <div className="panel-header">
                        WINDING STATUS - 30 DAY HISTORY
                    </div>
                    Updated hourly - latest data to {moment(this.props.molen.latestSummarisedHistoryRecords.latest60MinSummarisedHistoryUtcTime).format("DD/MM/yyyy HH:mm:ss")}
                </>
                }
                <div className="panel-body-light">

                    <Chart
                        palette="Material"
                        dataSource={graphData}
                        title="Sail Alignment to Wind"
                        id="history-chart"
                    >

                        <Animation
                            enabled={false}
                        />

                        <Series type="scatter" argumentField="utcTime" valueField="windDirBeauf01" name="Wind (Force 0-1)" color="#0c9bd7" >
                            <Point symbol="circle" size="2" />
                        </Series>

                        <Series type="scatter" argumentField="utcTime" valueField="windDirBeauf23" name="Wind (Force 2-3)" color="#0aaa4a" >
                            <Point symbol="circle" size="3" />
                        </Series>

                        <Series type="scatter" argumentField="utcTime" valueField="windDirBeauf45" name="Wind (Force 4-5)" color="#92c83e" >
                            <Point symbol="circle" size="4" />
                        </Series>

                        <Series type="scatter" argumentField="utcTime" valueField="windDirBeauf67" name="Wind (Force 6-7)" color="#fcee13" >
                            <Point symbol="circle" size="5" />
                        </Series>

                        <Series type="scatter" argumentField="utcTime" valueField="windDirBeauf8Plus" name="Wind (Force 8+)" color="#f46d3d" >
                            <Point symbol="circle" size="6" />
                        </Series>

                        <Series type="spline" argumentField="utcTime" valueField="molenOrientation" name="Sails Facing" ignoreEmptyPoints={false} color="#000000">
                        </Series>

                        <CommonAxisSettings>
                            <Grid visible={true} />
                        </CommonAxisSettings>

                        <ArgumentAxis
                            argumentType="datetime"
                        >
                            <Label>
                                <Format type="dd/MM/yyyy" />
                            </Label>

                        </ArgumentAxis>

                        <ValueAxis min={0} max={360} inverted={true} tickInterval={90} valueMarginsEnabled={false} title="Compass Bearing" defaultVisualRange={[0, 360]}>

                        </ValueAxis>

                        <Legend
                            verticalAlignment="bottom"
                            horizontalAlignment="center"
                            position="outside"

                        />
                        <Export enabled={!(this.props.minimal)} />
                        <Tooltip enabled={!(this.props.minimal)} />
                    </Chart>

                </div>
            </div>

        )
    }
}

export default OrientationHistoryPanel
