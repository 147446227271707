import React from 'react'
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import './DigitalWindmill.css'
import './DigitalWindmillView2Live.css'
import DigitalWindmillNavigation from './DigitalWindmillNavigation';
import ThreeDayForecastPanel from '../panels/ThreeDayForecastPanel/ThreeDayForecastPanel';
import LiveCapOrientationPanel from '../panels/LiveCapOrientationPanel/LiveCapOrientationPanel';
import LiveSailRotationSensorPanel from '../panels/LiveSailRotationSensorPanel/LiveSailRotationSensorPanel';
import LiveFantailSensorPanel from '../panels/LiveFantailSensorPanel/LiveFantailSensorPanel';

class DigitalWindmillView2Live extends React.Component {

  state = {
    name: null,
    reloadCount: 0
  }

  lastLogTimeMillis = new Date().getTime();

  onNavigationItemClick(itemName) {
    var messageContent = JSON.stringify({ mode: itemName });
    window.parent.postMessage(messageContent, "*");
  }

  componentDidMount() {

    this.readFixedSizeItemScaleQueryString();

    const { molenName } = this.props.match.params
    fetch(`https://digital-windmill-api.azurewebsites.net/api/molenInfo/${molenName}`)
      .then(response2 => response2.json())
      .then(response2 => {
        this.setState({
          molenInfo: response2,
        })
      });

    const hubConnection = new HubConnectionBuilder()
      .withUrl(`https://` + process.env.REACT_APP_SIGNALR_API)
      .configureLogging(LogLevel.Information)
      .withAutomaticReconnect()
      .build();

    console.log(hubConnection);

    this.setState({ hubConnection }, () => {
      this.state.hubConnection
        .start()
        .then(() => {
          console.log('Connection started!');
        })
        .catch(err => {
          console.log('Error while establishing connection :(');
          console.log(err);
        });


      this.state.hubConnection.on('molenUpdated',
        (receivedMessage) => {
          this.reloadState();
        });

    });

    this.reloadState();

  }

  readFixedSizeItemScaleQueryString() {
    var fixedSizeItemScale = 1.0;
    if (window.location.search != null) {
      var queryString = window.location.search;
      if (queryString != null) {
        var params = new URLSearchParams(queryString);
        var fixedSizeItemScaleQSValue = params.get('fixedSizeItemScale');
        if (fixedSizeItemScaleQSValue !== null) {
          fixedSizeItemScale = parseFloat(fixedSizeItemScaleQSValue);
        }
      }
    }

    console.info("fixedSizeItemScale: " + fixedSizeItemScale);
    document.getElementsByClassName("SmartMolen")[0].style.setProperty("--fixed-size-item-scale", fixedSizeItemScale);
    document.getElementsByClassName("SmartMolen")[0].style.setProperty("--smartmolen-content-scale", fixedSizeItemScale * 0.96);
  }

  reloadState() {

    const { molenName } = this.props.match.params
    fetch(`https://` + process.env.REACT_APP_SMARTMOLEN_API + `/api/molen/${molenName}`)
      .then(response => response.json())
      .then(response => {
        this.setState({
          molen: response,
        })
      });
  }

  render() {
    if (this.state == null || this.state.molen == null || this.state.molenInfo == null) {
      return <span>&nbsp;</span>
    }
    else {
      document.title = "DW Smartmolen - " + this.state.molen.name;
      return this.renderLoaded();
    }
  }

  onAnalysisViewSelect() {
    console.info("selecting analysis view");
    var messageContent = JSON.stringify({ selectedMolen: this.state.molen.shortName, mode: "analysis" });
    window.parent.postMessage(messageContent, "*");
  }


  renderLoaded() {

    return (
      <div className="dw-container" id="dw-container">

        <DigitalWindmillNavigation molen={this.state.molen} selected={"about"} onNavigationItemClick={this.onNavigationItemClick} selectedSubscreen={2} />

        <div className="dw2-smartmolen-content">
            {(this.state.molen.latestOrientationSensorReading != null && this.state.molen.latestWeatherObservation != null) && <LiveCapOrientationPanel molen={this.state.molen} includeHyperlinks={false} />}
            {this.state.molen.latestWeatherForecast != null && <ThreeDayForecastPanel molen={this.state.molen} includeHyperlinks={false} />}
            {this.state.molen.latestSailRotationReading != null && <LiveSailRotationSensorPanel molen={this.state.molen} />}
            {this.state.molen.latestFantailSensorReading != null && <LiveFantailSensorPanel molen={this.state.molen} />}
          </div>

      </div>
    )
  }
}



export default DigitalWindmillView2Live