import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css'
import './index.css';
import SmartMolen from './SmartMolen';
import reportWebVitals from './reportWebVitals';
import { BreakpointProvider } from 'react-socks';
import { setDefaultBreakpoints } from 'react-socks';
import { IntlProvider } from 'react-intl';
import German from './lang/de.json';
import English from './lang/en.json';
import Dutch from './lang/nl.json';
import UnitsContext from './UnitsContext';
import './odometer/odometer-theme-minimal.css';
import './odometer/odometer-theme-car.css';

const locale = navigator.language;

let lang;
let unitsContext;

if (locale === "de") {
  lang = German;
  unitsContext = "metric";
} else if (locale === "nl") {
  lang = Dutch;
  unitsContext = "metric";
}
else {
  lang = English;
  unitsContext = "british";
}

ReactDOM.render(
  <React.StrictMode>
    <BreakpointProvider>
      <IntlProvider locale={locale} messages={lang}>
        <UnitsContext.Provider value={unitsContext}>
          <SmartMolen />
        </UnitsContext.Provider>
      </IntlProvider>
    </BreakpointProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

setDefaultBreakpoints([
  { xsmall: 0 }, // all mobile devices
  { small: 576 }, // mobile devices (not sure which one's this big)
  { medium: 768 }, // ipad, ipad pro, ipad mini, etc
  { large: 992 }, // smaller laptops
  { xlarge: 1400 } // laptops and desktops
]);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
