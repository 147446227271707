import React from 'react'
import './DigitalWindmillView4WindingTracker.css'
import DigitalWindmillNavigation from './DigitalWindmillNavigation';
import DigitalWindmillView4Graph from './DigitalWindmillView4Graph';

class DigitalWindmillView4WindingTracker extends React.Component {

  constructor(props) {
    super(props);
    this.onNavigationItemClick = this.onNavigationItemClick.bind(this);
  }

  onNavigationItemClick(itemName) {
    console.info("navigationItemClicked: " + itemName);
    var messageContent = JSON.stringify({ selectedMolen: this.state.molen.shortName, mode: itemName });
    window.parent.postMessage(messageContent, "*");
  }


  componentDidMount() {
    this.readFixedSizeItemScaleQueryString();
    this.reloadState();

  }

  readFixedSizeItemScaleQueryString() {
    var fixedSizeItemScale = 1.0;
    if (window.location.search != null) {
      var queryString = window.location.search;
      if (queryString != null) {
        var params = new URLSearchParams(queryString);
        var fixedSizeItemScaleQSValue = params.get('fixedSizeItemScale');
        if (fixedSizeItemScaleQSValue !== null) {
          fixedSizeItemScale = parseFloat(fixedSizeItemScaleQSValue);
        }
      }
    }

    console.info("fixedSizeItemScale: " + fixedSizeItemScale);
    document.getElementsByClassName("SmartMolen")[0].style.setProperty("--fixed-size-item-scale", fixedSizeItemScale);
  }

  reloadState() {

    const { molenName } = this.props.match.params

    fetch(`https://` + process.env.REACT_APP_SMARTMOLEN_API + `/api/molen/${molenName}`)
      .then(response => response.json())
      .then(response => {
        this.setState({
          molen: response,
        })
      });
  }


  render() {
    if (this.state == null || this.state.molen == null) {
      return <span>&nbsp;</span>
    }
    else {
      document.title = "DW Smartmolen - " + this.state.molen.name;
      return this.renderLoaded();
    }
  }

  onAnalysisViewSelect() {
    console.info("selecting analysis view");
    var messageContent = JSON.stringify({ mode: "analysis" });
    window.parent.postMessage(messageContent, "*");
  }

  renderLoaded() {

    var svgDotSize = 8 * document.getElementsByClassName("SmartMolen")[0].style.getPropertyValue('--fixed-size-item-scale');
    var svgDotRadius = svgDotSize / 2.0;

    return (
      <div className="dw-container" id="dw-container">

        <DigitalWindmillNavigation molen={this.state.molen} selected={"about"} onNavigationItemClick={this.onNavigationItemClick} selectedSubscreen={4} />

        <div className="dw4-graph" width="100%" height="100%">
          <DigitalWindmillView4Graph molen={this.state.molen} minimal={true} />
          <div className="dw4-graph-explanation">
            <div className="dw4-graph-explanation-left">
              <b>Explanatory Notes</b><br />
              This graph shows how the cap at {this.state.molen.name} Windmill has turned to face the wind over the last 30 days.<br />
              <ul>
                <li>The <b>vertical axis</b> represents the full circle of the compass.  For instance, South is represented as 180&deg;</li>
                <li>The <b>horizontal axis</b> represents time</li>
                <li>The <b>coloured dots</b> indicate the wind conditions:
                  <ul><li>The colour represents the wind speed, according to the Beaufort Scale</li>
                    <li>The dot's position on the vertical axis represents the direction from which the wind blew</li>
                  </ul></li>
                <li>The <b>black line</b> shows the orientation of the mill's cap</li></ul>
            </div>
            <div className="dw4-graph-explanation-right">
              <b>Turning to Wind</b><br />
              <table>
                <tbody>
                  <tr>
                    <td>
                      <svg width={svgDotSize} height={svgDotSize}><circle cx={svgDotRadius} cy={svgDotRadius} r={svgDotRadius} fill={"#0c9bd7"}></circle></svg>&nbsp;
                    </td><td><b>Light winds</b> (blue dots) are typically not strong enough to turn the cap to wind</td></tr>
                  <tr><td width="12%">
                    <svg width={svgDotSize} height={svgDotSize}><circle cx={svgDotRadius} cy={svgDotRadius} r={svgDotRadius} fill={"#0aaa4a"}></circle></svg>&nbsp;
                    <svg width={svgDotSize} height={svgDotSize}><circle cx={svgDotRadius} cy={svgDotRadius} r={svgDotRadius} fill={"#92c83e"}></circle></svg>&nbsp;
                  </td><td>In <b>moderate winds</b> (green dots) the cap will usually track the wind direction closely</td></tr>
                  <tr><td width="12%">
                    <svg width={svgDotSize} height={svgDotSize}><circle cx={svgDotRadius} cy={svgDotRadius} r={svgDotRadius} fill={"#fcee13"}></circle></svg>&nbsp;
                    <svg width={svgDotSize} height={svgDotSize}><circle cx={svgDotRadius} cy={svgDotRadius} r={svgDotRadius} fill={"#f46d3d"}></circle></svg>&nbsp;
                  </td><td>In <b>strong winds</b> (yellow or orange dots) it is extremely important the cap is facing into the wind to prevent damage</td></tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    )
  }
}



export default DigitalWindmillView4WindingTracker