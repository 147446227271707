import React from 'react'
import moment from 'moment';
import Clock from 'react-live-clock';
import OrientationDiagram from '../../components/orientationDiagram/orientationDiagram';
import BearingDiffCalculator from '../../components/windLogic';
import './LiveCapOrientationPanel.css';
import WindLogic from '../../components/windLogic';
import UnitsContext from '../../UnitsContext';

class LiveCapOrientationPanel extends React.Component {

    static contextType = UnitsContext;

    render() {
        if (this.props == null || this.props.molen == null) {
            return <span>Loading ...</span>
        }
        else {
            return this.renderLoaded();
        }
    }

    renderLoaded() {
        
        var noWind = (this.props.molen.latestWeatherObservation.windDirection == null);

        /// display wind speed in local units, defaulting to British
        var unitsContext = this.context;
        var windSpeedUnitText = "mph";
        var windSpeed = Math.round(this.props.molen.latestWeatherObservation.windSpeedMs * 2.23694);
        var windGustSpeed = Math.round(this.props.molen.latestWeatherObservation.windGustMs * 2.23694);
        if (unitsContext === "metric"){
            windSpeedUnitText = "km/h";
            windSpeed = Math.round(this.props.molen.latestWeatherObservation.windSpeedMs * 3.6);
            windGustSpeed = Math.round(this.props.molen.latestWeatherObservation.windGustMs * 3.6);
        }

        // if no wind, bearing diff colour is the default for no difference
        var bearingDiffColour;
        if (noWind) {
            bearingDiffColour = new BearingDiffCalculator().getBearingDiffIndicationColourDarker(0, 0, this.props.molen.latestWeatherObservation.windSpeedMs)
        }
        else {
            bearingDiffColour = new BearingDiffCalculator().getBearingDiffIndicatorColourForWindingRAGStatus(this.props.molen.windingRAGStatus, true);
        }

        var webcamAngle = -1;
        if (this.props.molen.webcam != null) {
            webcamAngle = this.props.molen.webcam.bearingRelativeToMolen;
        }

        var includeHyperlinks = true;
        if (this.props.includeHyperlinks != null) {
            includeHyperlinks = this.props.includeHyperlinks;
        }

        return (

            <div className="panel-narrow">
                <div className="panel-header">
                    <div className="panel-top-left">
                        LIVE WINDING STATUS
                    </div>
                    <div className="panel-top-right">
                        <Clock format={'HH:mm:ss'}
                            ticking={true}
                        />
                    </div>
                </div>
                <div className="panel-body">
                    <div className="orientation-diagram-panel">
                        <OrientationDiagram windingRAGStatus={this.props.molen.windingRAGStatus} windDirection={this.props.molen.latestWeatherObservation.windDirection} molenOrientation={this.props.molen.latestOrientationSensorReading.bearing} windIndicationColour={bearingDiffColour} webcamAngle={webcamAngle} graphicsSettings={this.props.molen.displayGraphics} molenShortName={this.props.molen.shortName} />
                    </div>

                    <div className="panel-columns-span-width">
                        <div className="wind-data-panel">
                            <div className="app-panel-header-text">WIND</div>
                            <div className="app-oversize-text">{(noWind) ? "None" : this.props.molen.latestWeatherObservation.windDirectionCompassPoint}</div>
                            {(noWind) ? "" : (this.props.molen.latestWeatherObservation.windDirection.toString() + "\xB0")}<br />
                            <div className="app-standard-text">
                                {new WindLogic().isWindGusting(this.props.molen.latestWeatherObservation.windSpeedMs, this.props.molen.latestWeatherObservation.windGustMs) ?
                                    <><div className="wind-data-panel-gust">Gusts to {windGustSpeed} {windSpeedUnitText}</div></> :
                                    <><b>Force {this.props.molen.latestWeatherObservation.windSpeedBeaufort}</b>&nbsp;&nbsp;|&nbsp;&nbsp;{windSpeed} {windSpeedUnitText}</>}
                            </div>
                            <div className="app-supersmalltext">
                                <br />
                                Reported {moment(this.props.molen.latestWeatherObservation.utcTime).format("HH:mm:ss")} by<br />
                                {(includeHyperlinks && this.props.molen.latestWeatherObservation.weatherSourceLink.length > 0) && <><a target="_blank" rel="noreferrer" href={this.props.molen.latestWeatherObservation.weatherSourceLink}>{this.props.molen.latestWeatherObservation.weatherSourceName}</a></>}
                                {(!includeHyperlinks && this.props.molen.latestWeatherObservation.weatherSourceLink.length > 0) && <>{this.props.molen.latestWeatherObservation.weatherSourceName}</>}
                                {this.props.molen.latestWeatherObservation.weatherSourceLink.length === 0 && <>{this.props.molen.latestWeatherObservation.weatherSourceName}</>}
                            </div>

                        </div>

                        <div className="mill-facing-panel" id="mill-facing-pnl" style={{ backgroundColor: bearingDiffColour }}>
                            <div className="app-panel-header-text">SAILS FACING</div>
                            {(this.props.molen.windingRAGStatus !== "GREY" && this.props.molen.windingRAGStatus !== "FAULT") &&
                                <>
                                    <div className="app-oversize-text">{this.props.molen.latestOrientationSensorReading.compassPoint}</div>{this.props.molen.latestOrientationSensorReading.bearing}&deg;
                                    <div className="app-standard-text"></div>
                                </>}
                            {(this.props.molen.windingRAGStatus === "GREY") &&
                                <>
                                    <div className="app-oversize-text">{this.props.molen.latestOrientationSensorReading.compassPoint}</div>{this.props.molen.latestOrientationSensorReading.bearing}&deg;
                                    <div className="app-standard-text">Accuracy Warning</div></>}
                            {(this.props.molen.windingRAGStatus === "FAULT") &&
                                <>
                                    <div className="app-oversize-text">-&nbsp;</div><br />
                                    <div className="app-standard-text">No signal</div></>}
                            <div className="app-supersmalltext">
                                <br />
                                {(this.props.molen.windingRAGStatus !== "FAULT") && <>
                                    Reported {moment(this.props.molen.latestOrientationSensorReading.utcTime).format("HH:mm:ss")} by<br />mill sensor</>}
                                {(this.props.molen.windingRAGStatus === "FAULT") && <>
                                    Last contact from mill sensor at {moment(this.props.molen.latestOrientationSensorReading.utcTime).format("HH:mm:ss")} on {moment(this.props.molen.latestOrientationSensorReading.utcTime).format("DD/MM/yyyy")}</>}
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        )
    }
}

export default LiveCapOrientationPanel
