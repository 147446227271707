import React from 'react';
import './SensorGatewayStatsPanel.css';
import moment from 'moment';
import SensorGatewayStatsTableRowTemplate from './SensorGatewayStatsTableRowTemplate';



class SensorGatewayStatsPanel extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            lorawanStats: {
            },
            uplinkMessageCount: 0,
        }
    }


    componentDidMount() {
        this.updateAnalysis();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.analysisId !== this.props.analysisId) {
            this.updateAnalysis();
        }
    }

    updateAnalysis() {
        if (this.props.analysisId != null) {
            fetch(`https://` + process.env.REACT_APP_SMARTMOLEN_API + `/api/lorawanStats/` + this.props.analysisId)
                .then(response => response.json())
                .then(response => {
                    this.setState({ lorawanStats: response });
                    var sortedGateways = response.gatewayStats.gateways.sort(function(a, b) {return b.uplinkMessageCount - a.uplinkMessageCount;});
                    this.setState({ gatewayStatsSorted: sortedGateways});
                });
        }
    }

    render() {
        if (this.props == null || this.props.analysisId == null || this.state.lorawanStats == null || this.state.lorawanStats.gatewayStats == null) {
            return <span>Loading ...</span>
        }
        else {
            return this.renderLoaded();
        }
    }

    renderLoaded() {
        return (
            <div className="panel-wide">
                <div className="panel-header">
                    LORAWAN GATEWAY STATS (LAST 60 DAYS) - {this.state.lorawanStats.gatewayStats.numberOfUplinkMessages} READINGS
                </div>
                Last updated {moment(this.state.lorawanStats.gatewayStats.timeRangeEnd).format("DD/MM/yyyy HH:mm:ss")}

                <div className="panel-body-light" width="100%">

                <table id="sensor-gateway-stats-table" width="100%">
                    <tbody>
                        <tr>
                            <th width="35%">Gateway</th>
                            <th width="15%">Readings</th>
                            <th width="20%">Signal Strength (dBm)</th>
                            <th width="5%"></th>
                            <th width="20%">Signal To Noise Ratio</th>
                            <th width="5%"></th>
                        </tr>
                        {
                            this.state.lorawanStats.gatewayStats.gateways.map((gateway, index) => <SensorGatewayStatsTableRowTemplate key={index} gateway={gateway} totalUplinkMessages={this.state.lorawanStats.gatewayStats.numberOfUplinkMessages} />)
                        }
                    </tbody>
                </table>

                </div>
            </div>

        )
    }

    customizeSnrLabelText(arg) {
        return `${(Math.round(arg.value * 10)) / 10}`;
    }

    customizeRssiLabelText(arg) {
        return `${Math.round(arg.value)}`;
    }

    customizeMessageCountLabel(arg) {
            return `${arg.value}`;
    }

}



export default SensorGatewayStatsPanel
