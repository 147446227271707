import React from 'react'
import './UpminsterDustFloorCameraPanel.css';

class UpminsterDustFloorCameraPanel extends React.Component {

    currentCamera = "NE";

    constructor(props) {
        super(props)
        this.state = { selectedCamera: "NE" };
      }

    componentDidMount() {
        const switchCameraFunc = this.switchCamera.bind(this);
        setInterval(switchCameraFunc, 30000);
    }

    switchCamera() {
        console.log("Switching camera");

        var newCamera = "NE";
        if (this.state !== null && this.state.selectedCamera !== null) {
            newCamera = this.state.selectedCamera === "NE" ? "NW" : "NE";
        }
        this.setState({ selectedCamera: newCamera });
    }

    render() {
        return this.renderLoaded();
    }

    renderLoaded() {

        return (

            <div className="panel-wide">
                <div className="panel-header">
                    <div className="panel-top-left">
                        LIVE DUST FLOOR CAMERA
                    </div>
                </div>
                <div className="panel-body">
                    {this.state.selectedCamera === "NE" &&
                        <iframe src={"https://view-video.smartmolen.com:5443/5da4cf72947144f8b6d00df1b89698ae/play.html?name=798777657158216999305400"} allow="autoplay" className="webcam-view" scrolling="no" title="dustfloornweebcam" allowFullScreen style={{ border: "none" }} width="100%" height="100%"></iframe>}
                    {this.state.selectedCamera === "NW" &&
                        <iframe src={"https://view-video.smartmolen.com:5443/5da4cf72947144f8b6d00df1b89698ae/play.html?name=587190536928663120880539"} allow="autoplay" className="webcam-view" scrolling="no" title="dustfloornwwebcam" allowFullScreen style={{ border: "none" }} width="100%" height="100%"></iframe>}
                </div>
            </div>
        )
    }
}

export default UpminsterDustFloorCameraPanel
