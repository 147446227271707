var fieldTestReadings = [];

function getFieldTestReadings() {
    return fieldTestReadings;
}

function setFieldTestReadings(readings) {
    fieldTestReadings = readings;
} 

const fieldTestData = {
    getFieldTestReadings,
    setFieldTestReadings,
};

export default fieldTestData;