import React from 'react'
import './Console.css'
import ConsoleTableItem from './consoleTableItem';
import MonitoringStatusSummary from '../components/molenList/monitoringStatusSummary/monitoringStatusSummary';

class ConsoleMolenList extends React.Component {


    componentDidMount() {
    }

    reloadMolens() {

    }

    render() {
        if (this.props.molenList == null) {
            return <span>...</span>
        }
        else {
            return this.renderLoaded();
        }
    }

    selectMolen(molen) {
        // TODO: signal to parent!
    }

    renderLoaded() {
        return (
            <>
                <div className="console-right-body-heading">
                    <MonitoringStatusSummary molenList={this.props.molenList} />
                </div>

                {this.props.molenList.map((molen, index) => (
                    <ConsoleTableItem key={molen.molenId} molen={molen} onMolenSelected={this.selectMolen.bind(this)} />
                ))}
            </>
        )
    }
}

export default ConsoleMolenList





