import React from 'react';

class CustomOrientationDiagramAythorpe extends React.Component {

    componentDidMount() {
    }

    render() {

        return (
            <>
                <defs>
                    <linearGradient gradientUnits="userSpaceOnUse" x1="242.956" y1="224.17" x2="242.956" y2="374.452" id="gradient-0" gradientTransform="matrix(0.753111, 0.007207, -0.001716, 0.179401, 0.651229, 111.644341)">
                        <stop offset="0" style={{ stopColor: "rgba(255, 255, 255, 1)" }}></stop>
                        <stop offset="1" style={{ stopColor: "rgba(204, 204, 204, 1)" }}></stop>
                    </linearGradient>
                </defs>

                <g name="base" transform="matrix(1, 0, 0, 1, 0, 0)">
                    <ellipse style={{ stroke: "rgb(42, 40, 40)", fill: "rgb(96, 83, 69)" }} cx="144" cy="144" rx="54.186" ry="54.186"></ellipse>
                </g>

                <g name="cap-outer" transform={"rotate( " + this.props.molenOrientation + ", 144, 144)"}>
                    {this.props.windingRAGStatus === "FAULT" && <animateTransform attributeName="transform" type="rotate" dur="4s" from="0 142 142" to="360 142 142" repeatCount="indefinite" />}
                    <g name="cap-inner" transform="matrix(1, 0, 0, 1, 8.908863, -10.858231)">
                        <g transform="matrix(0.361512, 0.012399, -0.009673, 0.458215, 49.490936, 44.796516)">
                            <polyline style={{fill: "rgb(255, 255, 255)", stroke: "rgb(255, 255, 255)"}} points="236.7 396.682 248.451 434.116 253.24 433.755 260.685 396.682" transform="matrix(-1, 0, 0, -1, 497.384995, 830.798004)" />
                            <ellipse style={{fill: "rgb(255, 255, 255)", stroke: "rgb(255, 255, 255)"}} cx="248.642" cy="433.761" rx="11.894" ry="4.59" />
                        </g>
                        <g transform="matrix(-0.403959, -0.018904, 0.012926, -0.448732, 229.330566, 414.525696)">
                            <polyline style={{fill: "rgb(255, 255, 255)", stroke: "rgb(255, 255, 255)"}} points="236.7 396.682 248.451 434.116 253.24 433.755 260.685 396.682" transform="matrix(-1, 0, 0, -1, 497.384995, 830.798004)" />
                            <ellipse style={{fill: "rgb(255, 255, 255)", stroke: "rgb(255, 255, 255)"}} cx="248.642" cy="433.761" rx="11.894" ry="4.59" />
                        </g>
                        <g transform="matrix(0.518937, 0, 0, 0.422801, 6.737543, 28.726366)">
                            <g>
                                <rect x="4.159" y="187.161" width="486.361" height="6.33" style={{fill: "rgb(255, 255, 255)", stroke: "rgb(255, 255, 255)"}} />
                                <path d="M -8.89 -530.506 L -8.89 -353.264 L -18.386 -353.264 L -8.89 -530.506 Z" style={{fill: "rgb(255, 255, 255)", stroke: "rgb(255, 255, 255)"}} transform="matrix(0, -1, -1, 0, -40.041053, 185.128589)" />
                                <rect x="180.291" y="187.216" width="132.931" height="15.825" style={{fill: "rgb(255, 255, 255)", stroke: "rgb(255, 255, 255)"}} />
                                <path d="M 27.882 530.506 L 27.882 707.748 L 18.386 707.748 L 27.882 530.506 Z" style={{fill: "rgb(255, 255, 255)", stroke: "rgb(255, 255, 255)"}} transform="matrix(0, -1, 1, 0, -526.346558, 221.95517)" />
                                <rect x="236.262" y="195.636" width="21.1" height="12.107" style={{fill: "rgb(255, 255, 255)", stroke: "rgb(255, 255, 255)"}} />
                            </g>
                        </g>
                        <rect x="144.309" y="127.003" width="77.342" height="53.569" style={{fill: "url(#gradient-0)", stroke: "rgb(255, 255, 255)"}} transform="matrix(-0.003636, -0.999993, 0.999993, -0.003636, -18.330687, 337.908905)" />
                        <rect x="118.512" y="191.093" width="4.944" height="40.61" style={{fill: "rgb(255, 255, 255)", stroke: "rgb(255, 255, 255)"}} />
                        <rect x="146.696" y="190.565" width="4.944" height="40.61" style={{fill: "rgb(255, 255, 255)", stroke: "rgb(255, 255, 255)"}} />
                        <rect x="120.632" y="194.414" width="27.897" height="1.413" style={{fill: "rgb(255, 255, 255)", stroke: "rgb(255, 255, 255)"}} />
                        <rect x="121.162" y="198.3" width="27.897" height="1.413" style={{fill: "rgb(255, 255, 255)", stroke: "rgb(255, 255, 255)"}} />
                        <rect x="121.868" y="202.183" width="27.897" height="1.413" style={{fill: "rgb(255, 255, 255)", stroke: "rgb(255, 255, 255)"}} />
                        <rect x="119.043" y="205.715" width="27.897" height="1.413" style={{fill: "rgb(255, 255, 255)", stroke: "rgb(255, 255, 255)"}} />
                        <rect x="120.455" y="209.315" width="27.897" height="1.413" style={{fill: "rgb(255, 255, 255)", stroke: "rgb(255, 255, 255)"}} />
                        <rect x="121.514" y="216.52" width="27.897" height="1.413" style={{fill: "rgb(255, 255, 255)", stroke: "rgb(255, 255, 255)"}} />
                        <rect x="120.102" y="212.989" width="27.897" height="1.413" style={{fill: "rgb(255, 255, 255)", stroke: "rgb(255, 255, 255)"}} />
                        <rect x="120.809" y="219.909" width="27.897" height="1.413" style={{fill: "rgb(255, 255, 255)", stroke: "rgb(255, 255, 255)"}} />
                        <rect x="121.514" y="222.876" width="27.897" height="1.413" style={{fill: "rgb(255, 255, 255)", stroke: "rgb(255, 255, 255)"}} />
                        <rect x="132.143" y="191.093" width="4.944" height="14.831" style={{fill: "rgb(255, 255, 255)", stroke: "rgb(255, 255, 255)"}} />
                        <rect x="120.962" y="226.031" width="27.897" height="1.413" style={{fill: "rgb(255, 255, 255)", stroke: "rgb(255, 255, 255)"}} />
                        <rect x="113.394" y="229.046" width="43.561" height="3.25" style={{fill: "rgb(255, 255, 255)", stroke: "rgb(255, 255, 255)"}} />
                    </g>
                </g>



            </>


        )
    }
}

export default CustomOrientationDiagramAythorpe