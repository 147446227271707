import React from 'react';

class WhirlOffWeekdaySparkBars extends React.Component {

    componentDidMount() {
    }

    render() {

        var barWidth = 13;
        var barSpacing = 14.5;
        var height = 20;

        var dayOfWeekLetters = ["M", "T", "W", "T", "F", "S", "S"];

        // NOTE co-ordinates 0,0 start from top left corner

        var maxValue = Math.max(...this.props.countsByWeekday);
        var scaledArray;
        if (maxValue === 0) { scaledArray = this.props.countsByWeekday.map(elem => height); } else {
            scaledArray = this.props.countsByWeekday.map(elem => height -((elem * height) / maxValue));
        }

        return (
            <>
                <div className="whirloff-weekday-spark-bars" >
                    <svg height={height} width={(barSpacing*6) + barWidth}>
                        {scaledArray.map((currentValue, index) => (
                            <>
                                <polygon points={(index * barSpacing).toString() + "," + height + " " + (index * barSpacing + barWidth) + "," + height + " " + (index * barSpacing + barWidth) + "," + currentValue + " " + (index * barSpacing) + "," + currentValue} fill={index <= 4 ? "darkgrey" : "lightblue"} />
                                <text x={(index * barSpacing) + (barWidth / 2)} y={height-5} dominant-baseline="middle" text-anchor="middle" font-size="10">{dayOfWeekLetters[index]}</text>
                            </>
                        ))}
                    </svg>
                </div>

            </>
        )
    }
}



export default WhirlOffWeekdaySparkBars
