import React from 'react';

class CustomOrientationDiagramHardley extends React.Component {

    componentDidMount() {
    }

    render() {

        return (
            <>

                <g name="base" transform="matrix(0.575704, 0, 0, 0.575704, 3.932375, -22.707181)">
                    <ellipse style={{ stroke: "rgb(42, 40, 40)", fill: "rgb(161, 95, 21)" }} cx="243.298" cy="289.571" rx="96.083" ry="96.083" />
                </g>

                <g name="cap-outer" transform={"rotate( " + this.props.molenOrientation + ", 144, 144)"}>
                    {this.props.windingRAGStatus === "FAULT" && <animateTransform attributeName="transform" type="rotate" dur="4s" from="0 142 142" to="360 142 142" repeatCount="indefinite" />}
                    <g name="cap-inner" transform="matrix(0.575704, 0, 0, 0.575704, 3.427819, -24.504173)">
                        <g transform="matrix(0.729314, 0, 0, 0.718889, 107.014313, 107.810715)">
                            <rect x="120.559" y="174.209" width="134.117" height="167.682" style={{fill: "rgb(255, 255, 255)", stroke: "rgb(255, 255, 255)"}} />
                            <path style={{fill: "rgb(255, 255, 255)", stroke: "rgb(255, 255, 255)"}} d="M 121.833 342.072 C 121.833 342.072 119.01396946158103 336.9859139373728 117.37 332.828 C 114.24728697146986 324.9300600886014 108.87246798562265 309.08687965544397 106.31 297.481 C 103.91467660289558 286.6321483309681 102.72567977536218 276.61139504113055 102.071 265.397 C 101.35588877465688 253.14743909840075 101.31866495781861 237.9189622198937 102.64 226.788 C 103.66886466964246 218.12081612794654 105.7695251983353 210.86652655154643 107.785 203.949 C 109.51947068147473 197.9959377329501 111.5860658687572 192.73067933134962 113.656 187.635 C 115.53797758648057 183.00202441650436 119.582 174.585 119.582 174.585" transform="matrix(0.999918, 0.012827, -0.012827, 0.999918, 3.322769, -1.411598)" />
                            <path style={{fill: "rgb(255, 255, 255)", stroke: "rgb(255, 255, 255)"}} d="M 273.007 174.695 C 273.007 174.695 270.187969461581 179.7810860626272 268.544 183.939 C 265.42128697146984 191.83693991139856 260.04646798562266 207.680120344556 257.484 219.286 C 255.0886766028956 230.13485166903195 253.89967977536213 240.15560495886933 253.245 251.37 C 252.5298887746569 263.6195609015992 252.4926649578186 278.84803778010615 253.814 289.979 C 254.8428646696425 298.64618387205354 256.94352519833524 305.9004734484535 258.959 312.818 C 260.6934706814747 318.77106226705 262.7600658687572 324.0363206686504 264.83 329.132 C 266.7119775864805 333.76497558349564 270.756 342.182 270.756 342.182" transform="matrix(-0.999918, 0.012827, -0.012827, -0.999918, 529.052977, 513.483794)" />
                            <path style={{fill: "rgb(255, 255, 255)", stroke: "rgb(255, 255, 255)"}} d="M 117.587 174.12 L 256.722 174.12 C 209.731 160.252 163.353 160.252 117.587 174.12 Z" />
                        </g>
                        <g>
                            <rect x="214.277" y="400.84" width="60.245" height="1.143" style={{fill: "rgb(126, 126, 126)", stroke: "rgb(126, 126, 126)"}} />
                            <rect x="202.012" y="347.289" width="14.803" height="37.33" style={{fill: "rgb(255, 255, 255)", stroke: "rgb(255, 255, 255)"}} />
                            <rect x="270.271" y="347.576" width="14.803" height="37.33" style={{fill: "rgb(255, 255, 255)", stroke: "rgb(255, 255, 255)"}} />
                            <rect x="198.829" y="370.746" width="90.107" height="11.585" style={{fill: "rgb(255, 255, 255)", stroke: "rgb(255, 255, 255)"}} />
                            <g transform="matrix(0.999638, 0.026898, -0.026898, 0.999638, 8.254025, -3.175472)">
                                <polyline style={{fill: "rgb(255, 255, 255)", stroke: "rgb(255, 255, 255)"}} points="236.7 396.682 248.451 434.116 253.24 433.755 260.685 396.682" transform="matrix(-1, 0, 0, -1, 497.384995, 830.798004)" />
                                <ellipse style={{fill: "rgb(255, 255, 255)", stroke: "rgb(255, 255, 255)"}} cx="248.642" cy="433.761" rx="11.894" ry="4.59" />
                            </g>
                            <g transform="matrix(-0.999327, -0.036689, 0.036689, -0.999327, 476.26535, 806.312988)">
                                <polyline style={{fill: "rgb(255, 255, 255)", stroke: "rgb(255, 255, 255)"}} points="236.7 396.682 248.451 434.116 253.24 433.755 260.685 396.682" transform="matrix(-1, 0, 0, -1, 497.384995, 830.798004)" />
                                <ellipse style={{fill: "rgb(255, 255, 255)", stroke: "rgb(255, 255, 255)"}} cx="248.642" cy="433.761" rx="11.894" ry="4.59" />
                            </g>
                            <rect x="288.899" y="120.735" width="65.422" height="1.29" style={{fill: "rgb(255, 255, 255)", stroke: "rgb(255, 255, 255)"}} transform="matrix(0.97173, 0.236095, -0.36453, 0.940525, -24.541237, 201.845062)" />
                            <rect x="-299.706" y="-144.462" width="67.869" height="1.544" style={{fill: "rgb(255, 255, 255)", stroke: "rgb(255, 255, 255)"}} transform="matrix(-0.97173, 0.236095, 0.36453, 0.940525, 38.659104, 589.668821)" />
                            <polygon style={{fill: "rgb(255, 255, 255)", stroke: "rgb(255, 255, 255)"}} points="199.914 381.977 215.862 401.714 220.351 398.914 209.63 379.757" transform="matrix(0.96581, 0.259251, -0.259251, 0.96581, 108.483009, -41.117809)" />
                            <polygon style={{fill: "rgb(255, 255, 255)", stroke: "rgb(255, 255, 255)"}} points="267.894 399.312 283.842 379.575 288.331 382.375 277.61 401.532" transform="matrix(-0.96581, 0.259251, -0.259251, -0.96581, 647.967729, 695.653041)" />
                        </g>
                        <g transform="matrix(1, 0, 0, 0.871403, -3.165084, 46.223553)">
                            <g>
                                <rect x="4.159" y="187.161" width="486.361" height="6.33" style={{fill: "rgb(255, 255, 255)", stroke: "rgb(255, 255, 255)"}} />
                                <path d="M -8.89 -530.506 L -8.89 -353.264 L -18.386 -353.264 L -8.89 -530.506 Z" style={{fill: "rgb(255, 255, 255)", stroke: "rgb(255, 255, 255)"}} transform="matrix(0, -1, -1, 0, -40.041053, 185.128589)" />
                                <rect x="180.291" y="187.216" width="132.931" height="15.825" style={{fill: "rgb(255, 255, 255)", stroke: "rgb(255, 255, 255)"}} />
                                <path d="M 27.882 530.506 L 27.882 707.748 L 18.386 707.748 L 27.882 530.506 Z" style={{fill: "rgb(255, 255, 255)", stroke: "rgb(255, 255, 255)"}} transform="matrix(0, -1, 1, 0, -526.346558, 221.95517)" />
                                <rect x="236.262" y="195.636" width="21.1" height="12.107" style={{fill: "rgb(255, 255, 255)", stroke: "rgb(255, 255, 255)"}} />
                            </g>
                        </g>
                    </g>
                </g>



            </>


        )
    }
}

export default CustomOrientationDiagramHardley