import React from 'react';
import moment from 'moment';
import {
    DataGrid,
    Column,
    MasterDetail
} from 'devextreme-react/data-grid';
import AlertDetailTemplate from './AlertDetailTemplate';
import alertData from './AlertData';

class AlertHistoryPanel extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            alertData: []
        }
    }

    componentDidMount() {
        this.reloadAlerts();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.molen.openAlerts.length !== this.props.molen.openAlerts.length) {
            this.reloadAlerts();
        }
    }

    reloadAlerts() {
        if (this.props.molen != null) {
            fetch(`https://` + process.env.REACT_APP_SMARTMOLEN_API + `/api/alertHistory/` + this.props.molen.shortName)
                .then(response => response.json())
                .then(response => {
                    this.setState({ alertData: response });
                    alertData.setAlertData(response);
                });
        }
    }


    render() {
        if (this.props == null || this.props.molen == null) {
            return <span>Loading ...</span>
        }
        else {
            return this.renderLoaded();
        }
    }


    calculateAlertOpenedTimeDisplay(rowData) {
        return moment(rowData.openedUtcTime).format("DD/MM/yyyy HH:mm:ss");
    }

    calculateAlertClosedTimeDisplay(rowData) {
        return rowData.closedUtcTime != null ? moment(rowData.closedUtcTime).format("DD/MM/yyyy HH:mm:ss") : "";
    }

    renderLoaded() {
        return (
            <div className="panel-wide">
                <div className="panel-header">
                <div className="panel-header-title">
                    ALERT HISTORY (LAST 90 DAYS)
                    </div>
                </div>

                <div className="panel-body">

                    <DataGrid id="grid-container"
                        dataSource={this.state.alertData}
                        keyExpr="id"
                        showBorders={true}
                        rowAlternationEnabled={true}
                    >
                        <Column calculateCellValue={this.calculateAlertOpenedTimeDisplay} width={125} caption="Opened Time" dataType="date" />
                        <Column dataField="status" width={60} caption="Status" />
                        <Column dataField="alertType" caption="Type" />
                        <Column dataField="severity" width={60} caption="Severity" />
                        <Column calculateCellValue={this.calculateAlertClosedTimeDisplay} width={125} caption="Closed Time" dataType="date" />

                        <MasterDetail enabled={true} component={AlertDetailTemplate} />

                    </DataGrid>

                </div>
            </div>

        )
    }
}

export default AlertHistoryPanel
