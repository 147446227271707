var sailRotationReadings = [];

function getSailRotationData() {
    return sailRotationReadings;
}

function setSailRotationData(readings) {
    sailRotationReadings = readings;
} 

const sailRotationData = {
    getSailRotationData,
    setSailRotationData,
};

export default sailRotationData;