import React from 'react'
import Marquee from 'react-fast-marquee'
import '../../../SmartMolen.css';
import '../../../Panels.css';
import './UpmVisitorCentreScreen1.css';
import Clock from 'react-live-clock';
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import MetaTags from 'react-meta-tags';

class UpmVisitorCentreScreen1 extends React.Component {

  state = {
    name: null
  }

  componentDidMount() {

    const hubConnection = new HubConnectionBuilder()
      .withUrl(`https://` + process.env.REACT_APP_SIGNALR_API + `/`)
      .configureLogging(LogLevel.Information)
      .withAutomaticReconnect()
      .build();

    console.log(hubConnection);

    this.setState({ hubConnection }, () => {
      this.state.hubConnection
        .start()
        .then(() => {
          console.log('Connection started!');
        })
        .catch(err => {
          console.log('Error while establishing connection :(');
          console.log(err);
        });


      this.state.hubConnection.on('molenUpdated',
        (receivedMessage) => {
          this.reloadState();
        });

    });

    this.reloadState();

  }

  reloadState() {

    fetch(`https://` + process.env.REACT_APP_SMARTMOLEN_API + `/api/molen/upminster`)
      .then(response => response.json())
      .then(response => {
        this.setState({
          molen: response,
        })

      });

  }

  render() {
    if (this.state == null || this.state.molen == null) {
      return "Loading ..."
    }
    else {
      return this.renderLoaded();
    }
  }


  renderLoaded() {
    return (
      <>
        <MetaTags>
          <title>SmartMolen: Upminster Visitor Centre Screen 1</title>
        </MetaTags>


        <div className="vcs-background">

          <div className="vcs-content">

            <svg width="3840" height="2160">
              <polygon points="0,0 1200,0 3500,2160 1500,2160 0,1000" fill="#A6B8DC"></polygon>
            </svg>

            <div className="vcs-welcome">
              <img src="/img/custom/upminster/visitor-centre-and-windmill.jpg" alt="Windmill and Visitor Centre" width="100%"/>
              <img src="/img/custom/upminster/heritage-lottery.jpg" alt="Heritage lottery" width="25%"/>
              <img src="/img/custom/upminster/veolia.png" alt="Veolia" width="25%"/>
              </div>

            <div className="vcs-motd">
              <Clock format={'dddd D MMMM YYYY HH:mm:ss'}
                ticking={true}></Clock>
              <br />
              <br />
              Welcome to visitors from the<br />National Lottery Heritage Fund
              </div>

            <div className="vcs-live-weather">Windmill Weather Station&nbsp;&nbsp;&nbsp;{this.state.molen.latestWeatherObservation.tempDegC}&deg;C
            &nbsp;&nbsp;&nbsp;
            <Clock format={'HH:mm:ss'}
            
            ticking={true}></Clock></div>

            <div className="vcs-events">FORTHCOMING EVENTS</div>

            <div className="vcs-activities">OPEN DAYS & VOLUNTEERING</div>

            <div className="vcs-facebook">Facebook</div>

            <div className="vcs-footer">
              <Marquee loop={0} gradient={false} speed={100}>  
              Thank you for visiting the windmill.  Upminster Windmill is staffed by volunteers and funded by donations from the local community.  Our friendly volunteer groups meet regularly and run a wide variety of activities including welcoming visitors and school parties, gardening, sewing, managing the site and facilities and maintenance of the windmill itself.  We are always keen to welcome new volunteers so please speak to any of our team or contact us on Facebook if you would like to join us.
              </Marquee>
            </div>

          </div>
        </div>

      </>
    )
  }
}

export default UpmVisitorCentreScreen1