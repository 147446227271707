import React from 'react'
import moment from 'moment';
import './SecondaryWeatherObservationsPanel.css';
import WindDirectionArrow from '../../components/weatherForecastTable/windDirectionArrow';
import UnitsContext from '../../UnitsContext';

class SecondaryWeatherObservationsPanel extends React.Component {

    static contextType = UnitsContext;

    render() {
        if (this.props == null || this.props.molen == null || this.props.molen.secondaryWeatherObservations == null || this.props.molen.secondaryWeatherObservations == null) {
            return <span>Loading ...</span>
        }
        else {
            return this.renderLoaded();
        }
    }

    renderLoaded() {

        /// display wind speed in local units, defaulting to British
        var unitsContext = this.context;
        var windSpeedUnitText = "mph";
        var windSpeedMultiplier = 2.23694;
        if (unitsContext === "metric") {
            windSpeedUnitText = "km/h";
            windSpeedMultiplier = 3.6;
        }

        return (

            <div className="panel-narrow">
                <div className="panel-header">
                    <div className="panel-top-left">
                        REGIONAL WIND OBSERVATIONS
                    </div>
                    <div className="panel-top-right">
                    </div>
                </div>
                <div className="panel-body">

                    {this.props.molen.secondaryWeatherObservations.map(station => (
                        <div className="weather-station-row">
                            <div className="ws-row-col1">{station.stationType === "AIR" ? <img src="/img/ws/air.png" alt="airfield" width="34"></img> : <></>}</div>
                            <div className="ws-row-col2">{station.stationName}<br /><div className="app-supersmalltext">{station.stationId}<br />{moment(station.utcTime).format("DD/MM/yyyy HH:mm")}</div></div>
                            <div className="ws-row-col4"><WindDirectionArrow size="42" windDirection={station.windDirection} molenOrientation={this.props.molen.latestOrientationSensorReading.bearing} windSpeedMs={station.windSpeedMs}></WindDirectionArrow></div>
                            <div className="ws-row-col5"><div className="app-smalltext">{station.windDirectionCompassPoint} {station.windDirection}&deg;<br />
                                {Math.round(station.windSpeedMs * windSpeedMultiplier)} {windSpeedUnitText}<br /></div></div>
                        </div>
                    ))}

                </div>
            </div>
        )
    }
}

export default SecondaryWeatherObservationsPanel
