import React from 'react'
import './DigitalWindmillNavigation.css'

class DigitalWindmillNavigation extends React.Component {


    selectNavigationItem(itemName) {
        console.info("DW Navigation Item Click: " + itemName);
        this.props.onNavigationItemClick(itemName);
    }

    render() {

        return (
            <div className="dw-navigation">
                <div className="dw-navigation-heading">
                    <div className="dw-navigation-heading-left">
                        <img src="/img/smartmolen_master2_256px.png" height={80 * document.getElementsByClassName("SmartMolen")[0].style.getPropertyValue('--fixed-size-item-scale')} alt="smartmolen logo"></img></div>
                    <div className="dw-navigation-heading-right">
                        <div className="dw-navigation-heading-right-upper">
                            {this.props.molen.name}
                        </div>
                        <div className="dw-navigation-heading-right-lower">
                            {this.props.molen.county}<br />
                            {(Math.round(Math.abs(this.props.molen.location.latitude) * 100)) / 100}&deg;{this.props.molen.location.latitude > 0 ? "N" : "S"}
                            &nbsp;&nbsp;&nbsp;
                            {(Math.round(Math.abs(this.props.molen.location.longitude) * 100)) / 100}&deg;{this.props.molen.location.longitude > 0 ? "E" : "W"}
                        </div>
                    </div>
                </div>
                <div className="dw-navigation-button" onClick={() => { this.selectNavigationItem("map") }} style={{ backgroundColor: "#00b000" }}>
                    <img src="/img/globe.png" height={38 * document.getElementsByClassName("SmartMolen")[0].style.getPropertyValue('--fixed-size-item-scale')} width="auto" alt="Map"></img>
                    Map</div>
                <div className="dw-navigation-button" onClick={() => { this.selectNavigationItem("mill") }} style={{ backgroundColor: this.props.selectedSubscreen === 1 ? "#1A8BE8" : "#092f4f" }}>
                    <img src="/img/dw/windmill.png" height={38 * document.getElementsByClassName("SmartMolen")[0].style.getPropertyValue('--fixed-size-item-scale')} width="auto" alt="Windmill"></img>
                    About</div>
                <div className="dw-navigation-button" onClick={() => { this.selectNavigationItem("live") }} style={{ backgroundColor: this.props.selectedSubscreen === 2 ? "#1A8BE8" : "#092f4f" }}>
                    <img src="/img/dw/telemetry.png" height={38 * document.getElementsByClassName("SmartMolen")[0].style.getPropertyValue('--fixed-size-item-scale')} width="auto" alt="Telemetry"></img>
                    Live Telemetry</div>
                <div className="dw-navigation-button" onClick={() => { this.selectNavigationItem("windrose") }} style={{ backgroundColor: this.props.selectedSubscreen === 3 ? "#1A8BE8" : "#092f4f" }}>
                    <img src={this.props.selectedSubscreen === 3 ? "/img/dw/polarchartbw.png": "/img/dw/polarchart.png" } height={38 * document.getElementsByClassName("SmartMolen")[0].style.getPropertyValue('--fixed-size-item-scale')} width="auto" alt="Polar Chart"></img>
                    Wind Rose</div>
                <div className="dw-navigation-button" onClick={() => { this.selectNavigationItem("tracker") }} style={{ backgroundColor: this.props.selectedSubscreen === 4 ? "#1A8BE8" : "#092f4f" }}>
                    <img src="/img/dw/graph.png" height={38 * document.getElementsByClassName("SmartMolen")[0].style.getPropertyValue('--fixed-size-item-scale')} width="auto" alt="Line Graph"></img>
                    Cap Tracker</div>
            </div>
        )
    }
}

export default DigitalWindmillNavigation