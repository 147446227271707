import React from 'react'
import moment from 'moment';
import Clock from 'react-live-clock';
import './LiveFantailSensorPanel.css';

class LiveFantailSensorPanel extends React.Component {


    render() {
        if (this.props == null || this.props.molen == null) {
            return <span>Loading ...</span>
        }
        else {
            return this.renderLoaded();
        }
    }

    renderLoaded() {

        var fantailText1 = "";
        var fantailText2 = "";
        if (this.props.molen.latestFantailSensorReading.currentSpeedRpm > 0) { fantailText1 = "CLOCKWISE"; fantailText2 = " drive"; }
        if (this.props.molen.latestFantailSensorReading.currentSpeedRpm < 0) { fantailText1 = "ANTI-CLOCKWISE"; fantailText2 = " drive"; }

        var fantailLastHourText = "clockwise";
        var fantailLastHourDegrees = Math.round((this.props.molen.latestFantailSensorReading.turnsClockwiseLastHour - this.props.molen.latestFantailSensorReading.turnsAnticlockwiseLastHour) * 360 / this.props.molen.latestFantailSensorReading.fanTurnsToCapTurnRatio);
        if (fantailLastHourDegrees < 0)
        {
            fantailLastHourText = "anti-clockwise";
            fantailLastHourDegrees = Math.abs(fantailLastHourDegrees);
        }
        if (fantailLastHourDegrees === 0)
        {
            fantailLastHourText = "";
        }

        var fantailLast24HoursText = "clockwise";
        var fantailLast24HoursDegrees = Math.round((this.props.molen.latestFantailSensorReading.turnsClockwiseLast24Hours - this.props.molen.latestFantailSensorReading.turnsAnticlockwiseLast24Hours) * 360 / this.props.molen.latestFantailSensorReading.fanTurnsToCapTurnRatio);
        if (fantailLast24HoursDegrees < 0)
        {
            fantailLast24HoursText = "anti-clockwise";
            fantailLast24HoursDegrees = Math.abs(fantailLast24HoursDegrees);
        }
        if (fantailLast24HoursDegrees === 0)
        {
            fantailLast24HoursText = "";
        }


        return (

            <div className="panel-narrow">
                <div className="panel-header">
                    <div className="panel-top-left">
                        LIVE FANTAIL ROTATION
              </div>
                    <div className="panel-top-right">
                        <Clock format={'HH:mm:ss'}
                            ticking={true}
                        />
                    </div>
                </div>

                <div className="panel-body">
                    <div className="panel-columns-span-width">

                        <div className="wind-data-panel">
                            <div className="app-panel-header-text">FAN ROTATION</div>
                            <div className="app-oversize-text">{Math.round(Math.abs(this.props.molen.latestFantailSensorReading.currentSpeedRpm) * 10) / 10}</div>rpm<br />
                            <div className="app-standard-text"><b>{fantailText1}</b><br />{fantailText2}</div>

                        </div>

                        <div className="wind-data-panel">
                            <div className="app-panel-header-text">OSCILLATION</div>
                            <div className="app-oversize-text">{this.props.molen.latestFantailSensorReading.currentOscillationsPerMin}</div>
                            <div className="app-standard-text">oscillations per minute</div>

                        </div>

                    </div>
                    <div className="app-supersmalltext"><br /><br /><br />
                    </div>

                </div>

                <div className="panel-header">
                    <div className="panel-top-left">
                        RECENT FANTAIL MOVEMENT
                  </div>
                </div>

                <div className="panel-body">
                    <div className="fantail-turns-panel-contents">
                        <div>
                            <table id="fantail-turns-table">
                                <thead>
                                <tr  height="45px">
                                        <th width="21%"></th>
                                        <th colSpan="2"><div className="table-major-heading">Fantail turns<br />driving cap</div></th>
                                        <th width="29%"><div className="table-major-heading" rowSpan="3">Net cap rotation</div></th>
                                    </tr>
                                    <tr height="44px">
                                        <th></th>
                                        <th width="25%" ><img src="/img/icon_clockwise.png" height="36px" width="36px" alt="Clockwise Icon"></img></th>
                                        <th width="25%"><img src="/img/icon_anticlockwise.png" height="36px" width="36px" alt="Anti-Clockwise Icon"></img></th>
                                    </tr>
                                    <tr height="38px">
                                        <th></th>
                                        <th>clockwise</th>
                                        <th>anti-clockwise</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td><div className="table-major-heading">LAST HOUR</div></td>
                                        <td align="center"><div className="table-large-numbers">{this.props.molen.latestFantailSensorReading.turnsClockwiseLastHour}</div></td>
                                        <td align="center"><div className="table-large-numbers">{this.props.molen.latestFantailSensorReading.turnsAnticlockwiseLastHour}</div></td>
                                        <td align="center"><div className="table-large-numbers">{fantailLastHourDegrees}&deg;</div><br />{fantailLastHourText}</td>
                                    </tr>
                                    <tr>
                                        <td><div className="table-major-heading">LAST 24 HOURS</div></td>
                                        <td align="center"><div className="table-large-numbers" align="center">{this.props.molen.latestFantailSensorReading.turnsClockwiseLast24Hours}</div></td>
                                        <td align="center"><div className="table-large-numbers">{this.props.molen.latestFantailSensorReading.turnsAnticlockwiseLast24Hours}</div></td>
                                        <td align="center"><div className="table-large-numbers">{fantailLast24HoursDegrees}&deg;</div>
                                            <br />{fantailLast24HoursText}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div className="app-supersmalltext"><br />
          Reported {moment(this.props.molen.latestFantailSensorReading.utcTime).format("HH:mm:ss")} by fantail sensor<br />
                    </div>




                </div>
            </div>
        )
    }
}

export default LiveFantailSensorPanel
