import React from 'react';
import MonitoringStatusSummary from '../components/molenList/monitoringStatusSummary/monitoringStatusSummary';
import './Home2MapHeaderContent.css';

class Home2MapHeaderContent extends React.Component {

    componentDidMount() {
    }

    render()
    {
        if (this.props.molenList == null) {
            return <span></span>
          }
          else {
            return this.renderLoaded();
          }
    }

    renderLoaded() {

        return (
                <MonitoringStatusSummary molenList={this.props.molenList} />
        )
    }
}

export default Home2MapHeaderContent