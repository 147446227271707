var alertDataArray = [];

function getAlertData() {
    return alertDataArray;
}

function setAlertData(alerts) {
    alertDataArray = alerts;
} 

const alertData = {
    getAlertData,
    setAlertData,
};

export default alertData;