import React from 'react';

import './DigitalWindmillWindRose.css';
import {
    PolarChart,
    CommonSeriesSettings,
    Series,
    ArgumentAxis,
    ValueAxis,
    Label,
    Export,
    Legend,
    Animation,
    Title,
    Font
} from 'devextreme-react/polar-chart';
import moment from 'moment';

// NEW COLOURS
const windSpeeds = [
    { value: 'noWindPercent', name: 'No Wind', color: "#E0E0E0" },
    { value: 'beauf0Percent', name: '0 - Calm', color: "#3b6bb5" },
    { value: 'beauf1Percent', name: '1 - Light Air', color: "#0c9bd7" },
    { value: 'beauf2Percent', name: '2 - Light Breeze', color: "#35c0c5" },
    { value: 'beauf3Percent', name: '3 - Gentle Breeze', color: "#0aaa4a" },
    { value: 'beauf4Percent', name: '4 - Moderate Breeze', color: "#52b848" },
    { value: 'beauf5Percent', name: '5 - Fresh Breeze', color: "#92c83e" },
    { value: 'beauf6Percent', name: '6 - Strong Breeze', color: "#c6da2b" },
    { value: 'beauf7Percent', name: '7 - Near Gale', color: "#fcee13" },
    { value: 'beauf8Percent', name: '8 - Gale', color: "#fbb515" },
    { value: 'beauf9Percent', name: '9 - Severe Gale', color: "#f46d3d" },
    { value: 'beauf10Percent', name: '10 - Storm', color: "#e92624" },
    { value: 'beauf11Percent', name: '11 - Violent Storm', color: "#ac46cb" },
    { value: 'beauf12PlusPercent', name: '12 - Hurricane', color: "#000000" }
];

class DigitalWindmillWindRose extends React.Component {

    // FUTURE: refactor to make this the same as wind rose panel

    constructor(props) {
        super(props);
        this.state = {
            windDistribution: {
                distributionByCompassPoint: []
            }
        }
    }

    componentDidMount() {
        this.updateAnalysis();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.analysisId !== this.props.analysisId) {
            this.updateAnalysis();
        }
    }

    updateAnalysis() {
        if (this.props.analysisId != null) {
            fetch(`https://` + process.env.REACT_APP_SMARTMOLEN_API + `/api/analysis/windDistribution/` + this.props.analysisId)
                .then(response => response.json())
                .then(response => {
                    this.setState({ windDistribution: response })
                });
        }
    }

    render() {
        if (this.props == null || this.props.analysisId == null || this.state.windDistribution == null) {
            return <span></span>
        }
        else {
            return this.renderLoaded();
        }
    }

    renderLoaded() {
        return (
            <PolarChart
                id="dw-wind-rose-chart"
                palette="Soft"
                dataSource={this.state.windDistribution.distributionByCompassPoint}
            >

                <Title text={"Wind Recorded at " + this.props.molen.name + " Windmill"} subtitle={moment(this.state.windDistribution.sourceDataStartUtcTime).format("D MMM yyyy") + " - " + moment(this.state.windDistribution.sourceDataEndUtcTime).format("D MMM yyyy")}>
                    <Font family="Roboto" size="2.4vw" />
                </Title>

                <Animation
                    enabled={true}
                />

                <Legend visible={false} />

                <CommonSeriesSettings type="stackedbar" argumentField="compassPoint" />
                {
                    windSpeeds.map(function (item) {
                        return <Series key={item.value} valueField={item.value} name={item.name} color={item.color} barPadding={0} />;
                    })
                }
                <ArgumentAxis
                    discreteAxisDivisionMode="crossLabels"
                    firstPointOnStartAngle={true}
                />
                <ValueAxis valueMarginsEnabled={false}>
                    <Label visible={true} format={"#'%'"} ></Label>
                </ValueAxis>
                <Export enabled={!(this.props.minimal)} />

            </PolarChart>
        )
    }
}

export default DigitalWindmillWindRose
