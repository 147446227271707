import React from 'react'
import AlertHistoryPanel from '../../panels/AlertHistoryPanel/AlertHistoryPanel';

class TechAlertHistoryTab extends React.Component {

    render() {
        if (this.props == null || this.props.molen == null) {
            return <span>Loading ...</span>
        }
        else {
            return this.renderLoaded();
        }
    }


    renderLoaded() {
        return (
            <>
                <div className="panel-container">
                    <AlertHistoryPanel molen={this.props.molen} />
                </div>
            </>
        )
    }
}

export default TechAlertHistoryTab
