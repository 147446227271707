import React from 'react';
import { Link, Switch, Route, matchPath } from 'react-router-dom';
import TechLiveTab from './LiveTab/TechLiveTab'
import TechSensorTab from './SensorTab/TechSensorTab'
import TechAnalysisTab from './AnalysisTab/TechAnalysisTab'
import TechAlertHistoryTab from './AlertHistoryTab/TechAlertHistoryTab'
import './Tech2Tabs.css';

class Tech2Tabs extends React.Component {

  topNavClass = "topnav";

  onBurgerClick() {
    if (this.topNavClass === "topnav") {
      this.topNavClass = "topnav responsive";
    } else {
      this.topNavClass = "topnav";
    }
  }

  render() {

    var referrerIsConsole = false;
    var showMenuBar = true;
    var videoAutoPause = true;

    if (window.location.search != null) {
      var queryString = window.location.search; // look for ?referrer=console in query string so we know which link to offer back
      if (queryString != null) {
        var params = new URLSearchParams(queryString);
        var referrer = params.get('referrer');
        referrerIsConsole = ((referrer != null) && (referrer === "console"));
        var showMenuBarQSValue = params.get('showMenuBar');
        if (showMenuBarQSValue !== null) {
          showMenuBar = (showMenuBarQSValue === "true");
        }
        var videoAutoPauseQSValue = params.get('videoAutoPause');
        if (videoAutoPauseQSValue !== null) {
          videoAutoPause = !(videoAutoPauseQSValue === "false");
        }
      }
    }

    var pathPrefix = '/';
    if (window.location.pathname.startsWith('/live/')) { pathPrefix = "/live/"; }
    if (window.location.pathname.startsWith('/tech/')) { pathPrefix = "/tech/"; }

    var dashboardLinkClass = matchPath(window.location.pathname, { path: pathPrefix + this.props.molen.shortName, exact: true, strict: true }) != null ? "active" : "link";
    var analysisLinkClass = matchPath(window.location.pathname, { path: pathPrefix + this.props.molen.shortName + '/analysis', }) != null ? "active" : "link";
    var alertHistoryLinkClass = matchPath(window.location.pathname, { path: pathPrefix + this.props.molen.shortName + '/alertHistory', }) != null ? "active" : "link";
    var sensorLinkClass = matchPath(window.location.pathname, { path: pathPrefix + this.props.molen.shortName + '/sensor', }) != null ? "active" : "link";
    var homePageLinkClass = "exitLink";

    return (
      <div width="100%">
        {showMenuBar && <div className={this.topNavClass}>
          {referrerIsConsole && <Link to={'/console'} className={homePageLinkClass}><img src="/img/globe.png" alt="globe" height="20" style={{ "verticalAlign": "top" }} />&nbsp;&nbsp;Return to console</Link>}
          {!referrerIsConsole && <Link to={'/'} className={homePageLinkClass}><img src="/img/globe.png" alt="globe" height="20" style={{ "verticalAlign": "top" }} />&nbsp;&nbsp;Map View</Link>}
          <Link to={pathPrefix + this.props.molen.shortName} className={dashboardLinkClass}>Live Dashboard</Link>
          <Link to={pathPrefix + this.props.molen.shortName + '/analysis'} className={analysisLinkClass}>Analysis</Link>
          <Link to={pathPrefix + this.props.molen.shortName + '/alertHistory'} className={alertHistoryLinkClass}>Alert History</Link>
          <Link to={pathPrefix + this.props.molen.shortName + '/sensor'} className={sensorLinkClass}>Sensor Management</Link>
          <a href="# " className="icon" onClick={ev => { this.onBurgerClick(); }}>
            <i className="fa fa-bars"></i>
          </a>
        </div>}
        <div className="tabs">
          <Switch>
            <Route path={'/:molenName/analysis'} render={(props) => (<TechAnalysisTab molen={this.props.molen} />)} />
            <Route path={'/tech/:molenName/analysis'} render={(props) => (<TechAnalysisTab molen={this.props.molen} />)} />
            <Route path={'/live/:molenName/analysis'} render={(props) => (<TechAnalysisTab molen={this.props.molen} />)} />

            <Route path={'/:molenName/alertHistory'} render={(props) => (<TechAlertHistoryTab molen={this.props.molen} />)} />
            <Route path={'/tech/:molenName/alertHistory'} render={(props) => (<TechAlertHistoryTab molen={this.props.molen} />)} />
            <Route path={'/live/:molenName/alertHistory'} render={(props) => (<TechAlertHistoryTab molen={this.props.molen} />)} />

            <Route path={'/:molenName/sensor'} render={(props) => (<TechSensorTab molen={this.props.molen} />)} />
            <Route path={'/tech/:molenName/sensor'} render={(props) => (<TechSensorTab molen={this.props.molen} />)} />
            <Route path={'/live/:molenName/sensor'} render={(props) => (<TechSensorTab molen={this.props.molen} />)} />

            <Route path={'/:molenName'} render={(props) => (<TechLiveTab molen={this.props.molen} videoAutoPause={videoAutoPause} />)} />
            <Route path={'/tech/:molenName'} render={(props) => (<TechLiveTab molen={this.props.molen} videoAutoPause={videoAutoPause} />)} />
            <Route path={'/live/:molenName'} render={(props) => (<TechLiveTab molen={this.props.molen} videoAutoPause={videoAutoPause} />)} />
          </Switch>
        </div>
      </div>
    );
  }
}

export default Tech2Tabs