var sailBrakeFanReadings = [];

function getSailBrakeFanReadings() {
    return sailBrakeFanReadings;
}

function setSailBrakeFanReadings(readings) {
    sailBrakeFanReadings = readings;
} 

const sailBrakeFanData = {
    getSailBrakeFanReadings,
    setSailBrakeFanReadings,
};

export default sailBrakeFanData;