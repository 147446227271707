import React from 'react'
import './DigitalWindmillView3WindRose.css'
import DigitalWindmillNavigation from './DigitalWindmillNavigation';
import DigitalWindmillWindRose from './DigitalWindmillWindRose';
import './DigitalWindmill.css'

class DigitalWindmillView3WindRose extends React.Component {

  constructor(props) {
    super(props);
    this.onNavigationItemClick = this.onNavigationItemClick.bind(this);
  }

  onNavigationItemClick(itemName) {
    console.info("navigationItemClicked: " + itemName);
    var messageContent = JSON.stringify({ selectedMolen: this.state.molen.shortName, mode: itemName });
    window.parent.postMessage(messageContent, "*");
  }


  componentDidMount() {
    this.readFixedSizeItemScaleQueryString();
    this.reloadState();

  }

  readFixedSizeItemScaleQueryString() {
    var fixedSizeItemScale = 1.0;
    if (window.location.search != null) {
      var queryString = window.location.search;
      if (queryString != null) {
        var params = new URLSearchParams(queryString);
        var fixedSizeItemScaleQSValue = params.get('fixedSizeItemScale');
        if (fixedSizeItemScaleQSValue !== null) {
          fixedSizeItemScale = parseFloat(fixedSizeItemScaleQSValue);
        }
      }
    }

    console.info("fixedSizeItemScale: " + fixedSizeItemScale);
    document.getElementsByClassName("SmartMolen")[0].style.setProperty("--fixed-size-item-scale", fixedSizeItemScale);
  }

  reloadState() {

    const { molenName } = this.props.match.params

    fetch(`https://` + process.env.REACT_APP_SMARTMOLEN_API + `/api/molen/${molenName}`)
      .then(response => response.json())
      .then(response => {
        this.setState({
          molen: response,
        })
      });
  }


  render() {
    if (this.state == null || this.state.molen == null) {
      return <span>&nbsp;</span>
    }
    else {
      document.title = "DW Smartmolen - " + this.state.molen.name;
      return this.renderLoaded();
    }
  }

  onAnalysisViewSelect() {
    console.info("selecting analysis view");
    var messageContent = JSON.stringify({ mode: "analysis" });
    window.parent.postMessage(messageContent, "*");
  }

  renderLoaded() {

    return (
      <div className="dw-container" id="dw-container">

        <DigitalWindmillNavigation molen={this.state.molen} selected={"about"} onNavigationItemClick={this.onNavigationItemClick} selectedSubscreen={3} />

        <div className="dw3-wind-rose" width="100%" height="100%">
          <DigitalWindmillWindRose analysisId={this.state.molen.analysis.windDistributionAnalysisId} molen={this.state.molen} minimal={true} />
          <div className="dw3-wind-rose-explanation">
            <b>Explanatory Notes</b><br /><br />
            A <b>wind rose</b> is a graphical tool used by meteorologists to show the distribution of wind speed and direction at a particular location.<br /><br />
            This wind rose shows the distribution of wind observed at <b>{this.state.molen.name} Windmill</b> from the time we started recording until today.<br /><br />
            Each of the 16 spokes represents the percentage of time the wind has blown from the compass point indicated.<br />< br />
            The colours represent the wind speed.  On this chart the wind speed is illustrated with the <b>Beaufort Wind Scale</b>.<br /><br />
            <table>
              <thead>
                <td colSpan={3}><b>Beaufort Force</b></td>
                <td colSpan={2}><b>Milling conditions</b></td>
              </thead>
              <tbody>
              <tr>
                <td width="8%" bgcolor="#E0E0E0">&nbsp;&nbsp;&nbsp;&nbsp;</td><td width="10%"></td><td width="40%">No wind</td><td rowSpan={4} bgcolor="#0c9bd7" width="3%"></td><td rowSpan={4} className="dw3-milling-condition-text">Typically too light for milling</td></tr>
              <tr>
                <td bgcolor="#3b6bb5"></td><td className="dw3-beauf-force-number">0</td><td>Calm</td>
              </tr>
              <tr>
                <td bgcolor="#0c9bd7"></td><td className="dw3-beauf-force-number">1</td><td>Light Air</td>
              </tr>
              <tr>
                <td bgcolor="#35c0c5"></td><td className="dw3-beauf-force-number">2</td><td>Light Breeze</td>
              </tr>
              <tr>
                <td bgcolor="#0aaa4a"></td><td className="dw3-beauf-force-number">3</td><td>Gentle Breeze</td><td rowSpan={4} bgcolor="#52b848" width="3%"></td><td rowSpan={4} className="dw3-milling-condition-text">Usually ideal for milling</td>
              </tr>
              <tr>
                <td bgcolor="#52b848"></td><td className="dw3-beauf-force-number">4</td><td>Moderate Breeze</td>
              </tr>
              <tr>
                <td bgcolor="#92c83e"></td><td className="dw3-beauf-force-number">5</td><td>Fresh Breeze</td>
              </tr>
              <tr>
                <td bgcolor="#c6da2b"></td><td className="dw3-beauf-force-number">6</td><td>Strong Breeze</td>
              </tr>
              <tr>
                <td bgcolor="#fcee13"></td><td className="dw3-beauf-force-number">7</td><td>Near Gale</td><td rowSpan={6} bgcolor="#fbb515" width="3%"></td><td rowSpan={6} className="dw3-milling-condition-text">Mill at risk of damage</td>
              </tr>
              <tr>
                <td bgcolor="#fbb515"></td><td className="dw3-beauf-force-number">8</td><td>Gale</td>
              </tr>
              <tr>
                <td bgcolor="#f46d3d"></td><td className="dw3-beauf-force-number">9</td><td>Severe Gale</td>
              </tr>
              <tr>
                <td bgcolor="#e92624"></td><td className="dw3-beauf-force-number">10</td><td>Storm</td>
              </tr>
              <tr>
                <td bgcolor="#ac46cb"></td><td className="dw3-beauf-force-number">11</td><td>Violent Storm</td>
              </tr>
              <tr>
                <td bgcolor="#000000"></td><td className="dw3-beauf-force-number">12</td><td>Hurricane</td>
              </tr>
              </tbody>
            </table>
            <br />
            <br />
            <b>Turning to wind</b><br/><br />
            By turning to wind, the mill can make best use of all of the available wind power, regardless of the direction it blows from.<br /><br/>
            It is just as important though that the mill turns to wind when not operating, as <b>tail winds</b> can cause serious damage.
          </div>
        </div>
      </div>
    )
  }
}



export default DigitalWindmillView3WindRose