import React from 'react';
import alertData from './AlertData';
import './AlertDetailTemplate.css';
import moment from 'moment';


class AlertDetailTemplate extends React.Component {

    constructor(props) {
        super(props);
        this.alertDataItem = getAlert(props.data.key);
    }

    render() {
        if (this.alertDataItem == null) {
            return <span>Loading ...</span>
        }
        else {
            return this.renderLoaded();
        }
    }


    renderLoaded() {

        return (
            <React.Fragment>
                <table id="alert-detail-table">
                    <tr>
                        <td colSpan="2">
                            <b>Alert Opening</b>
                        </td>
                    </tr>
                    <tr>
                        <td>Description</td>
                        <td>
                            <div className="long-text-cell">
                                {this.alertDataItem.description}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td>Notification Dispatch Time</td>
                        <td>{this.alertDataItem.alertOpenedNotificationsSentUtcTime != null ? moment(this.alertDataItem.alertOpenedNotificationsSentUtcTime).format("DD/MM/yyyy HH:mm:ss") : ""}</td>
                    </tr>
                    <tr>
                        <td>Notifications Sent</td>
                        <td>{this.alertDataItem.alertOpenedNotificationsCount}</td>
                    </tr>
                    <tr><td>&nbsp;</td></tr>
                    <tr>
                        <td colSpan="2">
                            <b>Alert Closure</b>
                        </td>
                    </tr>
                    <tr>
                        <td>Closure reason</td>
                        <td>{this.alertDataItem.closedReason}</td>
                    </tr>
                    <tr>
                        <td>Notification Dispatch Time</td>
                        <td>{this.alertDataItem.alertClosedNotificationsSentUtcTime != null ? moment(this.alertDataItem.alertClosedNotificationsSentUtcTime).format("DD/MM/yyyy HH:mm:ss") : ""}</td>
                    </tr>
                    <tr>
                        <td>Notifications Sent</td>
                        <td>{this.alertDataItem.alertClosedNotificationsCount}</td>
                    </tr>
                </table>
            </React.Fragment>
        )
    }
}

function getAlert(key) {
    var alerts = alertData.getAlertData();
    return alerts.find(r => r.id === key);
}


export default AlertDetailTemplate