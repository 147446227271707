import React from 'react'
import './WidgetDemo.css';

class WidgetDemo extends React.Component {

  render() {
    return (
        <div className="widget-demo">
            <div className="widget-demo-column">
            <b>Widget IFrame - width=300, height=1040</b>
            &lt;iframe src=&quot;https://www.smartmolen.com/widget/upminster&quot; scrolling=&quot;no&quot; title=&quot;smartmolen-narrow&quot; width=&quot;300&quot; height=&quot;1040&quot; border=&quot;0&quot; /&gt;
            <br />
            <iframe src="widget/upminster" scrolling="no" title="smartmolen-narrow" width="300" height="1040" style={{border: 0}} />
            </div>

            <div className="widget-demo-column">
            <b>Widget IFrame - width=760, height=620</b>
            &lt;iframe src=&quot;https://www.smartmolen.com/widget/upminster&quot; scrolling=&quot;no&quot; title=&quot;smartmolen-wide&quot; width=&quot;760&quot; height=&quot;620&quot; border=&quot;0&quot; /&gt;
            <br />
            <br />
            <iframe src="widget/upminster" scrolling="no" title="smartmolen-wide" width="760" height="620" style={{border: 0}} />
            </div>

            <div className="widget-demo-column">
            <b>Widget IFrame - width=560, height=600</b>
            &lt;iframe src=&quot;https://www.smartmolen.com/widget/upminster&quot; scrolling=&quot;no&quot; title=&quot;smartmolen-mid&quot; width=&quot;560&quot; height=&quot;600&quot; border=&quot;0&quot; /&gt;
            <br />
            <br />
            <iframe src="widget/upminster" scrolling="no" title="smartmolen-mid" width="560" height="600" style={{border: 0}} />
            </div>


        </div>
    )
  }
}

export default WidgetDemo