class UnitLocalisationLogic {

    getLocalisedWindSpeed(speedMs, context) {
        var windSpeed = {value: speedMs, units:"m/s"};
        
        if (context === "metric"){
            windSpeed.units = "km/h";
            windSpeed.value = Math.round(speedMs * 3.6);
        }
        else
        {
            windSpeed.units="mph";
            windSpeed.value = Math.round(speedMs * 2.23694);
        }

        return windSpeed;
    }

    getLocalisedSailRotationSpeed(rpm, context) {
        var sailRotationSpeed = {value: (Math.round(rpm * 10) / 10), units:"rpm"};
        
        if (context === "metric"){
            sailRotationSpeed.units = "ends/min";
            sailRotationSpeed.value = Math.round(rpm * 4);
        }

        return sailRotationSpeed;
    }

}

export default UnitLocalisationLogic

