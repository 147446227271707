
const DARK_RED = "#f00000";
const DARK_ORANGE = "#e06000";
const DARK_YELLOW = "#e0a000";
const DARK_GREEN = "#00b000";
const DARK_AMBER = "#e0a000";
const DARK_GREY = "#808080";

const LIGHT_RED = "#ff1010";
const LIGHT_ORANGE = "#f07000";
const LIGHT_YELLOW = "#f0b000";
const LIGHT_GREEN = "#10d010";
const LIGHT_AMBER = "#f0b000";
const LIGHT_GREY = "#A0A0A0";


class WindLogic {

    getBearingDiff(a, b) {
        var diff = (a > b ? a - b : b - a);
        if (diff > 180) { diff = 360 - diff; }
        return (diff);
    }

    DARK_RED = "";

    getBearingDiffIndicatorColourForWindingRAGStatus(windingRAGStatus, darkColourScheme) {
        if (windingRAGStatus === "FAULT") {
            return (darkColourScheme ? DARK_GREY : LIGHT_GREY);
        }
        if (windingRAGStatus === "GREY") {
            return (darkColourScheme ? DARK_GREY : LIGHT_GREY);
        }
        if (windingRAGStatus === "RED") {
            return (darkColourScheme ?  DARK_RED : LIGHT_RED);
        }
        if (windingRAGStatus === "ORANGE") {
            return (darkColourScheme ? DARK_ORANGE : LIGHT_ORANGE);
        }
        if (windingRAGStatus === "YELLOW") {
            return (darkColourScheme ? DARK_YELLOW : LIGHT_YELLOW);
        }
        // NOTE - Amber left in place for changeover from RAG to ROYG
        if (windingRAGStatus === "AMBER") {
            return (darkColourScheme ? DARK_AMBER : LIGHT_AMBER);
        }

        return (darkColourScheme ? DARK_GREEN : LIGHT_GREEN);
    }

    // TODO: standardise calculation with wind warnings in back end "WindMonitorTask"
    // NOTE: numbers aligned numerically on 1/3/22, except for yellow colour which has a threshold
    // of any speed here to indicate tail wind, but must be 6.7 to trigger wind warning
    getBearingDiffIndicationColour(a, b, windSpeedMs) {
        var bearingDiff = this.getBearingDiff(a, b);

        if (bearingDiff >= 110 && windSpeedMs >= 11.18) {
            return LIGHT_RED;
        }
        if (bearingDiff >= 110 && windSpeedMs >= 8.94) {
            return LIGHT_ORANGE;
        }
        if (bearingDiff >= 110) {
            return LIGHT_YELLOW;
        }
        return LIGHT_GREEN;
    }

    getBearingDiffIndicationColourDarker(a, b, windSpeedMs) {
        var bearingDiff = this.getBearingDiff(a, b);

        if (bearingDiff >= 110  && windSpeedMs >= 11.18) {
            return DARK_RED;
        }
        if (bearingDiff >= 110 && windSpeedMs >= 8.94) {
            return DARK_ORANGE;
        }
        if (bearingDiff >= 110) {
            return DARK_YELLOW;
        }

        return DARK_GREEN;
    }


    getWindIndicationColourDarker(alertSeverity) {

        if (alertSeverity >= 3) {
            return DARK_RED;
        }
        if (alertSeverity >= 2) {
            return DARK_ORANGE;
        }
        if (alertSeverity >= 1) {
            return DARK_YELLOW;
        }

        return DARK_GREEN;
    }


    // Gusting wind is defined by US METAR as wind gust speed > 15 knots and more than 9 knots greater than wind speed itself
    // BBC defines gusts as over 40mph so using that definition (and not worrying about speed relative to underlying wind speed as above 40mph it's less relevant)
    isWindGusting(_windSpeedMs, windGustMs) {
        return (windGustMs > 17.88);
    }


}

export default WindLogic