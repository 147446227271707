import React from 'react';
import WeatherForecastTable from '../../components/weatherForecastTable/weatherForecastTable';

class ThreeDayForecastPanel extends React.Component {

    render() {
        if (this.props == null || this.props.molen == null) {
            return <span>Loading ...</span>
        }
        else {
            return this.renderLoaded();
        }
    }

    renderLoaded() {

        var molenOrientation = (this.props.molen.latestOrientationSensorReading != null) ? this.props.molen.latestOrientationSensorReading.bearing : null;

        var includeHyperlinks = true;
        if (this.props.includeHyperlinks != null) {
            includeHyperlinks = this.props.includeHyperlinks;
        }

        return (
            <div className="panel-narrow">
                <div className="panel-header">
                    FORECAST FOR NEXT 72 HOURS
                </div>
                <div className="panel-body">
                    <WeatherForecastTable forecastId={this.props.molen.latestWeatherForecast.forecastId} molenOrientation={molenOrientation} orientationSensorFitted={this.props.molen.orientationSensors != null && this.props.molen.orientationSensors.Length > 0} windingRAGStatus={this.props.molen.windingRAGStatus} includeHyperlinks={includeHyperlinks} />
                </div>
            </div>

        )
    }
}

export default ThreeDayForecastPanel
