import React from 'react';
import './WhirlOffHeadlineStats.css';
import Odometer from 'react-odometerjs';

class WhirlOffHeadlineStats extends React.Component {

    componentDidMount() {
    }

    render() {

        var grandTotal = this.props.whirloff.reduce((n, { revCountThisYear }) => n + revCountThisYear, 0);

        return (
            <>
                <div className="whirloff-headline-stats-container">
                    Total sail revolutions counted this year
                    <div className="whirloff-grand-total">
                        <Odometer value={grandTotal} format="(,ddd)" theme="minimal" />
                    </div>
                    from {this.props.whirloff.length} windmills

                </div>
            </>
        )
    }
}

export default WhirlOffHeadlineStats