import React from 'react';
import './alertBar.css';

class AlertBar extends React.Component {

    componentDidMount() {
    }

    render() {

        var molen = this.props.molen;
        var maxSeverity = 0;
        for (let i = 0; i < molen.openAlerts.length; i++) {
            if (molen.openAlerts[i].severity > maxSeverity) { maxSeverity = molen.openAlerts[i].severity; }
        }

        var alertPanelColour = "#909090";
        if (maxSeverity >= 2) {
            alertPanelColour = "#e0a000"
        }
        if (maxSeverity >= 3) {
            alertPanelColour = "#F00000"
        }



        return (
            <>

                <div className="alert-container" style={{ backgroundColor: alertPanelColour }}>
                    <div className="alert-list">
                        {molen.openAlerts.map((alert) => (
                            <>{alert.description}<br /></>
                        ))}
                    </div>
                </div>

            </>
        )
    }
}



export default AlertBar