import React from 'react';

class CompassIcon extends React.Component {

    componentDidMount() {
    }

    render() {

        var size = this.props.size != null ? this.props.size : 100;

        return (
            <>
                <div className="compass-icon" >
                    <svg height={size} width={size}>

                        <g name="compass" transform={"scale(" + size / 100 + " )"} >

                            <circle cx="50" cy="50" r="45" stroke="black" fill="none" strokeWidth="4"></circle>
                            <g transform="rotate(30,50,50)">
                                <polygon points="40,50 50,10 60,50" stroke="black" fill="none" strokeWidth="4"></polygon>
                                <polygon points="40,50 50,90 60,50" stroke="black" fill="black" strokeWidth="4"></polygon>
                            </g>

                        </g>
                    </svg>
                </div>

            </>
        )
    }
}

export default CompassIcon