import React from 'react'
import './Console.css'
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr';
import Clock from 'react-live-clock';
import moment from 'moment';
import ConsoleMolenList from './ConsoleMolenList';
import QRCode from 'react-qr-code';

class Console extends React.Component {

  state = {
    mapZoom: 6,
    mapLat: 54.5,
    mapLong: -1,
    mapMolenFocus: "",
    name: null
  }

  componentDidMount() {
    const hubConnection = new HubConnectionBuilder()
      .withUrl(`https://` + process.env.REACT_APP_SIGNALR_API + `/`)
      .configureLogging(LogLevel.Information)
      .withAutomaticReconnect()
      .build();

    console.log(hubConnection);

    this.setState({ hubConnection }, () => {
      this.state.hubConnection
        .start()
        .then(() => {
          console.log('Connection started!');
        })
        .catch(err => {
          console.log('Error while establishing connection :(');
          console.log(err);
        });


      this.state.hubConnection.on('molenUpdated',
        (receivedMessage) => {
          this.reloadMolens();
        });

    });

    this.reloadMolens();

  }

  reloadMolens() {

    fetch(`https://` + process.env.REACT_APP_SMARTMOLEN_API + `/api/molenList`)
      .then(response => response.json())
      .then(response => {

        var molensWithWindingAlerts = [];
        var molensWithTechAlerts = [];

        response.forEach(addToAlertArray);

        function addToAlertArray(item, index, arr) {
        }

        this.setState({
          molenList: response,
          molensWithWindingAlerts: molensWithWindingAlerts,
          molensWithTechAlerts: molensWithTechAlerts
        })

      });

    // log in 'plausible' that the window is still open (every 30 mins)
    if (this.lastLogTimeMillis <= (new Date().getTime()) - 1800000) {
      window.plausible('homeLive');
      this.lastLogTimeMillis = new Date().getTime();
    }
  }

  render() {
    if (this.state == null || this.state.molenList == null) {
      return <span></span>
    }
    else {
      return this.renderLoaded();
    }
  }

  focusMapOnMolen(molen) {
    this.setState({
      mapZoom: 8,
      mapLat: molen.location.latitude,
      mapLong: molen.location.longitude,
      mapMolenFocus: molen.shortName
    })
  }

  renderLoaded() {

    return (
      <div className="console-main">

        <div className="console-windy-iframe"><iframe src={"/map/map.html?zoom=" + this.state.mapZoom + "&lat=" + this.state.mapLat + "&lon=" + this.state.mapLong + "&molenFocus=" + this.state.mapMolenFocus + "&referrer=console"} title="Windy Map" width="100%" height="100%" style={{ border: "none" }}></iframe></div>

        <div className="console-live-panel">
          <div className="console-live-panel-top">
            <svg height="20" width="20">
              <circle cx="10" cy="10" r="8" stroke="#ffffff" strokeWidth="0" fillOpacity="1" fill='#ffffff'>
              <animate
                attributeName="fill"
                values="#ffffff;transparent"
                begin="0s"
                dur="2s"
                calcMode="discrete"
                repeatCount="indefinite"
              />
              </circle>
            </svg>
            <div className="console-live-title"><b>LIVE</b></div>
          </div>
          <div className="console-live-panel-clock">
            <Clock format={'D MMMM YYYY HH:mm:ss'}
              ticking={true}></Clock>
          </div>
          <div className="console-live-panel-bottom">
            Scan the QR code to view telemetry and<br/>webcams on your own device
          </div>
          <div className="console-live-panel-qr-code" style={{ background: 'white', padding: '10px' }}>
            <QRCode size={80} value="https://www.smartmolen.com"></QRCode>
          </div>
        </div>

        <div className="console-right-panel">

          <div className="console-right-header">
            <div className="console-header-container">
              <div className="console-header-left">
                <a href="/console"><img src="/img/smartmolen_master2_256px.png" className="console-header-icon" alt=""></img></a>
                <div className="console-header-title">
                  <div className="console-header-text">smartmolen.com</div>
                  Digital telemetry for traditional windmills
                </div>
              </div>
            </div>
          </div>

          <div className="console-right-body">
            <ConsoleMolenList molenList={this.state.molenList} onMolenSelected={this.focusMapOnMolen.bind(this)} />
          </div>

          <div className="console-right-footer">
            <div className="console-right-footer-container">
              <div>
                Operated by the<br /><b>Friends of Upminster Windmill</b><br />
                <div className="console-right-footer-copyright">
                  <br />
                  &copy; {moment().format('YYYY')} smartmolen.com
                </div>
              </div>
              <img src="img/LogoUpminsterLightSmall.jpg" className="console-right-footer-fuw-logo" alt=""></img>
            </div>
          </div>

        </div>

      </div>
    )
  }
}

export default Console