import React from 'react'
import WindRosePanel from '../../panels/WindRosePanel/WindRosePanel';
import OrientationHistoryPanel from '../../panels/OrientationHistoryPanel/OrientationHistoryPanel';
import ResponseToWindPanel from '../../panels/ResponseToWindPanel/ResponseToWindPanel';

class TechAnalysisTab extends React.Component {

    render() {
        if (this.props == null || this.props.molen == null) {
            return <span>Loading ...</span>
        }
        else {
            return this.renderLoaded();
        }
    }


    renderLoaded() {
        return (
            <>
                <div className="panel-container">
                    {(this.props.molen.analysis != null) && <WindRosePanel analysisId={this.props.molen.analysis.windDistributionAnalysisId} />}
                    {(this.props.molen.latestSummarisedHistoryRecords != null && this.props.molen.orientationSensors != null && this.props.molen.orientationSensors.length > 0 ) && <OrientationHistoryPanel molen={this.props.molen} />}
                    {(this.props.molen.analysis != null && this.props.molen.orientationSensors != null  && this.props.molen.orientationSensors.length > 0) && <ResponseToWindPanel molen={this.props.molen} />}
                </div>
            </>
        )
    }
}

export default TechAnalysisTab
