import React from 'react';

class BeaufortIcon extends React.Component {

    componentDidMount() {
    }

    render() {

        return (
            <>
                <div className="beaufort-icon" >
                    <svg height="32" width="32">

                        <g name="force6-7" hidden={(this.props.force < 6 || this.props.force > 7)} >
                            <polygon points="6,9 26,16, 6,23" fill="red" />
                        </g>


                        <g name="force8-9" hidden={(this.props.force < 8 || this.props.force > 9)}>
                            <polygon points="6,1 26,8, 6,15" fill="red" />
                            <polygon points="6,16 26,23, 6,30" fill="red" />
                        </g>

                        <g name="force10+" hidden={(this.props.force < 10)}>
                            <polygon points="6,6 26,6, 26,26 6,26" fill="red" />
                            <polygon points="11,11 21,11, 21,21 11,21" fill="black" />
                        </g>

                    </svg>
                </div>

            </>
        )
    }
}



export default BeaufortIcon