import React from 'react';
import './Home2MapBoundsLinks.css';

class Home2MapBoundsLinks extends React.Component {

  componentDidMount() {
  }

  selectNorfolk = (e) => {
    this.props.onBoundsSelected({ north: 53.1, south: 52.3, east: 1.8, west: 0.15 });
  };

  selectEastAnglia = (e) => {
    this.props.onBoundsSelected({ north: 53.1, south: 51.5, east: 1.8, west: -0.3 });
  };

  selectSEEngland = (e) => {
    this.props.onBoundsSelected({ north: 53.2, south: 50.55, east: 1.9, west: -1.8 });
  };

  selectEngland = (e) => {
    this.props.onBoundsSelected({ north: 55, south: 50.2, east: 2, west: -3.3 });
  };

  selectNWEurope = (e) => {
    this.props.onBoundsSelected({ north: 58.5, south: 49, east: 12, west: -10 });
  };


  render() {

    return (
      <>
        <div className="map-bounds-link-row">
          <div>View:</div>
          <div className="map-bounds-link" onClick={this.selectNWEurope}>North West Europe</div>
          <div className="map-bounds-link" onClick={this.selectEngland}>England</div>
          <div className="map-bounds-link" onClick={this.selectSEEngland}>South East England</div>
          <div className="map-bounds-link" onClick={this.selectEastAnglia}>East Anglia</div>
          <div className="map-bounds-link" onClick={this.selectNorfolk}>Norfolk</div>
        </div>
      </>
    )
  }
}

export default Home2MapBoundsLinks