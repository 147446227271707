import React from 'react';

class CameraIcon extends React.Component {

    componentDidMount() {
    }

    render() {

        var size = this.props.size != null ? this.props.size : 100;

        return (
            <>
                <div className="camera-icon" >
                    <svg height={size} width={size}>

                        <g name="camera" transform={"scale(" + size / 100 + " )"} >

                            <polygon points="5,15 60,15 60,35 95,20 95,80 60,65 60,85 5,85" stroke="black" fill="none" strokeWidth="6">
                            </polygon>

                        </g>
                    </svg>
                </div>

            </>
        )
    }
}

export default CameraIcon