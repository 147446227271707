import React from 'react'
import './DigitalWindmillView1MillInfo.css'
import './DigitalWindmill.css'
import DigitalWindmillNavigation from './DigitalWindmillNavigation';
import SailSpeedSensorIcon from '../components/sensorIcons/sailSpeedSensorIcon';
import FantailIcon from '../components/sensorIcons/fantailIcon';

class DigitalWindmillView1MillInfo extends React.Component {

  constructor(props) {
    super(props);
    this.onNavigationItemClick = this.onNavigationItemClick.bind(this);
  }

  onNavigationItemClick(itemName) {
    var messageContent = JSON.stringify({ mode: itemName });
    window.parent.postMessage(messageContent, "*");
  }


  componentDidMount() {
    this.readFixedSizeItemScaleQueryString();
    this.reloadState();

  }

  readFixedSizeItemScaleQueryString() {
    var fixedSizeItemScale = 1.0;
    if (window.location.search != null) {
      var queryString = window.location.search;
      if (queryString != null) {
        var params = new URLSearchParams(queryString);
        var fixedSizeItemScaleQSValue = params.get('fixedSizeItemScale');
        if (fixedSizeItemScaleQSValue !== null) {
          fixedSizeItemScale = parseFloat(fixedSizeItemScaleQSValue);
        }
      }
    }

    console.info("fixedSizeItemScale: " + fixedSizeItemScale);
    document.getElementsByClassName("SmartMolen")[0].style.setProperty("--fixed-size-item-scale", fixedSizeItemScale);
    document.getElementsByClassName("SmartMolen")[0].style.setProperty("--smartmolen-content-scale", fixedSizeItemScale * 0.91);
  }

  reloadState() {

    const { molenName } = this.props.match.params

    fetch(`https://digital-windmill-api.azurewebsites.net/api/molenInfo/${molenName}`)
      .then(response2 => response2.json())
      .then(response2 => {
        this.setState({
          molenInfo: response2,
        })
      });


    fetch(`https://` + process.env.REACT_APP_SMARTMOLEN_API + `/api/molen/${molenName}`)
      .then(response => response.json())
      .then(response => {
        this.setState({
          molen: response,
        })
      });
  }

  render() {
    if (this.state == null || this.state.molen == null || this.state.molenInfo == null) {
      return <span>&nbsp;</span>
    }
    else {
      document.title = "DW Smartmolen - " + this.state.molen.name;
      return this.renderLoaded();
    }
  }



  renderLoaded() {

    return (
      <div className="dw-container" id="dw-container">

        <DigitalWindmillNavigation molen={this.state.molen} selected={"about"} onNavigationItemClick={this.onNavigationItemClick} selectedSubscreen={1}/>

        <div className="dw1-media">
          {(this.state.molenInfo.media === "Photo") &&
            <>
              <img className="dw1-media-image" src={"https://digital-windmill-api.azurewebsites.net/api/image/" + this.state.molenInfo.photo.resourceId} alt="Windmill"></img>
            </>
          }

          {(this.state.molenInfo.media === "Webcam") &&
            <>
              <iframe src={this.state.molen.webcam.url} allow="autoplay" className="dw-webcam-view" scrolling="no" title="millwebcam" style={{ border: "none" }}></iframe>
            </>
          }

          {(this.state.molenInfo.media === "Video") &&
            <>
              <iframe width="100%" height="100%" src={this.state.molenInfo.videoUrl} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>               </>
          }

        </div>
        <div className="dw1-media-overlay"></div>

        <div className="dw1-lower-info">
          <div className="dw1-lower-info-type">
            <div className="dw1-lower-info-field-name">Type</div>
            <div className="dw1-lower-info-data-point">{this.state.molenInfo.typeOfMill}</div>
          </div>
          <div className="dw1-lower-info-built">
            <div className="dw1-lower-info-field-name">Built</div>
            <div className="dw1-lower-info-data-point">{this.state.molenInfo.yearBuilt}</div>
          </div>
          <div className="dw1-lower-info-purpose">
            <div className="dw1-lower-info-field-name">Purpose</div>
            <div className="dw1-lower-info-data-point">{this.state.molenInfo.purpose}</div>
          </div>
          <div className="dw1-lower-info-text">{this.state.molenInfo.moreInformationText}</div>
        </div>

        <div className="dw1-sails">
          <div className="dw1-tech-spec-left">
            <SailSpeedSensorIcon size={64 * document.getElementsByClassName("SmartMolen")[0].style.getPropertyValue('--fixed-size-item-scale')} color={"#FFFFFF"} strokeWidth={2.5} />SAILS<br />
          </div>
          <div className="dw1-tech-spec-right">
            <table><tbody>
              <tr>
                <td width={90 * document.getElementsByClassName("SmartMolen")[0].style.getPropertyValue('--fixed-size-item-scale')}>Direction</td><td>{this.state.molenInfo.sailDirection}</td>
              </tr>
              <tr>
                <td>Type</td><td>{this.state.molenInfo.sails.split(",").map(item => (<>
                  {item}<br />
                </>
                ))}</td>
              </tr>
            </tbody>
            </table>
          </div>
        </div>

        <div className="dw1-fantail">
          <div className="dw1-tech-spec-left">
            <FantailIcon size={64 * document.getElementsByClassName("SmartMolen")[0].style.getPropertyValue('--fixed-size-item-scale')} color={"#FFFFFF"} strokeWidth={2.5} />FANTAIL<br />
          </div>
          <div className="dw1-tech-spec-right">
            <table><tbody>
              <tr>
                <td width={90 * document.getElementsByClassName("SmartMolen")[0].style.getPropertyValue('--fixed-size-item-scale')} >Direction</td><td>{this.state.molenInfo.fantailDirection}</td>
              </tr>
              <tr>
                <td>Blades</td><td>{this.state.molenInfo.fantail}</td>
              </tr>
            </tbody>
            </table>
          </div>
        </div>

        <div className="dw1-powers">
          <div className="dw1-tech-spec-left">
            <img src="/img/dw/cogs.png" alt="Machinery Icon" height={64 * document.getElementsByClassName("SmartMolen")[0].style.getPropertyValue('--fixed-size-item-scale')}></img>
            POWERS</div>
          <div className="dw1-tech-spec-right">
            {this.state.molenInfo.powers.split(",").map(item => (<>
              {item}<br /></>))}
          </div>
        </div>
      </div>
    )
  }
}



export default DigitalWindmillView1MillInfo